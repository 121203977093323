import { Reducer } from 'utils/types'
import { Action, State } from './types'

export const reducer: Reducer<State, Action> = (
  state: State,
  action: Action,
) => {
  // console.log('Snackbar reducer action', action)
  switch (action.type) {
    case 'showDialog':
      return {
        open: true,
        ...action.payload,
      }
    case 'closeDialog':
      return {
        ...state,
        open: false, // default value
      }
    default:
      throw new Error('unrecognized settings action in snackbar reducer')
  }
}
