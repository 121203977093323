import { DetailedHTMLProps, TextareaHTMLAttributes } from 'react'
import { styled } from '@mui/material/styles'
import Color from 'color'

//https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/

export const TextAreaGrow = ({
  onChange,
  ...rest
}: DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>) => {
  return (
    <GrowWrapper className='grow-wrapper'>
      <textarea
        onChange={(event) => {
          const parent: HTMLDivElement | undefined = event.currentTarget
            .parentNode as HTMLDivElement
          parent.dataset.replicatedValue = event.currentTarget.value
          onChange && onChange(event)
        }}
        {...rest}
      />
    </GrowWrapper>
  )
}

export const GrowWrapper = styled('div')`
  display: grid;
  ::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) ' ';

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
  }

  > textarea {
    &:hover {
      background-color: rgba(0, 0, 0, 0.09);
    }

    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;

    &:focus {
      box-shadow: 0 0 0 3px
        ${(p) => Color(p.theme.palette.primary.main).alpha(0.4).hsl().string()};
    }

    &::placeholder {
      font-weight: 300;
      color: ${(p) => p.theme.palette.text.secondary};
    }
  }

  > textarea,
  ::after {
    /* Place on top of each other */
    border: none;
    color: ${(p) => p.theme.palette.text.primary};
    grid-area: 1 / 1 / 2 / 2;
    line-height: 1.5;
    border-radius: 15px;
    outline: none;
    font-size: 1rem;
    font-weight: 500;
    font-family: inherit;
    padding: 0.5rem 0.75rem;
    display: block;
    background-color: rgba(0, 0, 0, 0.06);
    //${(p) => p.theme.palette.background.elements};
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    min-height: 0px;
  }
`

export const TextAreaWrapper = styled('div')`
  display: grid;

  // .grow-wrapper {
  //   margin-top: 0.5rem;
  //   margin-bottom: 0.5rem;
  //   margin-right: 0.5rem;
  // }
`
