import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * Scrolls to the top of the page on load
 */
export const useScrollToTop = () => {
  useEffect(() => {
    // console.log('scrolling')
    window.scrollTo({ top: 0 })
  }, [])
}

export const useWindowFocus = () => {
  const [focus, setFocus] = useState(true)
  const lastFocus = usePrevious(focus)
  // User has switched back to the tab
  const onFocus = useCallback(() => {
    setFocus(true)
  }, [])

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = useCallback(() => {
    setFocus(false)
  }, [])

  useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  })

  return { focus, lastFocus }
}

export const usePrevious = <T>(value: T | undefined) => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useBeforeUnload = ({
  when,
  message,
}: {
  when: boolean
  message?: string
}) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = message
      return message
    }

    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [when, message])
}

export const useWindowSizes = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg')) // >1200
  const isMedium = useMediaQuery(theme.breakpoints.up('md')) // >1200
  const isLarge = useMediaQuery(theme.breakpoints.up('lg')) // >1200
  const showSidebar = useMediaQuery(theme.breakpoints.up('md')) // >800
  const showHeader = useMediaQuery(theme.breakpoints.down('md')) // >800
  const smallProgressBar = useMediaQuery(theme.breakpoints.down(800)) // < 800

  return {
    isMobile,
    isDesktop,
    showSidebar,
    showHeader,
    smallProgressBar,
    isMedium,
    isLarge,
  }
}
