import { styled } from '@mui/material/styles'

export const FormGroup = styled('form')`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding-bottom: 5rem;

  label {
    margin-bottom: 0.25rem;
    margin-left: 0.125rem;
  }
`
