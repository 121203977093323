import { styled } from '@mui/material/styles'

export const NavButtonGroup = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 0 5rem 0;

  button {
    background-color: ${(p) => p.theme.palette.primary.main};
    color: ${(p) => p.theme.palette.background.elements};
  }

  button:hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
`
