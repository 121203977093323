import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { EditDialog } from './EditDialog'
import { EditDialogState } from './types'

const INITIAL_STATE: State = {
  mode: 'idle',
}

const DispatchContext = createContext<Action>({
  setDialog: () => undefined,
  setMode: () => undefined,
})
const StoreContext = createContext<State>(INITIAL_STATE)

type Mode = 'idle' | 'selecting'

type State = {
  mode: 'selecting' | 'idle'
  // shownUsers: Role[]
}

const INITIAL_DIALOG: EditDialogState = {
  id: undefined,
  open: false,
}

type Action = {
  setDialog: Dispatch<SetStateAction<EditDialogState>>
  setMode: Dispatch<SetStateAction<Mode>>
}

export const useDialog = () => {
  const { setDialog } = useContext(DispatchContext)
  const showDialog = useCallback(
    (id: number | undefined) => setDialog({ open: true, id }),
    [setDialog],
  )
  const closeDialog = useCallback(
    () => setDialog({ open: false, id: undefined }),
    [setDialog],
  )
  const useDialogReturn = useMemo(
    () => ({ showDialog, closeDialog }),
    [closeDialog, showDialog],
  )
  return useDialogReturn
}

export const useUiStore = () => useContext(StoreContext)

export const useUiDispatch = () => useContext(DispatchContext)

export const UiProvider = ({ children }: { children: ReactNode }) => {
  const [dialog, setDialog] = useState<EditDialogState>(INITIAL_DIALOG)
  const [mode, setMode] = useState<Mode>('idle')

  const dispatchValue = useMemo(() => ({ setMode, setDialog }), [])
  const storeValue = useMemo(() => ({ mode }), [mode])

  return (
    <DispatchContext.Provider value={dispatchValue}>
      <StoreContext.Provider value={storeValue}>
        {children}
        <EditDialog {...dialog} />
      </StoreContext.Provider>
    </DispatchContext.Provider>
  )
}
