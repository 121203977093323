import FontFaceObserver from 'fontfaceobserver'
import { QueryClient } from './queryClient'
// Use consistent styling
import 'sanitize.css/sanitize.css'
// Development modules variables in the window
import 'utils/consoleModules'
import { IS_PRODUCTION } from './constants'
import reportWebVitals from 'utils/reportWebVitals'

// Observe loading of Inter (to remove 'Inter', remove the <link> tag in
// the index.html file and this observer)
const interObserver = new FontFaceObserver('Inter', {})
const nunitoObserver = new FontFaceObserver('Nunito', {})

// When Inter is loaded, add a font-family using Inter to the body
interObserver.load().then(() => {
  document.body.classList.add('interLoaded')
})
nunitoObserver
  .load()
  .then(() => {
    document.body.classList.add('nunitoLoaded')
  })
  .catch((err) => console.error('unabled to load nunito!', err))

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // https://codemachine.dev/things-i-learned-while-using-react-query-part-2
      retry: false, // number of attempts before sending error
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 1000 * 60 * 60 * 1,
      staleTime: 1000 * 60 * 60 * 1,
    },
  },
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (!IS_PRODUCTION) {
  reportWebVitals()
}
