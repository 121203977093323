import { getSelfUser } from 'contexts/api/hooks'
import { GetSelfUserResponse, Role } from 'contexts/api/types'
import { useDispatchSnackbar } from 'contexts/snackbar'
import { LoadingPage } from 'pages/LoadingPage'
import { createContext, useContext, useMemo, useRef } from 'react'
import { useQuery } from 'react-query'
import { QueryKeyKey, SelfUserQueryKey } from 'utils/config'
import { IS_DEVELOPMENT } from 'utils/constants'
import { setStorageItem, StorageItems, useStorage } from 'utils/storage'

export type State = {
  selfUser: GetSelfUserResponse | undefined
  isLoggedIn: boolean
  isLoading: boolean
}

// https://github.com/aws-amplify/amplify-ui/discussions/200

const StoreContext = createContext<State>({
  selfUser: undefined,
  isLoggedIn: false,
  isLoading: true,
})

export const useAuthStore = () => useContext(StoreContext)

export const AuthController = ({ children }: { children: JSX.Element }) => {
  const { isLoggedIn, isLoading, user: selfUser } = useAuthSelfUser()
  const wasLoggedIn = useRef<undefined | boolean>(isLoggedIn)
  if (isLoggedIn && wasLoggedIn.current === false) {
    setStorageItem(StorageItems.LAST_SUBSCRIPTION_MODAL_DATE, null)
  }
  wasLoggedIn.current = isLoading ? undefined : isLoggedIn

  const accessToken = useStorage(StorageItems.ACCESS_TOKEN)

  // Need to check access token
  const data = useMemo(
    () => ({ selfUser, isLoggedIn, isLoading, accessToken }),
    [selfUser, isLoggedIn, isLoading, accessToken],
  )

  // clearAllSessionStorage()
  // clearAllLocalStorage()

  // useEffect(() => {
  //   console.log(
  //     'loggingOut',
  //     'isLoggedIn',
  //     isLoggedIn,
  //     'isLoading',
  //     isLoading,
  //     selfUser,
  //     accessToken,
  //   )
  // }, [isLoading, isLoggedIn, selfUser, accessToken])

  return (
    <StoreContext.Provider value={data}>
      {isLoading ? <LoadingPage /> : children}
    </StoreContext.Provider>
  )
}

export const useAuthSelfUser = () => {
  const token = useStorage(StorageItems.ACCESS_TOKEN)
  const dispatchSnackbar = useDispatchSnackbar()
  const { data: user, ...rest } = useQuery<
    GetSelfUserResponse | undefined,
    Error,
    GetSelfUserResponse | undefined,
    SelfUserQueryKey
  >(QueryKeyKey.SELF_USER, () => getSelfUser(), {
    // enabled: !!token, // Allow even if token doesn't exist
    retry: false,
    // retry: (failureCount, error) => {
    //   console.log('in retry function for useAuthSelfUser', failureCount, error)
    //   return error.message === 'Unauthorized' ? false : failureCount < 2
    // },
    onError: (err) => {
      console.log('useAuthSelfUser onError', err)
      if (err.message === 'Unauthorized' && token) {
        console.log('removing access token')
        dispatchSnackbar({
          type: 'showSnackbar',
          payload: { message: 'Session expired', type: 'Error' },
        })
        setStorageItem(StorageItems.ACCESS_TOKEN, null)
      } else if (err.message === 'Unauthorized') {
        console.log('No auth')
      } else {
        console.log('Other getSelfUser error', err)
      }
    },
  })

  console.log('useSelfUser rerender useSelfUser status', rest.status)

  if (IS_DEVELOPMENT) {
    console.log('useSelfUser token', token, user, rest)
  }

  const selfUserData = useMemo(
    () => ({
      user,
      id: user?.id,
      formResponses: user?.formResponses,
      isLoggedIn: !!user,
      role: user?.role ?? Role.USER,
      email: user?.email,
      ...rest,
    }),
    [rest, user],
  )
  return selfUserData
}
