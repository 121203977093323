import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Button, Column, Row, Spacer } from 'components'
import { Icon, UtilityIcon } from 'components/Icon'
import { useViewUser } from 'contexts/api/hooks'
import {
  FormCategoryKey,
  FORM_CATEGORY_DATA,
  FORM_ITEMS,
} from 'pages/IntakeFormPage/constants'
import { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Path } from 'router/constants'
import { Header, InfoBody, InfoHeader } from '.'

export const IntakeFormSummary = ({
  category,
  children,
  additionalItems,
}: {
  category: FormCategoryKey
  children?: ReactNode
  additionalItems?: number
}) => {
  const [isShowMore, setIsShowMore] = useState(false)
  const { name, formResponses: intakeFormData } = useViewUser()
  const navigate = useNavigate()
  const moreItemsCount = Math.max(
    FORM_CATEGORY_DATA[category].items.length - 4 + (additionalItems ?? 0),
    0,
  )
  return (
    <div>
      <Header>Profile</Header>
      <Paper>
        <Stack padding={2}>
          <Row justifyContent='space-between'>
            <Row>
              <Avatar alt={name ?? 'Unknown'} {...stringAvatar(name ?? '')} />
              <Column
                style={{
                  marginLeft: 10,
                }}>
                <Typography
                  style={{
                    fontFamily: 'Nunito',
                    fontSize: 22,
                    fontWeight: '600',
                  }}>
                  {name}
                </Typography>
                <Typography style={{ fontFamily: 'Nunito', fontSize: 14 }}>
                  Athlete
                </Typography>
              </Column>
            </Row>
            <IconButton
              aria-label='edit'
              onClick={() =>
                navigate(`/${Path.ATHLETE}/${Path.INTAKE_FORM}/${category}`)
              }>
              <Icon path={UtilityIcon.EDIT} />
            </IconButton>
          </Row>
          <Spacer vertical size={30} />
          <Grid item container spacing={2}>
            {children}
            {FORM_CATEGORY_DATA[category].items
              .slice(
                0,
                !isShowMore
                  ? Math.max(4 - (additionalItems ?? 0), 0)
                  : undefined,
              )
              .map((i) => {
                const { title } = FORM_ITEMS[i]
                return (
                  <Grid key={i} item xs={12} md={6}>
                    <InfoHeader>{title}</InfoHeader>
                    <InfoBody>{intakeFormData?.[i]}</InfoBody>
                  </Grid>
                )
              })}
            <Button
              style={{ padding: 10, marginTop: 10 }}
              fullWidth
              onClick={() => setIsShowMore((s) => !s)}>
              {isShowMore ? 'Hide' : 'Show'} More{' '}
              {moreItemsCount && !isShowMore ? `(${moreItemsCount})` : ''}
            </Button>
          </Grid>
        </Stack>
      </Paper>
    </div>
  )
}

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name: string) {
  const splitName = name.split(' ')
  return {
    style: { backgroundColor: stringToColor(name) },
    children:
      splitName.length < 2
        ? null
        : `${splitName?.[0]?.[0]}${splitName?.[1]?.[0]}`,
  }
}
