import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { Title, TitleSection } from 'components'
import { Feed } from 'components/Feed'
import { Icon } from 'components/Icon'
import { VideoHistory, VideoPinned } from 'components/Video'
import { withPageWrapper } from 'components/withScreenWrapper'
import { useViewUser } from 'contexts/api/hooks'
import { Path, Relationships } from 'router/constants'
import { Goal, Header } from './components'
import { IntakeFormSummary } from './components/IntakeFormSummary'
import { TabPinVideo } from 'contexts/api/types'

const InternalGamePage = () => {
  const { user, formResponses, isSelf } = useViewUser()

  const titlePrefix = isSelf ? (
    ''
  ) : (
    <>
      <TitleSection> {`${user?.name}`}</TitleSection> -{' '}
    </>
  )
  return (
    <>
      <Title style={{ marginBottom: '0.5rem' }}>
        {titlePrefix}Internal Game
      </Title>
      <Divider
        style={{
          borderColor: '#00000050',
          marginBottom: 30,
        }}
      />
      <Grid container spacing={3} paddingBottom={2}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={3} paddingBottom={2}>
            <IntakeFormSummary category={Path.INTERNAL_GAME} />
          </Stack>
          <Grid item xs={12}>
            <VideoPinned tabPinVideo={TabPinVideo.PinnedInternal}/>
          </Grid>
          <Grid item xs={12}>
            <VideoHistory />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={3}>
            <Stack alignItems='stretch' spacing={2}>
              <Header>Goals / Races</Header>
              <Goal
                title='Internal Game Next Year Goals'
                subtitle={formResponses?.mental_next_year_goals}
                icon={<Icon path={Path.INTERNAL_GAME} />}
              />
              <Goal
                title='Internal Game Ultimate Running Goal'
                subtitle={formResponses?.mental_ultimate_goal}
                icon={<Icon path={Path.INTERNAL_GAME} />}
              />
              <Goal
                title='Internal Game Running Goals'
                subtitle={formResponses?.mental_get_from_running_goal}
                icon={<Icon path={Path.INTERNAL_GAME} />}
              />
            </Stack>
            <Feed category={Relationships.INTERNAL_GAME} />
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

export default withPageWrapper(
  InternalGamePage,
  { width: 'max-width' },
  { alignItems: 'stretch' },
)
