import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import {
  useSelfRelationships,
  useSelfUser,
  useUsersByRole,
} from 'contexts/api/hooks'
import { Role } from 'contexts/api/types'
import { useUiDispatch, useUiStore } from 'contexts/ui/Controller'

export function RelationshipAutocomplete() {
  const { role } = useSelfUser()
  const { setViewUserId } = useUiDispatch()
  const { viewUserId } = useUiStore()
  const { relationships } = useSelfRelationships()
  const { users } = useUsersByRole(Role.USER)

  const userOptions =
    role === Role.ADMIN
      ? users.map((u) => ({ id: u.id, name: u.name }))
      : role === Role.COACH
      ? relationships
          .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
          .map((r) => ({ id: r.to.id, name: r.to.name }))
          .filter((r, idx, s) => s.map((r1) => r1.id).indexOf(r.id) === idx)
      : []

  return (
    <Autocomplete
      autoHighlight
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      style={{ width: '100%', paddingLeft: 10, paddingRight: 10 }}
      options={userOptions}
      value={userOptions?.find((v) => v.id === viewUserId) ?? null}
      onChange={(event, newValue) => {
        setViewUserId(newValue?.id)
      }}
      getOptionLabel={(option) => option?.name ?? 'No Name'}
      renderOption={(props, option) => (
        <Box component='li' {...props} key={option?.id}>
          {option?.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField {...params} size='small' label='Select User Profile' />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...rest } = getTagProps({ index })
          return <Chip key={key} label={option?.id} size='small' {...rest} />
        })
      }
    />
  )
}
