import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Column, Row } from 'components'
import { SubTitle } from 'components/SubTitle'

const PaddedPaper = styled(Paper)`
  padding: 10px;
  // border-color: black;
`

export const Goal = ({
  title,
  subtitle,
  icon,
}: {
  title: string
  subtitle?: string
  icon: JSX.Element
}) => {
  return (
    <PaddedPaper>
      <Row justifyContent='flex-start' gap={20} style={{ marginLeft: 20 }}>
        {icon}
        <Column>
          <SubTitle>{title}</SubTitle>
          <Typography>{subtitle}</Typography>
        </Column>
      </Row>
    </PaddedPaper>
  )
}

export const Header = styled(Typography)`
  font-family: Nunito;
  font-weight: 800;
  margin-bottom: 5px;
`

export const Body = styled(Typography)``

export const InfoHeader = styled(Typography)`
  color: #212121;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
`

export const InfoBody = styled(Typography)`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 600;
`
