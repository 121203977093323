import { createContext, ReactNode, useContext } from 'react'
import { INITIAL_STATE } from './constants'
import { usePathParams } from './hooks'

const PathManagerContext = createContext(INITIAL_STATE)

export const usePathManager = () => useContext(PathManagerContext)

const Controller = ({ children }: { children: ReactNode }) => {
  const { page, subpage, subsubpage } = usePathParams()

  return (
    <PathManagerContext.Provider
      value={{
        page,
        subpage,
        subsubpage,
      }}>
      {children}
    </PathManagerContext.Provider>
  )
}
export default Controller
