import MuiButton from '@mui/lab/LoadingButton'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import { CSSProperties, HTMLProps, ReactElement } from 'react'
import { Link as RouterLink } from 'react-router-dom'

export const A = styled('a')`
  color: ${(p) => p.theme.palette.primary.main};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }

  &:active {
    opacity: 0.4;
  }
`

export const Button = styled(MuiButton)(({ disabled }) => ({
  borderRadius: '5px',
  fontWeight: '600',
  padding: '15px 30px 15px 30px',
  color: 'white',
  ...(disabled
    ? {}
    : {
        // backgroundColor: theme.palette.text.primary,
        // color: theme.palette.background.default,
      }),
}))

// https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
type ConditionalWrapperProps = {
  condition: boolean
  wrapper: (children: ReactElement) => JSX.Element
  children: JSX.Element
}
export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps): JSX.Element =>
  condition ? wrapper(children) : children

export const DataGrid = styled(MuiDataGrid)`
  .MuiDataGrid-cell {
    cursor: pointer;
    outline: none !important;
  }
`
export const Column = styled('div', {
  shouldForwardProp: (p) => p !== 'justifyContent' && p !== 'alignItems',
})<
  HTMLProps<HTMLDivElement> & {
    justifyContent?: CSSProperties['justifyContent']
    alignItems?: CSSProperties['alignItems']
  }
>(({ justifyContent, alignItems }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: justifyContent ?? 'flex-start',
  alignItems: alignItems ?? 'stretch',
}))

export const Input = styled('input')`
  border: 2px solid ${(p) => p.theme.palette.border.default};
  border-radius: 4px;
  color: ${(p) => p.theme.palette.text.primary};
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: ${(p) => p.theme.palette.text.primary};
  background-color: ${(p) => p.theme.palette.background.elements};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
  height: 2.75rem;
  padding: 0 0.75rem;

  &::placeholder {
    font-weight: 300;
    color: ${(p) => p.theme.palette.text.secondary};
  }

  &:focus {
    border-color: ${(p) => p.theme.palette.primary.main};
    box-shadow: 0 0 0 3px
      ${(p) =>
        p.theme.palette.primary.main.replace(
          /rgba?(\(\s*\d+\s*,\s*\d+\s*,\s*\d+)(?:\s*,.+?)?\)/,
          'rgba$1,0.2)',
        )};
  }
`

export const InputWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  // input {
  //   margin-top: 0.5rem;
  //   margin-bottom: 0.5rem;
  //   margin-right: 0.5rem;
  // }
`

export const Lead = styled('p')`
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.5;
  color: ${(p) => p.theme.palette.text.secondary};
  margin: 0 0 1.5rem 0;

  strong {
    color: ${(p) => p.theme.palette.text.primary};
  }
`

export const Link = styled(RouterLink)`
  color: ${(p) => p.theme.palette.primary.main};
  text-decoration: none;

  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }

  &:active {
    opacity: 0.4;
  }
`

export const List = styled('ul')`
  padding: 0;
  margin: 3.25rem 0 3.25rem 0;
`

export const P = styled('p')`
  font-size: 1rem;
  line-height: 1.5;
  color: ${(p) => p.theme.palette.text.secondary};
  margin: 0.625rem 0 1.5rem 0;
`

export const Row = styled('div', {
  shouldForwardProp: (p) =>
    p !== 'justifyContent' && p !== 'alignItems' && p !== 'gap',
})<
  HTMLProps<HTMLDivElement> & {
    justifyContent?: CSSProperties['justifyContent']
    alignItems?: CSSProperties['alignItems']
    gap?: CSSProperties['gap']
  }
>(({ justifyContent, alignItems, gap }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: justifyContent ?? 'center',
  alignItems: alignItems ?? 'center',
  gap: gap ?? 0,
}))

type SpacerProps = {
  size?: number
  vertical?: boolean
}
export const Spacer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'vertical',
})<SpacerProps>(({ theme, size, vertical }) =>
  vertical
    ? {
        height: size ?? theme.spacing(2),
      }
    : {
        width: size ?? theme.spacing(2),
      },
)

export const Title = styled('h1')`
  font-size: 32px;
  font-weight: bold;
  color: ${(p) => p.theme.palette.text.primary};
`

export const TitleSection = styled('span')`
  font-size: 32px;
  font-weight: bold;
  color: ${(p) => p.theme.palette.primary.main};
`
// padding-top: 20px;

export const DataGridTypography = styled(Typography)`
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
),`

export const TooltipDataGridTypography = ({
  value,
  tooltip,
}: {
  value: string
  tooltip?: string
}) => (
  <Tooltip title={tooltip ?? value}>
    <DataGridTypography>{value}</DataGridTypography>
  </Tooltip>
)
