import { useSelfUser } from 'contexts/api/hooks'
import { LoadingPage } from 'pages/LoadingPage'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

export type State = {
  viewUserId: UserId | undefined
}

export type Action = {
  setViewUserId: Dispatch<SetStateAction<UserId | undefined>>
}

// https://github.com/aws-amplify/amplify-ui/discussions/200

const StoreContext = createContext<State>({ viewUserId: undefined })
const DispatchContext = createContext<Action>({
  setViewUserId: () => undefined,
})

export const useUiStore = () => useContext(StoreContext)
export const useUiDispatch = () => useContext(DispatchContext)

export type UserId = number

export const UiController = ({ children }: { children: JSX.Element }) => {
  const { isLoggedIn, isLoading } = useSelfUser()
  const [viewUserId, setViewUserId] = useState<UserId>()

  // The intake form data is only used for the first load of data

  const dispatchData = useMemo(() => ({ setViewUserId }), [])
  const data = useMemo(() => ({ viewUserId }), [viewUserId])

  useEffect(() => {
    if (!isLoggedIn) {
      setViewUserId(undefined)
    }
  }, [isLoggedIn])

  return (
    <DispatchContext.Provider value={dispatchData}>
      <StoreContext.Provider value={data}>
        {isLoading ? <LoadingPage /> : children}
      </StoreContext.Provider>
    </DispatchContext.Provider>
  )
}
