import { Path } from 'router/constants'
import { usePathManager } from 'contexts/pathManager'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormCategoryKey, FORM_CATEGORIES } from './constants'

export type NewPage = 'prev' | 'next' | 'done' | number

export const useNavigatePage = () => {
  const navigate = useNavigate()
  const formIndex = useFormIndex()

  const navigatePage = useCallback(
    (newPage: NewPage) => {
      if (newPage === 'done') {
        navigate(`/${Path.ATHLETE}/${Path.SUBSCRIPTION}`)
      } else {
        const newIndex =
          typeof newPage === 'number'
            ? newPage
            : newPage === 'next'
            ? formIndex + 1
            : formIndex - 1
        const newSubpage =
          newIndex === FORM_CATEGORIES.length
            ? Path.INTAKE_FORM_COMPLETE
            : newIndex < 0
            ? ''
            : FORM_CATEGORIES[newIndex]
        navigate(`/${Path.ATHLETE}/${Path.INTAKE_FORM}/${newSubpage}`)
      }
    },
    [formIndex, navigate],
  )

  return navigatePage
}

export const useFormIndex = () => {
  const { subpage, subsubpage } = usePathManager()
  if (subpage !== Path.INTAKE_FORM) {
    throw new Error('Attempted to get form index for non form page')
  }

  const intakeFormIndex = subsubpage
    ? FORM_CATEGORIES.indexOf(subsubpage as FormCategoryKey)
    : -1

  return intakeFormIndex
}
