import { Button, List } from 'components'
import { FormScaleItem } from 'components/FormScaleItem'
import MDProgressBar from 'components/MDProgressBar'
import { SubTitle } from 'components/SubTitle'
import {
  useMutateIntakeForm,
  useSelfUser,
  useViewUser,
} from 'contexts/api/hooks'
import { isValidForm } from 'contexts/api/functions'
import { useCallback, useRef, useState } from 'react'
import {
  FormCategoryKey,
  FORM_CATEGORIES,
  FORM_CATEGORY_DATA,
  FORM_ITEMS,
  IntakeFormData,
  isFormItemRadioType,
  isFormItemScaleType,
  isFormItemTextType,
} from '../constants'
import { NewPage, useFormIndex, useNavigatePage } from '../hooks'
import { FormGroup } from './FormGroup'
import { FormRadioItem } from './FormRadioItem'
import { FormTextItem } from './FormTextItem'
import { NavButtonGroup } from './NavButtonGroup'
import { useShowDialog } from 'contexts/dialog/Controller'
import Typography from '@mui/material/Typography'

type FormProps = {
  category: FormCategoryKey
}

type SubmittedDict = Record<FormCategoryKey, boolean>
const initialSubmittedDict: SubmittedDict =
  FORM_CATEGORIES.reduce<SubmittedDict>(
    (accu, category) => ({ ...accu, [category]: false }),
    {} as SubmittedDict,
  )

export function Form({ category }: FormProps) {
  const [wasSubmittedDict, setWasSubmittedDict] =
    useState<SubmittedDict>(initialSubmittedDict)
  const formIndex = useFormIndex()
  const formRef = useRef<HTMLFormElement>(null)
  const { formResponses: intakeFormData, user: viewUser } = useViewUser()
  const { mutateAsync } = useMutateIntakeForm()
  const navigatePage = useNavigatePage()
  const showDialog = useShowDialog()
  const { user: selfUser } = useSelfUser()
  // console.debug('Form rerender intakeFormData', intakeFormData)

  const { title, items, subtitle } = FORM_CATEGORY_DATA[category]

  const handlePageChange = useCallback(
    (newPage: NewPage) => () => {
      setWasSubmittedDict((sd) => ({ ...sd, [category]: true }))
      const formData = new FormData(formRef.current ?? undefined)

      if (!isValidForm(formData)) {
        console.log('is invalid form!')
        showDialog({
          title: 'Intake Form Errors',
          subtitle: 'Please correct form issues to continue',
          option2Text: null,
        })
        return
      }

      // If the old value is undefined then the new value can be '' or undefined

      const updatedData = Object.fromEntries(
        Array.from(formData.entries()).filter(([k, v]) => {
          const existingValue = intakeFormData?.[k as keyof IntakeFormData]
          if (existingValue === undefined && v === '') return false
          if (existingValue !== v) return true
        }),
      )
      // console.debug(
      //   'updatedData',
      //   updatedData,
      //   'formData',
      //   formData,
      //   'intakeFormData',
      //   intakeFormData,
      // )

      if (!Object.keys(updatedData).length) {
        navigatePage(newPage)
        return
      }

      if (selfUser?.id !== viewUser?.id) {
        showDialog({
          title: 'Not Yet Implemented',
          subtitle: `Currently, only users are able to edit their own data. Please contact ${viewUser?.name} to have them update their data`,
          onOption1: () => navigatePage(newPage),
          option2Text: null,
        })
        return
      }

      console.log('updatedData', updatedData)
      mutateAsync({
        ...updatedData,
        ...(newPage === 'done' ? { done: 'true' } : {}),
      }).then(() => navigatePage(newPage))
    },
    [
      category,
      intakeFormData,
      selfUser?.id,
      viewUser?.id,
      viewUser?.name,
      showDialog,
      navigatePage,
      mutateAsync,
    ],
  )

  // console.debug('Form reredner intakeFormData', intakeFormData)
  return (
    <>
      <MDProgressBar
        current={formIndex + 1}
        total={FORM_CATEGORIES.length}
        onChange={handlePageChange}
      />
      <FormGroup noValidate onSubmit={handlePageChange('next')} ref={formRef}>
        <List>
          <SubTitle>
            {title}{' '}
            <span style={{ fontWeight: '500' }}>
              ({items.length} questions)
            </span>
          </SubTitle>
          {subtitle ? <Typography>{subtitle}</Typography> : null}
          {items.map((formKey) => {
            const value = FORM_ITEMS[formKey]
            const initialValue = intakeFormData?.[formKey]
            if (isFormItemRadioType(value)) {
              const { placeholder, title, subtitle, type, items } = value
              return (
                <FormRadioItem
                  key={formKey}
                  name={formKey}
                  wasSubmitted={wasSubmittedDict[category]}
                  placeholder={placeholder}
                  title={title}
                  subtitle={subtitle}
                  items={items}
                  initialValue={initialValue}
                  allowOther={type === 'radio_input'}
                />
              )
            } else if (isFormItemScaleType(value)) {
              const { title, subtitle, low_text, high_text } = value
              return (
                <FormScaleItem
                  key={formKey}
                  name={formKey}
                  initialValue={initialValue}
                  wasSubmitted={wasSubmittedDict[category]}
                  title={title}
                  subtitle={subtitle}
                  lowText={low_text}
                  highText={high_text}
                />
              )
            } else if (isFormItemTextType(value)) {
              const { placeholder, title, subtitle, type } = value
              return (
                <FormTextItem
                  key={formKey}
                  name={formKey}
                  initialValue={initialValue}
                  type={type}
                  wasSubmitted={wasSubmittedDict[category]}
                  placeholder={placeholder}
                  title={title}
                  subtitle={subtitle}
                />
              )
            } else {
              throw new Error('Unrecognized FormItem type')
            }
          })}
        </List>
        <NavButtonGroup>
          {formIndex === FORM_CATEGORIES.length - 1 ? (
            <Button variant='contained' onClick={handlePageChange('done')}>
              Submit
            </Button>
          ) : (
            <Button variant='contained' onClick={handlePageChange('next')}>
              Next
            </Button>
          )}
          {formIndex >= 1 && (
            <Button variant='contained' onClick={handlePageChange('prev')}>
              Prev
            </Button>
          )}
        </NavButtonGroup>
      </FormGroup>
    </>
  )
}
