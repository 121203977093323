import { LoadingPage } from 'pages/LoadingPage'
import { lazyLoad } from 'utils/loadable'

export const FormPage = lazyLoad(
  () => import('./index'),
  (module) => module.default,
  {
    fallback: <LoadingPage />,
  },
)
