import { useSelfUser } from 'contexts/api/hooks'
import { LoadingPage } from 'pages/LoadingPage'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { StorageItems, setStorageItem } from 'utils/storage'

export const ProtectedRoute = ({ redirectTo }: { redirectTo: string }) => {
  const { isLoggedIn, isLoading } = useSelfUser()
  const location = useLocation()

  if (!isLoggedIn) {
    console.log(
      'setStorageItem AUTH_REDIRECT',
      location.pathname,
      'redirectTo',
      redirectTo,
      'isLoggedIn',
      isLoggedIn,
    )
    setStorageItem(StorageItems.AUTH_REDIRECT, location.pathname, true)
  }

  // console.log(
  //   'ProtectedRoute',
  //   'redirectTo',
  //   redirectTo,
  //   'isLoggedIn',
  //   isLoggedIn,
  //   'isLoading',
  //   isLoading,
  // )
  return isLoading ? (
    <LoadingPage />
  ) : isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={redirectTo} replace state={{ from: location }} />
  )
}
