import { MediaType, NonNullableProperties, Truthy } from './types'

export function titleCase(str: string, type: 'word' | 'sentence' = 'sentence') {
  return str
    .toLowerCase()
    .split(' ')
    .map((word, idx) =>
      type === 'word' || (type === 'sentence' && idx === 0)
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word,
    )
    .join(' ')
}

export function snakeCaseToTitleCase(snakeCase: string) {
  return titleCase(snakeCase.replaceAll('_', ' '))
}

export const bound = (variable: number, min: number, max: number) =>
  Math.max(min, Math.min(variable, max))

export const isTruthy = <T>(value: T): value is Truthy<T> => Boolean(value)

export const isEmail = (email: string) => {
  const newLocal =
    // eslint-disable-next-line no-control-regex
    /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$/
  return newLocal.test(email)
}

// export const filterKey = <T, U extends string>(obj: T, keyToRemove:U): T & { U: never }  => {
//   return Object.fromEntries(Object.entries(obj).filter(([key, value]) => key !== keyToRemove)) as (T & { U: never })
// }

export const getFileUrlType = (f: string): MediaType | undefined => {
  const AUDIO = ['mp3']
  const VIDEO = ['mov', 'mp4']
  const IMAGE = ['jpeg', 'jpg', 'png']

  const extension = f.split('.').slice(-1)[0].toLowerCase()
  const mediaType = IMAGE.includes(extension)
    ? MediaType.Image
    : VIDEO.includes(extension)
    ? MediaType.Video
    : AUDIO.includes(extension)
    ? MediaType.Audio
    : MediaType.Other

  console.log('getFileUrlType', mediaType)
  return mediaType
}

export const getFileType = (f: File): MediaType | undefined =>
  getFileUrlType(f.name)

export const onlyUnique = <T>(value: T, index: number, self: T[]) => {
  return self.indexOf(value) === index
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeEmpty = <T extends { [key: string]: any }>(obj: T) =>
  Object.fromEntries(
    Object.entries(obj).filter(([, v]) => v !== null && v !== undefined),
  ) as NonNullableProperties<T>
