import { ReactNode, useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Path, PATH_TO_HELMET } from 'router/constants'
import { titleCase } from 'utils/functions'

export const HelmetController = ({ children }: { children: ReactNode }) => {
  const [path, setPath] = useState<Path>(Path.HOME)

  const listenToPopstate = () => {
    const winPath = window.location.pathname
    setPath(winPath.split('?')[0].split('/').pop() as Path)
  }

  useEffect(() => {
    window.addEventListener('popstate', listenToPopstate)
    return () => {
      window.removeEventListener('popstate', listenToPopstate)
    }
  }, [])

  const titleText = PATH_TO_HELMET[path]?.title ?? titleCase(path)
  const descriptionText = PATH_TO_HELMET[path]?.description ?? ''

  return (
    <HelmetProvider>
      <Helmet>
        <title>{titleText}</title>
        <meta name='description' content={descriptionText} />
      </Helmet>
      {children}
    </HelmetProvider>
  )
}
