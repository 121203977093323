import { ReactNode, StrictMode } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from 'contexts/theme/ThemeProvider'
import { SnackbarController } from 'contexts/snackbar'

// Development modules variables in the window
import 'utils/consoleModules'
import { BrowserRouter } from 'react-router-dom'
import { DialogController } from 'contexts/dialog'
import { PathManagerController } from 'contexts/pathManager'
import PaymentModalProvider from './braintree/PaymentModalProvider'
import { HelmetController } from './HelmetController'
import { UiController } from './ui/Controller'
import { AuthController } from './auth/Controller'
import SubscriptionModalProvider from './braintree/SubscriptionModalProvider'

const AppProviders = ({
  queryClient,
  children,
}: {
  queryClient: QueryClient
  children: ReactNode
}) => (
  <StrictMode>
    <ThemeProvider>
      <HelmetController>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <SnackbarController>
              <DialogController>
                {/* Auth must be inside browser router */}
                <AuthController>
                  <SubscriptionModalProvider>
                    {/* IntakeFormData must be inside auth */}
                    <PathManagerController>
                      <UiController>
                        <PaymentModalProvider>{children}</PaymentModalProvider>
                      </UiController>
                    </PathManagerController>
                  </SubscriptionModalProvider>
                </AuthController>
              </DialogController>
            </SnackbarController>
          </BrowserRouter>
        </QueryClientProvider>
      </HelmetController>
    </ThemeProvider>
  </StrictMode>
)

export default AppProviders
