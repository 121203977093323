import { Role } from 'contexts/api/types'

export type SortOption = 'lastName' | 'priceLow' | 'priceHigh'

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>> //  OnlyNameIsMandatory = PartialBy<Person, 'nickname'|'hometown'>

//https://www.typescriptlang.org/docs/handbook/2/mapped-types.html
export type PartialNull<T> = {
  [Property in keyof T]: T[Property] | null
}

//https://www.typescriptlang.org/docs/handbook/2/mapped-types.html
// Turns all properties into strings (objects handled recursively), Helpful for autocomplete
export type RecursiveStringProperties<T> = {
  [P in keyof T]-?: T[P] extends boolean | string | number
    ? string
    : RecursiveStringProperties<T[P]>
}

export type StringKeys<T> = Extract<keyof T, string>

export type Reducer<S, A> = (state: S, action: A) => S

export type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T

export type LocationState = null | { from?: { pathname?: string } }

export type KeyOmit<T, U extends keyof T> = T & { [P in U]?: never }

export enum MediaType {
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
  Other = 'other',
}

type Enum = Role

// For form data
export type StringOrArray<T> = {
  [K in keyof T]-?: T[K] extends boolean
    ? boolean
    : T[K] extends unknown[]
    ? unknown[]
    : T[K] extends Enum
    ? T[K]
    : T[K] extends object // json like address
    ? T[K]
    : string
}

export type NonNullableProperties<T> = {
  [P in keyof T]-?: NonNullable<T[P]>
}

export type NestError = {
  config: {
    transitional: object
    transformRequest: number[]
    transformResponse: number[]
    timeout: number
    adapter: () => void
  }
  data: { statusCode: number; message: string }
  headers: { 'content-length': string; 'content-type': string }
  message: string
  request: XMLHttpRequest
  status: number
  statusText: string
}
