import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { Title, TitleSection } from 'components'
import { Feed } from 'components/Feed'
import { Icon } from 'components/Icon'
import { withPageWrapper } from 'components/withScreenWrapper'
import { useViewUser } from 'contexts/api/hooks'
import { Path, Relationships } from 'router/constants'
import { Goal, Header } from './components'
import { IntakeFormSummary } from './components/IntakeFormSummary'
import { VideoHistory, VideoPinned } from 'components/Video'
import { TabPinVideo } from 'contexts/api/types'

const NutritionPage = () => {
  const { user, formResponses, isSelf } = useViewUser()
  
  const titlePrefix = isSelf ? (
    ''
  ) : (
    <>
      <TitleSection> {`${user?.name}`}</TitleSection> -{' '}
    </>
  )
  return (
    <>
      <Title style={{ marginBottom: '0.5rem' }}>{titlePrefix}Nutrition</Title>
      <Divider
        style={{
          borderColor: '#00000050',
          marginBottom: 30,
        }}
      />
      <Grid container spacing={3} paddingBottom={2}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={3} paddingBottom={2}>
            <IntakeFormSummary category={Path.NUTRITION} />
            {/* <div>
              <Header>Pacing Profile</Header>
              <Paper style={{ padding: 10 }}>
                <Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Sed felis eget velit aliquet sagittis. Dui id ornare arcu odio
                  ut.
                </Body>
              </Paper>
            </div> */}
          </Stack>
          <Grid item xs={12}>
            <VideoPinned tabPinVideo={TabPinVideo.PinnedNutrition}/>
          </Grid>
          <Grid item xs={12}>
            <VideoHistory />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={3}>
            <Stack alignItems='stretch' spacing={2}>
              <Header>Goals / Races</Header>
              <Goal
                title='Nutrition General Goal'
                subtitle={formResponses?.nut_goals}
                icon={<Icon path={Path.NUTRITION} />}
              />
              <Goal
                title='Nutrition Macro Goal'
                subtitle={formResponses?.nut_macro_goal}
                icon={<Icon path={Path.NUTRITION} />}
              />
              <Goal
                title='Nutrition Weight Goal'
                subtitle={formResponses?.nut_goal_weight}
                icon={<Icon path={Path.NUTRITION} />}
              />
              {/* <Spacer vertical /> */}
            </Stack>
            <Feed category={Relationships.NUTRITION} />
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

export default withPageWrapper(
  NutritionPage,
  { width: 'max-width' },
  { alignItems: 'stretch' },
)
