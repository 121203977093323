import grey from '@mui/material/colors/grey'
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette'
import createTheme, { ThemeOptions } from '@mui/material/styles/createTheme'
import { useEffect, useMemo, useState } from 'react'

// MUI with styled components
//https://mui.com/guides/interoperability/#styled-components

// const lightTheme = {
//   primary: 'rgba(215,113,88,1)',
//   primaryAccent: 'rgba(200,100,76,1)',
//   text: 'rgba(58,52,51,1)',
//   textSecondary: 'rgba(58,52,51,0.7)',
//   background: 'rgba(255,255,255,1)',
//   backgroundVariant: 'rgba(251,249,249,1)',
//   border: 'rgba(58,52,51,0.12)',
//   borderLight: 'rgba(58,52,51,0.05)',
// }

// const darkTheme: Theme = {
//   primary: 'rgba(220,120,95,1)',
//   primaryAccent: 'rgba(207,106,80,1)',
//   text: 'rgba(241,233,231,1)',
//   textSecondary: 'rgba(241,233,231,0.6)',
//   background: 'rgba(0,0,0,1)',
//   backgroundVariant: 'rgba(28,26,26,1)',
//   border: 'rgba(241,233,231,0.15)',
//   borderLight: 'rgba(241,233,231,0.05)',
// }

// export type Theme = typeof lightTheme

// export const themes = {
//   light: lightTheme,
//   dark: darkTheme,
// }

// export const useTheme = (): {
//   chosenTheme: ChosenThemeKey
//   theme: Theme
//   setTheme: Dispatch<SetStateAction<ChosenThemeKey>>
//   themeKey: ThemeKey
// } => {
//   const [chosenTheme, setChosenTheme] = useState<ChosenThemeKey>('light')
//   const isSystemDark = useIsSystemDark()
//   const themeKey =
//     chosenTheme !== 'system' ? chosenTheme : isSystemDark ? 'dark' : 'light'
//   // const storedTheme = getThemeFromStorage()

//   // const usedTheme = storedTheme === null ?
//   //   chosenTheme === 'system' ?
//   //   themeMode
//   //   :themeMode
//   //  === 'chosenTheme === 'system' ? themeMode

//   // Store the theme if it is selected
//   // useEffect(() => {
//   //   if (chosenTheme){
//   //     saveTheme(chosenTheme)
//   //   }

//   // }, [chosenTheme])
//   return {
//     chosenTheme,
//     theme: themes[themeKey],
//     setTheme: setChosenTheme,
//     themeKey,
//   }
// }

export type ThemeKey = 'light' | 'dark' | 'system'

const matchDark = '(prefers-color-scheme: dark)'
export const useIsSystemDark = () => {
  const [isDark, setIsDark] = useState(
    () => window.matchMedia && window.matchMedia(matchDark).matches,
  )

  useEffect(() => {
    const matcher = window.matchMedia(matchDark)
    const onChange: ({ matches }: { matches: boolean }) => void = ({
      matches,
    }) => setIsDark(matches)
    matcher.addEventListener('change', onChange)
    return () => matcher.removeEventListener('change', onChange)
  }, [])

  return isDark
}

export function saveTheme(theme: ThemeKey) {
  window.localStorage && localStorage.setItem('selectedTheme', theme)
}

/* istanbul ignore next line */
export function getThemeFromStorage(): ThemeKey | null {
  return window.localStorage
    ? (localStorage.getItem('selectedTheme') as ThemeKey) || null
    : null
}

// const orangeForDark: SimplePaletteColorOptions = {
//   light: '#ffa843',
//   main: '#dc7809',
//   dark: '#a44b00',
//   contrastText: '#FFF',
// }

const purpleForDark: SimplePaletteColorOptions = {
  light: '#b45bb7',
  main: '#a233a6',
  dark: '#712374',
  contrastText: '#FFF',
}

// const orangeForLight: SimplePaletteColorOptions = {
//   light: '#ffa843',
//   main: '#dc7809',
//   dark: '#a44b00',
//   contrastText: '#000',
// }

const goldForLight: SimplePaletteColorOptions = {
  light: '#c1aa7b',
  main: '#b2955b',
  dark: '#7c683f',
  contrastText: '#000',
}

const purpleForLight: SimplePaletteColorOptions = {
  light: '#a233a6',
  main: '#8b0090',
  dark: '#610064',
  contrastText: '#000',
}

const BACKGROUND_COLOR = '#ECECEC'

//https://mui.com/customization/dark-mode/
export const getDesignTokens = (mode: 'light' | 'dark'): ThemeOptions => ({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
          backgroundColor: BACKGROUND_COLOR,
        },
        'body.interLoaded': {
          fontFamily: 'Inter, Helvetica Neue, Helvetica, Arial, sans-serif',
        },
        p: {
          lineHeight: '1.5em',
        },
        label: {
          lineHeight: '1.5em',
        },
        input: {
          fontFamily: 'inherit',
          fontSize: 'inherit',
        },
        select: {
          fontFamily: 'inherit',
          fontSize: 'inherit',
        },
        button: {
          fontFamily: 'inherit',
          fontSize: 'inherit',
        },
        '.icon': {
          width: '1.5rem',
          height: '1.5rem',
        },
        '@keyframes sign-in-spin': {
          from: {
            transform: 'rotate(360deg)',
          },
          to: {
            transform: 'rotate(0deg)',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          padding: 10,
        },
        root: {
          padding: 10,
        },
      },
    },
  },
  shape: {
    borderRadius: 10,
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: goldForLight,
          secondary: purpleForLight,
          divider: 'rgba(0,0,0,0.12)',
          text: {
            primary: grey[900],
            secondary: grey[800],
          },
          background: {
            default: BACKGROUND_COLOR,
            elements: 'rgba(251,249,249,1)',
            paper: '#FFFFFF',
          },
          action: {
            active: '#000000',
          },
          icon: {
            default: '#121212',
          },
          border: {
            default: 'rgba(58,52,51,0.12)',
            light: 'rgba(58,52,51,0.05)',
          },
        }
      : {
          // palette values for dark mode
          primary: goldForLight,
          secondary: purpleForDark,
          divider: 'rgba(255,255,255,.12)',
          background: {
            default: '#121212',
            paper: '#121212',
          },
          text: {
            primary: grey[50],
            secondary: grey[500],
          },
          action: {
            active: '#ffffff',
          },
          icon: {
            default: '#ededed',
          },
          border: {
            default: 'rgba(241,233,231,0.15)',
            light: 'rgba(241,233,231,0.05)',
          },
        }),
  },
})

export const useMuiTheme = (mode: 'light' | 'dark') => {
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode])
  return theme
}

declare module '@mui/material/styles' {
  interface Theme {
    status?: {
      danger: string
    }
    // whiteShadows: string[]
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger: string
    }
    // whiteShadows: string[]
  }
  interface Palette {
    icon: {
      default: string
    }
    border: {
      default: string
      light: string
    }
  }
  interface PaletteOptions {
    icon: {
      default: string
    }
    border: {
      default: string
      light: string
    }
  }
  interface TypeBackground {
    default: string
    elements: string
    paper: string
  }
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    default: string
    elements: string
    paper: string
  }
}

export enum ThemeConstants {
  NAV_BAR_HEIGHT = 50,
  SIDEBAR_WIDTH = 250,
  SCREEN_MAX_WIDTH = 1400,
  FORM_MAX_WIDTH = 480,
}
