// import { Button } from 'components'
import CircularProgress from '@mui/material/CircularProgress'
import Slide, { SlideProps } from '@mui/material/Slide'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import React, {
  createContext,
  Dispatch,
  SyntheticEvent,
  useContext,
  useReducer,
} from 'react'
import { reducer } from './reducer'
import { Action } from './types'
import { INITIAL_STATE } from './constants'
import Button from '@mui/material/Button'

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction='up' />
}

export const DispatchContext = createContext<Dispatch<Action>>(() => undefined)

export const useDispatch = () => useContext(DispatchContext)

const Controller = ({ children }: { children: React.ReactNode }) => {
  const [data, reduceData] = useReducer(reducer, INITIAL_STATE)
  const { show, timeout, message, type } = data

  const handleCloseSnackbar = (
    event: SyntheticEvent<unknown> | Event,
    reason: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    reduceData({ type: 'resetSnackbar' })
  }

  const handleCloseButton = () => {
    reduceData({ type: 'resetSnackbar' })
  }

  const action =
    timeout === 0 ? (
      <CircularProgress size={32} />
    ) : (
      <Button
        onClick={handleCloseButton}
        color={type === 'Error' ? 'secondary' : 'primary'}
        size='small'>
        close
      </Button>
    )

  return (
    <DispatchContext.Provider value={reduceData}>
      {children}
      <Snackbar
        open={show}
        autoHideDuration={timeout === 0 ? null : timeout}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
        TransitionComponent={SlideTransition}
        message={message}
        action={action}
      />
    </DispatchContext.Provider>
  )
}

export default Controller
