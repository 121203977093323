import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { Title, TitleSection } from 'components'
import { Feed } from 'components/Feed'
import { withPageWrapper } from 'components/withScreenWrapper'
import { useViewUser } from 'contexts/api/hooks'
import { Relationships } from 'router/constants'
import { Goal } from './components'
import { Icon } from 'components/Icon'
import { Path } from 'router/constants'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useShowDialog } from 'contexts/dialog/Controller'

function DashboardPage() {
  const { user, formResponses, isSelf } = useViewUser()
  const [params] = useSearchParams()
  const isNew = params.get('new')
  const [loaded, setLoaded] = useState(false)
  const showDialog = useShowDialog()
  const navigate = useNavigate()

  useEffect(() => {
    if (loaded || !isNew) return

    setLoaded(true)
    showDialog({
      title: 'Welcome!',
      subtitle:
        "You're one step further in your holistic journey. Start setting your goals by completing the athlete intake questionnaire or subscribe to receive online coaching",
      option1Text: 'Continue',
      onOption1: undefined,
      onOption2: undefined,
    })
  }, [isNew, loaded, navigate, showDialog])
  const titlePrefix = isSelf ? (
    ''
  ) : (
    <>
      <TitleSection> {`${user?.name}`}</TitleSection> -{' '}
    </>
  )

  return (
    <>
      <Title style={{ marginBottom: '0.5rem' }}>{titlePrefix}Dashboard</Title>
      <Divider
        style={{
          borderColor: '#00000050',
          marginBottom: 30,
        }}
      />
      <Grid container spacing={3} paddingBottom={2}>
        <Grid item xs={12} md={6}>
          <Title>Personal Goals</Title>
          <Stack alignItems='stretch' spacing={2}>
            <Goal
              title='Sleep Hours Goal'
              subtitle={formResponses?.sleep_hours_goals}
              icon={<Icon path={Path.REST} />}
            />
            <Goal
              title='Rest Screen Time Goal'
              subtitle={formResponses?.sleep_screen_goal}
              icon={<Icon path={Path.REST} />}
            />

            <Goal
              title='Nutrition General Goal'
              subtitle={formResponses?.nut_goals}
              icon={<Icon path={Path.NUTRITION} />}
            />
            <Goal
              title='Nutrition Macro Goal'
              subtitle={formResponses?.nut_macro_goal}
              icon={<Icon path={Path.NUTRITION} />}
            />
            <Goal
              title='Nutrition Weight Goal'
              subtitle={formResponses?.nut_goal_weight}
              icon={<Icon path={Path.NUTRITION} />}
            />

            <Goal
              title='One Percent Race Weight Goal'
              subtitle={formResponses?.op_weight_race_goal}
              icon={<Icon path={Path.INTERNAL_GAME} />}
            />

            <Goal
              title='Internal Game Next Year Goals'
              subtitle={formResponses?.mental_next_year_goals}
              icon={<Icon path={Path.INTERNAL_GAME} />}
            />
            <Goal
              title='Internal Game Ultimate Running Goal'
              subtitle={formResponses?.mental_ultimate_goal}
              icon={<Icon path={Path.INTERNAL_GAME} />}
            />
            <Goal
              title='Internal Game Running Goals'
              subtitle={formResponses?.mental_get_from_running_goal}
              icon={<Icon path={Path.INTERNAL_GAME} />}
            />
            <Goal
              title='400m Next Year Goal'
              subtitle={formResponses?.train_400_nextyear_goal}
              icon={<Icon path={Path.TRAINING} />}
            />
            <Goal
              title='800m Next Year Goal'
              subtitle={formResponses?.train_800_nextyear_goal}
              icon={<Icon path={Path.TRAINING} />}
            />
            <Goal
              title='Mile Next Year Goal'
              subtitle={formResponses?.train_mile_nextyear_goal}
              icon={<Icon path={Path.TRAINING} />}
            />
            <Goal
              title='5000m Next Year Goal'
              subtitle={formResponses?.train_5000_nextyear_goal}
              icon={<Icon path={Path.TRAINING} />}
            />
            <Goal
              title='10000m Next Year Goal'
              subtitle={formResponses?.train_10000_nextyear_goal}
              icon={<Icon path={Path.TRAINING} />}
            />
            <Goal
              title='Half Marathon Next Year Goal'
              subtitle={formResponses?.train_half_nextyear_goal}
              icon={<Icon path={Path.TRAINING} />}
            />
            <Goal
              title='Marathon Next Year Goal'
              subtitle={formResponses?.train_marathon_nextyear_goal}
              icon={<Icon path={Path.TRAINING} />}
            />
            <Goal
              title='400m Lifetime Goal'
              subtitle={formResponses?.train_400_lifetime_goal}
              icon={<Icon path={Path.TRAINING} />}
            />
            <Goal
              title='800m Lifetime Goal'
              subtitle={formResponses?.train_800_lifetime_goal}
              icon={<Icon path={Path.TRAINING} />}
            />
            <Goal
              title='Mile Lifetime Goal'
              subtitle={formResponses?.train_mile_lifetime_goal}
              icon={<Icon path={Path.TRAINING} />}
            />
            <Goal
              title='5000m Lifetime Goal'
              subtitle={formResponses?.train_5000_lifetime_goal}
              icon={<Icon path={Path.TRAINING} />}
            />
            <Goal
              title='10000m Lifetime Goal'
              subtitle={formResponses?.train_10000_lifetime_goal}
              icon={<Icon path={Path.TRAINING} />}
            />
            <Goal
              title='Half Marathon Lifetime Goal'
              subtitle={formResponses?.train_half_lifetime_goal}
              icon={<Icon path={Path.TRAINING} />}
            />
            <Goal
              title='Marathon Lifetime Goal'
              subtitle={formResponses?.train_marathon_lifetime_goal}
              icon={<Icon path={Path.TRAINING} />}
            />
            {/* <Goal
              title='Ultimate Running Goal'
              subtitle={goal_training_ultimate_goal}
              icon={<Icon path={Path.TRAINING} />}
            />
            <Goal
              title='Next Year Running Goals'
              subtitle={goal_training_year_goal}
              icon={<Icon path={Path.TRAINING} />}
            />
            <Goal
              title='Nutrition Goals'
              subtitle={nut_goals}
              icon={<Icon path={Path.NUTRITION} />}
            />
            <Goal
              title='Rest Goals'
              subtitle={rest_goals}
              icon={<Icon path={Path.REST} />}
            />
            <Goal
              title='Internal Game Goals'
              subtitle={internal_game_goals}
              icon={<Icon path={Path.INTERNAL_GAME} />}
            /> */}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Feed category={Relationships.GENERAL} />
        </Grid>
      </Grid>
    </>
  )
}

export default withPageWrapper(
  DashboardPage,
  { width: 'max-width' },
  { alignItems: 'stretch' },
)
