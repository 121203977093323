import { AuthRoute } from 'components/AuthRoute'
import { Nav } from 'components/Nav'
import { ProtectedCoachContent } from 'components/ProtectedCoachContent'
import { ProtectedRoute } from 'components/ProtectedRoute'
import { ViewUserContentRoute } from 'components/ViewUserContentRoute'
import AboutPage from 'pages/AthletePages/AboutPage'
import AccountPage from 'pages/AthletePages/AccountPage'
import CoachRelationsPage from 'pages/AthletePages/CoachRelationsPage'
import DashboardPage from 'pages/AthletePages/DashboardPage'
import InternalGamePage from 'pages/AthletePages/InternalGamePage'
import { NotificationsPage } from 'pages/AthletePages/NotificationsPage'
import NutritionPage from 'pages/AthletePages/NutritionPage'
import OnePercentPage from 'pages/AthletePages/OnePercentPage'
import RestPage from 'pages/AthletePages/RestPage'
import { SettingsPage } from 'pages/AthletePages/SettingsPage'
import TrainingPage from 'pages/AthletePages/TrainingPage'
import UserRelationsPage from 'pages/AthletePages/UserRelationsPage'
import VideoPage from 'pages/AthletePages/VideoPage'
import { ForgotPasswordPage } from 'pages/ForgotPasswordPage/Loadable'
import { HomePage } from 'pages/HomePage/Loadable'
import { Complete } from 'pages/IntakeFormPage/components/Complete'
import { Form } from 'pages/IntakeFormPage/components/Form'
import { FORM_CATEGORIES } from 'pages/IntakeFormPage/constants'
import { FormPage } from 'pages/IntakeFormPage/Loadable'
import { LogoutPage } from 'pages/LogoutPage/Loadable'
import { NotFoundPage } from 'pages/NotFoundPage/Loadable'
import { PlansPage } from 'pages/PlansPage/Loadable'
import PublicSubscriptionPage from 'pages/PublicSubscriptionPage/PublicSubscriptionPage'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Path } from './constants'
import { WaiverPage } from 'pages/WaiverPage'
import SpecialSubscriptionPage from 'pages/SpecialSubscriptionPage/SpecialSubscriptionPage'

export default function Router() {
  const { search } = useLocation()
  const isNew = search.includes('new=true')
  return (
    <Routes>
      {/* Main Routes */}
      <Route index element={<Navigate replace to={`${Path.AUTH}`} />} />
      <Route path={Path.LOGOUT} element={<LogoutPage />} />
      <Route path={Path.SUBSCRIPTION} element={<PublicSubscriptionPage />} />
      <Route path={Path.SPECIAL_SUBSCRIPTION} element={<SpecialSubscriptionPage />} />

      {/* Auth Routes */}
      <Route path={Path.AUTH} element={<AuthRoute />}>
        <Route path={Path.HOME} element={<HomePage />} />
        <Route
          path={Path.LOGIN}
          element={<Navigate replace to={`/${Path.AUTH}`} />}
        />
        <Route
          path={Path.REGISTER}
          element={<Navigate replace to={`/${Path.AUTH}`} />}
        />
        <Route path={Path.RESET_PASSWORD} element={<ForgotPasswordPage />} />
      </Route>
      <Route element={<ProtectedRoute redirectTo={`/${Path.AUTH}`} />}>
        <Route path={Path.WAIVER} element={<WaiverPage />} />
        <Route element={<Nav />}>
          <Route path={Path.ATHLETE}>
            <Route index element={<Navigate replace to={Path.DASHBOARD} />} />
            <Route element={<ProtectedCoachContent />}>
              <Route
                path={Path.USER_RELATIONS}
                element={<UserRelationsPage />}
              />
              <Route
                path={Path.COACH_RELATIONS}
                element={<CoachRelationsPage />}
              />
            </Route>
            <Route path={Path.VIDEOS} element={<VideoPage />} />
            <Route element={<ViewUserContentRoute />}>
              <Route path={`${Path.INTAKE_FORM}`} element={<FormPage />}>
                <Route
                  index
                  element={
                    <Navigate
                      to={`${FORM_CATEGORIES[0]}${isNew ? '?new=true' : ''}`}
                      replace
                    />
                  }
                />
                {FORM_CATEGORIES.map((category) => (
                  <Route
                    key={category}
                    path={category}
                    element={<Form category={category} />}
                  />
                ))}
                <Route
                  path={Path.INTAKE_FORM_COMPLETE}
                  element={<Complete />}
                />
              </Route>
              <Route path={Path.ACCOUNT} element={<AccountPage />} />
              <Route path={Path.ABOUT} element={<AboutPage />} />
              <Route path={Path.SETTINGS} element={<SettingsPage />} />
              <Route
                path={Path.NOTIFICATIONS}
                element={<NotificationsPage />}
              />
              <Route path={Path.DASHBOARD} element={<DashboardPage />} />
              <Route path={Path.TRAINING} element={<TrainingPage />} />
              <Route path={Path.NUTRITION} element={<NutritionPage />} />
              <Route path={Path.ONE_PERCENT} element={<OnePercentPage />} />
              <Route path={Path.REST} element={<RestPage />} />
              <Route path={Path.INTERNAL_GAME} element={<InternalGamePage />} />
              <Route path={Path.SUBSCRIPTION} element={<PlansPage />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  )
}
