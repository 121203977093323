import { Grid, Paper, Stack } from '@mui/material'
import {
  OverrideOptions,
  Role,
  TabPinVideo,
  VideoItem,
} from 'contexts/api/types'
import { Header } from 'pages/AthletePages/components'
import { getFileUrlType } from 'utils/functions'
import { useWindowSizes } from 'utils/hooks'
import { MediaType } from 'utils/types'
import { IconButtonWrapper, IconWrapper } from './styles'
import { Icon, UtilityIcon } from 'components/Icon'
import { FeedVideo } from 'components/Feed/FeedVideo'
import { useEffect, useState } from 'react'
import { SubTitle } from 'components/SubTitle'
import { useSelfUser } from 'contexts/api/hooks'
import { useNavigate } from 'react-router-dom'
import { useBraintreeSubscription } from 'contexts/braintree/hooks'
import { Path } from 'router/constants'

export const PinnedContent = ({
  headerName,
  videos,
  pinnedTab,
  handlePinVideo,
}: {
  headerName?: string
  videos?: VideoItem[]
  pinnedTab?: TabPinVideo
  handlePinVideo?: (video: VideoItem, pinnedTab?: TabPinVideo) => void
}) => {
  const { role, user } = useSelfUser()
  const windowSizes = useWindowSizes()
  const { braintreeSubscription } = useBraintreeSubscription()
  const navigate = useNavigate()
  const [filteredVideos, setFilteredVideos] = useState<VideoItem[] | null>()
  const isUser = role !== Role.ADMIN && role !== Role.COACH
  const manualOverride = user?.manualOverride

  const subscription =
    manualOverride === OverrideOptions.NO_MANUAL_OVERRIDE
      ? braintreeSubscription
      : manualOverride === OverrideOptions.PROVIDE_SUBSCRIPTION
      ? true
      : false

  const NoPinnedVideo = () => (
    <Stack width={windowSizes.isMobile ? 'auto' : 340} height={180}>
      <Paper
        style={{
          padding: 10,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        No Pinned Video
      </Paper>
    </Stack>
  )

  useEffect(() => {
    // Filter videos based on the selected tab
    const filteredVideos = videos
      ? videos.filter((video) => {
          switch (pinnedTab) {
            case TabPinVideo.PinnedInternal:
              return video.isPinnedInternal
            case TabPinVideo.PinnedNutrition:
              return video.isPinnedNutrition
            case TabPinVideo.PinnedOnePercent:
              return video.isPinnedOnePercent
            case TabPinVideo.PinnedRest:
              return video.isPinnedRest
            case TabPinVideo.PinnedTraining:
              return video.isPinnedTraining
            default:
              return false
          }
        })
      : null

    setFilteredVideos(filteredVideos)
  }, [videos, pinnedTab])

  return (
    <>
      {headerName && <Header style={{ fontSize: 22 }}>{headerName}</Header>}
      {filteredVideos?.length === 0 && !isUser && (
        <Stack spacing={2} direction={windowSizes.isDesktop ? 'row' : 'column'}>
          {NoPinnedVideo()}
          {NoPinnedVideo()}
        </Stack>
      )}
      {filteredVideos && filteredVideos.length > 0 && (
        <Grid container columnGap={2} columns={2}>
          {filteredVideos?.map((video, index) => {
            const type =
              video.contentType ?? getFileUrlType(video.contentUrl ?? '')
            const preview = video.contentUrl
              ? {
                  type,
                  url: video.contentUrl,
                  name: video.fileName,
                }
              : undefined
            return (
              <Grid item key={index} position='relative'>
                {preview?.url && preview.type === MediaType.Video && (
                  <>
                    {isUser ? (
                      <>
                        <Paper
                          style={{
                            maxWidth: 270,
                            borderRadius: 0,
                            alignItems: 'center',
                          }}>
                          {!subscription ? (
                            <Grid
                              item
                              onClick={() =>
                                navigate(
                                  `/${Path.ATHLETE}/${Path.SUBSCRIPTION}`,
                                )
                              }>
                              <IconWrapper>
                                <Icon
                                  style={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    margin: 10,
                                  }}
                                  path={UtilityIcon.LOCK}
                                />
                                <div style={{ height: 160 }}>
                                  <FeedVideo
                                    preview={preview}
                                    isLock={!!subscription}
                                  />
                                </div>
                                <div className='overlay'></div>
                              </IconWrapper>
                            </Grid>
                          ) : (
                            <div style={{ height: 160 }}>
                              <FeedVideo preview={preview} />
                            </div>
                          )}
                        </Paper>
                        <SubTitle style={{ fontSize: 18, width: 270 }}>
                          {video.fileName}
                        </SubTitle>
                      </>
                    ) : (
                      <>
                        <Paper style={{ height: 180, borderRadius: 0 }}>
                          <IconButtonWrapper
                            onClick={() => {
                              handlePinVideo
                                ? handlePinVideo(video, pinnedTab)
                                : null
                            }}
                            style={{
                              position: 'absolute',
                              top: 2,
                              right: 2,
                              zIndex: 1,
                              backgroundColor: 'white',
                            }}>
                            <Icon path={UtilityIcon.PUSHPINSIMPLE} />
                          </IconButtonWrapper>
                          <FeedVideo preview={preview} />
                        </Paper>
                        <SubTitle style={{ fontSize: 18, width: 340 }}>
                          {video.fileName}
                        </SubTitle>
                      </>
                    )}
                  </>
                )}
                {!isUser
                  ? filteredVideos?.length === 1
                    ? NoPinnedVideo()
                    : null
                  : null}
              </Grid>
            )
          })}
        </Grid>
      )}
    </>
  )
}
