import { styled } from '@mui/material/styles'
import { useWindowSizes } from 'utils/hooks'

export function Logo() {
  const { isDesktop } = useWindowSizes()
  return (
    <Wrapper>
      <Title>Run Free Training</Title>
      {isDesktop && (
        <Description>
          A one of a kind, holistic program for runners of all levels
          {/* , based on the principles of America{"'"}s Fastest Marathoner */}
        </Description>
      )}
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
`

const Title = styled('div')`
  font-size: 1.25rem;
  color: ${(p) => p.theme.palette.text.primary};
  font-weight: bold;
  margin-right: 1rem;
`

const Description = styled('div')`
  font-size: 0.875rem;
  color: ${(p) => p.theme.palette.text.secondary};
  font-weight: normal;
`
