import { Path } from 'router/constants'

type FormCategoryDict = Record<FormCategoryKey, FormCategoryContent>
type FormCategoryContent = {
  items: FormKey[]
  title: string
  subtitle?: string
}

export type IntakeFormData = {
  [key in FormKey]: string
}

export const REQUIRED_FIELDS: FormKey[] = ['train_gender_pref']

export const FORM_CATEGORY_DATA: FormCategoryDict = {
  [Path.ACCOUNT]: {
    items: [
      'address',
      'city',
      'state',
      'age',
      'gender',
      'height',
      'weight',
      'birthplace',
      'degrees',
    ],
    title: 'Onboarding',
  },
  [Path.TRAINING]: {
    title: 'Training - Part 1',
    items: [
      'train_years_running',
      'train_how_you_heard',
      'train_current_typical_volume',
      'train_good_bad_job',
      'train_working',
      'train_where_at',
      'train_try_type',
      'train_runner_natural',
      'train_runner_intensity',
      'train_runner_type',
      'train_runner_high_weekly_ever',
      'train_runner_high_weekly_6',
      'train_runner_low_6',
      'train_coach_1',
      'train_coach_details',
      'train_mon',
      'train_tues',
      'train_wed',
      'train_thur',
      'train_fri',
      'train_sat',
      'train_sun',
      'train_alone',
      'train_energy',
      'train_day_preferences',
      'train_fav_workout',
      'train_lfav_workout',
      'train_help',
      'train_into_running',
    ],
  },
  [Path.TRAINING_SPLIT2]: {
    title: 'Training - Part 2',
    items: [
      'train_400_lifetime_pr',
      'train_400_lastyear_pr',
      'train_400_nextyear_goal',
      'train_400_lifetime_goal',
      'train_800_lifetime_pr',
      'train_800_lastyear_pr',
      'train_800_nextyear_goal',
      'train_800_lifetime_goal',
      'train_mile_lifetime_pr',
      'train_mile_lastyear_pr',
      'train_mile_nextyear_goal',
      'train_mile_lifetime_goal',
      'train_5000_lifetime_pr',
      'train_5000_lastyear_pr',
      'train_5000_nextyear_goal',
      'train_5000_lifetime_goal',
      'train_10000_lifetime_pr',
      'train_10000_lastyear_pr',
      'train_10000_nextyear_goal',
      'train_10000_lifetime_goal',
      'train_half_lifetime_pr',
      'train_half_lastyear_pr',
      'train_half_nextyear_goal',
      'train_half_lifetime_goal',
      'train_marathon_lifetime_pr',
      'train_marathon_lastyear_pr',
      'train_marathon_nextyear_goal',
      'train_marathon_lifetime_goal',
      'train_gender_pref',
      'train_referral_source',
      'train_additional_details',
    ],
  },
  [Path.REST]: {
    items: [
      'sleep_hours',
      'sleep_hours_goals',
      'sleep_nap',
      'sleep_hard',
      'sleep_screen',
      'sleep_screen_goal',
      'sleep_pattern_details',
      'sleep_additional_details',
    ],
    title: 'Rest',
  },
  [Path.NUTRITION]: {
    items: [
      'nut_caffeine',
      'nut_caffeine_sup',
      'nut_diet',
      'nut_goals',
      'nut_eat',
      'nut_crave',
      'nut_snack',
      'nut_macro',
      'nut_macro_goal',
      'nut_goal_weight',
      'nut_details',
    ],
    title: 'Nutrition',
  },
  [Path.ONE_PERCENT]: {
    items: [
      'op_injuries',
      'op_medical',
      'op_weight_monitor',
      'op_weight_fluctuate',
      'op_weight_race_goal',
      'op_weight_help',
      'op_weights_years',
      'op_weights_routine',
      'op_weights_imp',
      'op_weights_max',
      'op_weights_weak',
      'op_weights_crave',
      'op_weights_stop',
    ],
    title: 'The 1% - Part 1',
    subtitle:
      'Intensive guides to strength training, recovery modalities, dynamic flexibility, visualization, injury recovery & prevention, cross training, and more.',
  },
  [Path.ONE_PERCENT_SPLIT2]: {
    items: [
      'op_weights_prefer',
      'op_weights_group',
      'op_weights_access',
      'op_injury_cur',
      'op_injury_weak',
      'op_injury_big',
      'op_injury_soft',
      'op_injury_shoe',
      'op_injury_exer',
      'op_injury_train',
      'op_injury_help',
      'op_details',
    ],
    title: 'The 1% - Part 2',
    subtitle:
      'Intensive guides to strength training, recovery modalities, dynamic flexibility, visualization, injury recovery & prevention, cross training, and more.',
  },
  [Path.INTERNAL_GAME]: {
    items: [
      'mental_social_media',
      'mental_next_year_goals',
      'mental_ultimate_goal',
      'mental_get_from_running_goal',
      'mental_goal_details',
      'mental_why_run',
      'mental_fav_race',
      'mental_bucket',
      'mental_str_weak',
      'mental_race_approach',
      'mental_history_details',
    ],
    title: 'Internal Game - Part 1',
    subtitle:
      'What you believe about yourself will come out in your training. Cultivating mental and emotional strength by addressing what’s going on in your mind and heart to empower you as an athlete',
  },
  [Path.INTERNAL_GAME_SPLIT2]: {
    items: [
      'mental_injury_dur',
      'mental_injury_risk',
      'mental_tough_num',
      'mental_moment',
      'mental_vis',
      'mental_growth',
      'mental_cues',
      'mental_help',
      'mental_goals',
      'mental_believe',
      'mental_details',
    ],
    title: 'Internal Game - Part 2',
    subtitle:
      'What you believe about yourself will come out in your training. Cultivating mental and emotional strength by addressing what’s going on in your mind and heart to empower you as an athlete',
  },
}

type FormItems = Record<FormKey, FormItem>

export type FormItemTextType = {
  title: string
  subtitle?: string
  autoComplete?:
    | 'name'
    | 'email'
    | 'tel-national'
    | 'street-address'
    | 'age'
    | 'address-level1'
    | 'address-level2'
  type: 'text' | 'email' | 'tel' | 'number' | 'textarea'
  placeholder: string
}

export type FormItemRadioType = {
  title: string
  subtitle?: string
  type: 'radio' | 'radio_input'
  placeholder?: string
  items: string[]
}

export type FormItemScaleType = {
  title: string
  subtitle?: string
  type: 'scale'
  placeholder?: string
  low_text: string
  high_text: string
}

export function isFormItemRadioType(
  formItem: FormItem,
): formItem is FormItemRadioType {
  return formItem.type === 'radio' || formItem.type === 'radio_input'
}
export function isFormItemScaleType(
  formItem: FormItem,
): formItem is FormItemScaleType {
  return formItem.type === 'scale'
}
export function isFormItemTextType(
  formItem: FormItem,
): formItem is FormItemTextType {
  return (
    formItem.type === 'text' ||
    formItem.type === 'textarea' ||
    formItem.type === 'email' ||
    formItem.type === 'tel' ||
    formItem.type === 'number'
  )
}

export type FormItem = FormItemTextType | FormItemRadioType | FormItemScaleType

export const FORM_DEFAULT_CATEGORY: FormCategoryKey = Path.ACCOUNT
export const FORM_CATEGORIES = [
  Path.ACCOUNT,
  Path.TRAINING,
  Path.TRAINING_SPLIT2,
  Path.REST,
  Path.NUTRITION,
  Path.ONE_PERCENT,
  Path.ONE_PERCENT_SPLIT2,
  Path.INTERNAL_GAME,
  Path.INTERNAL_GAME_SPLIT2,
] as const

export type FormCategoryKey = typeof FORM_CATEGORIES[number]

export type AccountFormKey = 'email' | 'password' | 'confirm_password'

export type FormKey =
  | 'address'
  | 'city'
  | 'state'
  | 'age'
  | 'gender'
  | 'height'
  | 'weight'
  | 'birthplace'
  | 'degrees'
  | 'train_years_running'
  | 'train_how_you_heard'
  | 'train_current_typical_volume'
  | 'train_good_bad_job'
  | 'train_working'
  | 'train_where_at'
  | 'train_try_type'
  | 'train_runner_natural'
  | 'train_runner_intensity'
  | 'train_runner_type'
  | 'train_runner_high_weekly_ever'
  | 'train_runner_high_weekly_6'
  | 'train_runner_low_6'
  | 'train_coach_1'
  | 'train_coach_details'
  | 'train_mon'
  | 'train_tues'
  | 'train_wed'
  | 'train_thur'
  | 'train_fri'
  | 'train_sat'
  | 'train_sun'
  | 'train_alone'
  | 'train_energy'
  | 'train_day_preferences'
  | 'train_fav_workout'
  | 'train_lfav_workout'
  | 'train_help'
  | 'train_into_running'
  | 'train_anything_else'
  | 'train_400_lifetime_pr'
  | 'train_400_lastyear_pr'
  | 'train_400_nextyear_goal'
  | 'train_400_lifetime_goal'
  | 'train_800_lifetime_pr'
  | 'train_800_lastyear_pr'
  | 'train_800_nextyear_goal'
  | 'train_800_lifetime_goal'
  | 'train_mile_lifetime_pr'
  | 'train_mile_lastyear_pr'
  | 'train_mile_nextyear_goal'
  | 'train_mile_lifetime_goal'
  | 'train_5000_lifetime_pr'
  | 'train_5000_lastyear_pr'
  | 'train_5000_nextyear_goal'
  | 'train_5000_lifetime_goal'
  | 'train_10000_lifetime_pr'
  | 'train_10000_lastyear_pr'
  | 'train_10000_nextyear_goal'
  | 'train_10000_lifetime_goal'
  | 'train_half_lifetime_pr'
  | 'train_half_lastyear_pr'
  | 'train_half_nextyear_goal'
  | 'train_half_lifetime_goal'
  | 'train_marathon_lifetime_pr'
  | 'train_marathon_lastyear_pr'
  | 'train_marathon_nextyear_goal'
  | 'train_marathon_lifetime_goal'
  | 'train_gender_pref'
  | 'train_referral_source'
  | 'train_additional_details'
  | 'sleep_hours'
  | 'sleep_hours_goals'
  | 'sleep_nap'
  | 'sleep_hard'
  | 'sleep_screen'
  | 'sleep_screen_goal'
  | 'sleep_pattern_details'
  | 'sleep_additional_details'
  | 'nut_caffeine'
  | 'nut_caffeine_sup'
  | 'nut_diet'
  | 'nut_goals'
  | 'nut_eat'
  | 'nut_crave'
  | 'nut_snack'
  | 'nut_macro'
  | 'nut_macro_goal'
  | 'nut_goal_weight'
  | 'nut_details'
  | 'op_injuries'
  | 'op_medical'
  | 'op_weight_monitor'
  | 'op_weight_fluctuate'
  | 'op_weight_race_goal'
  | 'op_weight_help'
  | 'op_weights_years'
  | 'op_weights_routine'
  | 'op_weights_imp'
  | 'op_weights_max'
  | 'op_weights_weak'
  | 'op_weights_crave'
  | 'op_weights_stop'
  | 'op_weights_prefer'
  | 'op_weights_group'
  | 'op_weights_access'
  | 'op_injury_cur'
  | 'op_injury_weak'
  | 'op_injury_big'
  | 'op_injury_soft'
  | 'op_injury_shoe'
  | 'op_injury_exer'
  | 'op_injury_train'
  | 'op_injury_help'
  | 'op_details'
  | 'mental_social_media'
  | 'mental_next_year_goals'
  | 'mental_ultimate_goal'
  | 'mental_get_from_running_goal'
  | 'mental_goal_details'
  | 'mental_why_run'
  | 'mental_fav_race'
  | 'mental_bucket'
  | 'mental_str_weak'
  | 'mental_race_approach'
  | 'mental_history_details'
  | 'mental_injury_dur'
  | 'mental_injury_risk'
  | 'mental_tough_num'
  | 'mental_moment'
  | 'mental_vis'
  | 'mental_growth'
  | 'mental_cues'
  | 'mental_help'
  | 'mental_goals'
  | 'mental_believe'
  | 'mental_details'
  | 'done'

export const FORM_ITEMS: FormItems = {
  address: {
    title: 'Street Address',
    autoComplete: 'street-address',
    type: 'textarea',
    placeholder: 'Please enter your address',
  },
  city: {
    title: 'City',
    autoComplete: 'address-level2',
    type: 'text',
    placeholder: 'Please enter your city',
  },
  state: {
    title: 'State',
    autoComplete: 'address-level1',
    type: 'text',
    placeholder: 'Please enter your state',
  },
  age: {
    title: 'Age',
    autoComplete: 'age',
    type: 'number',
    placeholder: 'Please enter your age',
  },
  height: {
    title: 'Height',
    type: 'text',
    placeholder: 'Please enter your height',
  },
  weight: {
    title: 'Weight',
    type: 'number',
    placeholder: 'Please enter your weight',
  },
  birthplace: {
    title: 'Birthplace',
    type: 'text',
    placeholder: 'Please enter your birthplace',
  },
  degrees: {
    title: 'Degrees',
    type: 'text',
    placeholder: 'Please list your degrees (i.e., high school, BS, MS, PhD)',
  },
  op_injuries: {
    title: 'Injuries',
    type: 'textarea',
    placeholder: 'Please list any injuries from the last 2 years',
  },
  op_medical: {
    title: 'Medical Conditions/Medications',
    type: 'textarea',
    placeholder: 'Please list any medical conditions or medications',
  },
  train_years_running: {
    title: 'Running Experience',
    type: 'number',
    placeholder: 'How many years have you been running?',
  },
  mental_social_media: {
    title: 'Social Media (optional)',
    type: 'textarea',
    placeholder: 'Please list your social media handles',
  },
  train_referral_source: {
    title: 'Referral',
    subtitle:
      'If you were referred to Run Free from an individual, whom can we thank for your referral?',
    type: 'text',
    placeholder: 'Please list who referred you',
  },
  train_current_typical_volume: {
    title: 'Typical Volume (weekly miles) from last 12 months',
    subtitle:
      'E.g. Miles per week last calendar year. For example, supposed you averaged 50 miles per week last year and ran consistently at that level for 45 weeks of the year, then you would enter 50 here.',
    type: 'number',
    placeholder: 'Please enter your typical running volume',
  },
  train_working: {
    title: 'Effective Training Methods',
    subtitle:
      "In terms of training, what do you feel worked well for you last year and what didn't",
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_good_bad_job: {
    title: 'Self Evaluation',
    subtitle:
      'What did you do well last year and what would you like to improve on?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_where_at: {
    title: 'Additional comments about your current training',
    subtitle:
      'Is there anything else that would be helpful for me to know about where you are at?',
    type: 'textarea',
    placeholder: 'Please enter your answer.',
  },
  mental_next_year_goals: {
    title: 'Goals for next year',
    subtitle:
      'What are your goals for next year, including which races you would like to run?',
    type: 'textarea',
    placeholder: 'Please enter any additional details',
  },
  mental_ultimate_goal: {
    title: 'Ultimate Goal',
    subtitle: 'What is your Ultimate Running Goal?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  mental_get_from_running_goal: {
    title: 'Mental, Emotional, & Spiritual Benefits',
    subtitle: 'What do you hope to receive or get out of your running?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_try_type: {
    title: 'Training Preferences',
    subtitle:
      'What type of training are you curious to try? Higher volume?  More power focused running? Another way of saying it, “what kind of training do you feel like you need the most to get to the next level?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  mental_goal_details: {
    title: 'Additional Goal Details',
    subtitle:
      'Is there anything else that would be helpful for me to know about your goals? Please list any additional details or clarifications for this section.',
    type: 'textarea',
    placeholder: 'Please enter any additional details',
  },
  mental_why_run: {
    title: 'Purpose for Running',
    subtitle: 'Why do you run? (What do you love about running?)',
    type: 'textarea',
    placeholder: 'Please enter why you like to run',
  },
  mental_fav_race: {
    title: 'Racing History',
    subtitle:
      'What are your favorite races you have done in your career and why?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  mental_bucket: {
    title: 'Bucket List',
    subtitle: 'What are your bucket list races?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  mental_str_weak: {
    title: 'Mental Strengths & Weaknesses',
    subtitle: 'What are your strengths as a racer? What are your weaknesses?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  mental_race_approach: {
    type: 'radio',
    title: 'Mental Prep for Races',
    subtitle: 'How do you like to approach a race?',
    items: ['firm_plan_in_mind', 'general_plan_in_mind', 'no_plan_in_mind'],
  },
  train_runner_natural: {
    title: 'Identifying Training Preferences',
    subtitle:
      'From the following options, what comes more natural to you? Sprinting, Intervals, or Long Runs',
    type: 'textarea',
    placeholder: 'Please describe what feels more natural to you',
  },
  train_runner_intensity: {
    title: 'Type of training you feel you respond well to:',
    type: 'radio_input',
    items: [
      'high_mileage',
      'low_mileage',
      'high_intensity',
      'low_intensity',
      'lots_of_hills',
      'mixed_paced_training',
    ],
  },
  train_runner_type: {
    title: 'Identifying Challenges',
    subtitle: 'What type of training seems to not work for you?',
    type: 'text',
    placeholder: 'Please enter what type of training',
  },
  train_runner_high_weekly_ever: {
    title: 'Weekly High',
    subtitle: 'What is your highest weekly mileage ever?',
    type: 'number',
    placeholder: 'Please enter your highest weekly mileage (ever)',
  },
  train_runner_high_weekly_6: {
    title: 'Weekly High',
    subtitle: 'What is your highest weekly mileage in the last 6 months?',
    type: 'number',
    placeholder: 'Please enter your highest weekly mileage (6 months)',
  },
  train_runner_low_6: {
    title: 'Weekly Low',
    subtitle: 'What is your lowest weekly mileage in the last 6 months?',
    type: 'number',
    placeholder: 'Please enter your lowest weekly mileage (6 months)',
  },
  train_coach_1: {
    title: 'Coaching History',
    subtitle: 'Have you been coached before? What did you like/dislike?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_coach_details: {
    title: 'Additional comments about coaching history',
    subtitle:
      'Please list any additional details or clarifications about your previous experiences of receiving coaching.',
    type: 'textarea',
    placeholder: 'Please enter any additional details',
  },
  nut_caffeine: {
    title: 'Caffeine Daily Use',
    subtitle:
      'When, if ever, do you have caffeine? Do you take on all days or just workout days?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  nut_caffeine_sup: {
    title: 'Supplements',
    subtitle: 'What other supplements are you taking?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  nut_diet: {
    title: 'Diet Details',
    subtitle: 'Do you subscribe to a particular diet? (Vegetarian, Paleo, etc)',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  nut_goals: {
    title: 'Nutrition Goals',
    subtitle: 'What are your nutrition goals?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  nut_eat: {
    title: 'Eating before running',
    subtitle: 'Do you eat before running? Please describe.',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  nut_crave: {
    title: 'Foods',
    subtitle: 'Please list foods you avoid and foods you crave',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  nut_snack: {
    title: 'Snacks',
    subtitle:
      'Please enter your post easy run and post hard run typical snacks',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  nut_macro: {
    title: 'Macro Breakdown',
    subtitle: "What are your current macro %'s (Carb/Fat/Protein)?",
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  nut_macro_goal: {
    title: 'Macro Goal',
    subtitle: "What is your goal macro %'s (Carb/Fat/Protein)?",
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  nut_goal_weight: {
    title: 'Goal Weight',
    subtitle: 'Do you have a goal race weight?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  nut_details: {
    title: 'Additional Comments',
    subtitle:
      'Is there anything else that would be helpful for me to know about your nutrition?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  sleep_hours: {
    title: 'Average Sleep Duration',
    subtitle: 'How many hours of sleep do you get per night?',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  sleep_hours_goals: {
    title: 'Sleep Hours Goal?',
    subtitle: 'What is your goal hours of sleep?',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  sleep_nap: {
    title: 'Do you Nap?',
    type: 'radio_input',
    subtitle: '',
    items: ['yes', 'no'],
  },
  sleep_hard: {
    title: 'Insomnia',
    subtitle:
      'When do you typically have a hard time sleeping and how do you deal with these times?',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  sleep_screen: {
    title: 'Screen Time',
    subtitle: 'How far in advance of sleep do you avoid screens?',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  sleep_screen_goal: {
    title: 'Screen Time Goal',
    subtitle: 'What is your goal regarding screentime?',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  sleep_pattern_details: {
    title: 'Sleep Help',
    subtitle:
      'Is there anything else that would be helpful for me to know about your sleep patterns or habits?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  sleep_additional_details: {
    title: 'Additional Details?',
    subtitle:
      'Please list any additional details or clarifications for this section.',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_400_lifetime_pr: {
    title: '400m Lifetime PR',
    subtitle: 'Enter your lifetime PR for the 400m',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_400_lastyear_pr: {
    title: '400m Last Year PR',
    subtitle: 'Enter your last year PR for the 400m',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_400_nextyear_goal: {
    title: '400m Next Year Goal',
    subtitle: 'Enter your next year goal for the 400m',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_400_lifetime_goal: {
    title: '400m Lifetime Goal',
    subtitle: 'Enter your lifetime goal for the 400m',
    type: 'text',
    placeholder: 'Please enter your answer',
  },

  train_800_lifetime_pr: {
    title: '800m Lifetime PR',
    subtitle: 'Enter your lifetime PR for the 800m',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_800_lastyear_pr: {
    title: '800m Last Year PR',
    subtitle: 'Enter your last year PR for the 800m',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_800_nextyear_goal: {
    title: '800m Next Year Goal',
    subtitle: 'Enter your next year goal for the 800m',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_800_lifetime_goal: {
    title: '800m Lifetime Goal',
    subtitle: 'Enter your lifetime goal for the 800m',
    type: 'text',
    placeholder: 'Please enter your answer',
  },

  train_mile_lifetime_pr: {
    title: 'Mile Lifetime PR',
    subtitle: 'Enter your lifetime PR for the mile',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_mile_lastyear_pr: {
    title: 'Mile Last Year PR',
    subtitle: 'Enter your last year PR for the mile',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_mile_nextyear_goal: {
    title: 'Mile Next Year Goal',
    subtitle: 'Enter your next year goal for the mile',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_mile_lifetime_goal: {
    title: 'Mile Lifetime Goal',
    subtitle: 'Enter your lifetime goal for the mile',
    type: 'text',
    placeholder: 'Please enter your answer',
  },

  train_5000_lifetime_pr: {
    title: '5000m Lifetime PR',
    subtitle: 'Enter your lifetime PR for the 5000m',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_5000_lastyear_pr: {
    title: '5000m Last Year PR',
    subtitle: 'Enter your last year PR for the 5000m',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_5000_nextyear_goal: {
    title: '5000m Next Year Goal',
    subtitle: 'Enter your next year goal for the 5000m',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_5000_lifetime_goal: {
    title: '5000m Lifetime Goal',
    subtitle: 'Enter your lifetime goal for the 5000m',
    type: 'text',
    placeholder: 'Please enter your answer',
  },

  train_10000_lifetime_pr: {
    title: '10000m Lifetime PR',
    subtitle: 'Enter your lifetime PR for the 10000m',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_10000_lastyear_pr: {
    title: '10000m Last Year PR',
    subtitle: 'Enter your last year PR for the 10000m',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_10000_nextyear_goal: {
    title: '10000m Next Year Goal',
    subtitle: 'Enter your next year goal for the 10000m',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_10000_lifetime_goal: {
    title: '10000m Lifetime Goal',
    subtitle: 'Enter your lifetime goal for the 10000m',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_half_lifetime_pr: {
    title: 'Half Marathon Lifetime PR',
    subtitle: 'Enter your lifetime PR for the half marathon',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_half_lastyear_pr: {
    title: 'Half Marathon Last Year PR',
    subtitle: 'Enter your last year PR for the half marathon',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_half_nextyear_goal: {
    title: 'Half Marathon Next Year Goal',
    subtitle: 'Enter your next year goal for the half marathon',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_half_lifetime_goal: {
    title: 'Half Marathon Lifetime Goal',
    subtitle: 'Enter your lifetime goal for the half marathon',
    type: 'text',
    placeholder: 'Please enter your answer',
  },

  train_marathon_lifetime_pr: {
    title: 'Marathon Lifetime PR',
    subtitle: 'Enter your lifetime PR for the marathon',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_marathon_lastyear_pr: {
    title: 'Marathon Last Year PR',
    subtitle: 'Enter your last year PR for the marathon',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_marathon_nextyear_goal: {
    title: 'Marathon Next Year Goal',
    subtitle: 'Enter your next year goal for the marathon',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_marathon_lifetime_goal: {
    title: 'Marathon Lifetime Goal',
    subtitle: 'Enter your lifetime goal for the marathon',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  mental_history_details: {
    title: 'Racing History Details',
    subtitle:
      'Is there anything else that would be helpful for me to know about your racing history and style?',
    type: 'textarea',
    placeholder: 'Please enter any details',
  },
  op_weight_monitor: {
    type: 'radio',
    title: 'Weight Monitoring',
    subtitle: 'Do you monitor your weight?',
    items: ['yes', 'no', 'sometimes'],
  },
  op_weight_fluctuate: {
    title: ' Weight Fluctuation',
    subtitle:
      'If so, does your weight fluctuate over the course of the year and by approximately how much?',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  op_weight_race_goal: {
    title: 'Racing Weight',
    subtitle: 'What weight do you typically race the best at (if known)?',
    type: 'number',
    placeholder: 'Please enter your answer',
  },
  op_weight_help: {
    title: 'Additional Comments',
    subtitle:
      'Is there anything else that would be helpful for me to know about issues concerning weight?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_mon: {
    title: 'If you are currently training, what does a typical week look like for you on each day? Distance/Duration | Workout (if any) | Notes (Monday)',
    subtitle:
      'E.g 9 miles | 2 mile w-u, 5 x 1 mile, 2 mile c-d | Intervals run at 10K pace',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_tues: {
    title: 'Distance/Duration | Workout (if any) | Notes (Tuesday)',
    subtitle: 'E.g. 5 miles | None | Recovery run',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_wed: {
    title: 'Distance/Duration | Workout (if any) | Notes (Wednesday)',
    subtitle: 'E.g. 0 miles | None | Off Day',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_thur: {
    title: 'Distance/Duration | Workout (if any) | Notes (Thursday)',
    subtitle:
      'E.g. 8 miles | None | 7 miles average pace plus 1 mile of strides (100m on/off)',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_fri: {
    title: 'Distance/Duration | Workout (if any) | Notes (Friday)',
    subtitle: 'E.g. 5 miles | None | Recovery run',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_sat: {
    title: 'Distance/Duration | Workout (if any) | Notes (Saturday)',
    subtitle: 'E.g. 13 miles | None | Long run, last 3 miles at 10K pace',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_sun: {
    title: 'Distance/Duration | Workout (if any) | Notes (Sunday)',
    subtitle: 'E.g. 5 miles | None | Recovery run',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_alone: {
    title: 'Alone / Group',
    subtitle: 'Do you prefer working out alone or with a partner or group?',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_energy: {
    title: 'Energy',
    subtitle:
      'What time of day do you prefer working out and is your energy the best?.',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  train_day_preferences: {
    title: 'Workout Preferences',
    subtitle:
      'What days of the week are the best days for you to do hard workouts on? (i.e. what days do you have more time and energy).  Any days you cannot train? What is your preferred day off?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_fav_workout: {
    title: 'Favorite Workout?',
    subtitle: 'What is your favorite workout?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_lfav_workout: {
    title: 'Least Favorite Workout?',
    subtitle: 'What is your least favorite workout?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_help: {
    title: 'Anything Else?',
    subtitle:
      'Is there anything else that would be helpful for me to know about your training?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  op_weights_years: {
    title: 'Lifting History',
    subtitle: 'For how many years have you been lifting weights?',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  op_weights_routine: {
    title: 'Weight Routine',
    subtitle: 'What is your typical weight routine for the week?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  op_weights_imp: {
    title: 'Effective Weight Training',
    subtitle: 'What would you consider to be the most effective lifts for you?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  op_weights_max: {
    title: 'Lift Maxes',
    subtitle:
      'What are your maxes for your most effective lifts? If you have never maxed out, what is the most weight you have done in a workout (for how many sets and reps?)',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  op_weights_weak: {
    title: 'Weakest Part of your Body',
    subtitle:
      "What areas do you feel like are the weakest on your body and do you want to make these stronger? For example, if your arms are weak, we can make those stronger but that won't necessarily make you a better runner.",
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  op_weights_crave: {
    title: 'Lift Craving',
    subtitle: 'What lifts are you craving?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  op_weights_stop: {
    title: 'Pausing Weight Training for Races',
    subtitle: 'When do you typically stop lifting before your biggest race?',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  op_weights_prefer: {
    title: 'Day Preference',
    subtitle: 'What day of the week do you typically prefer to lift?',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  op_weights_group: {
    title: 'Group Weight Training',
    subtitle: 'Do you like lifting on your own or in a group?',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  op_weights_access: {
    title: 'Gym Access',
    subtitle:
      'Do you have access to a gym? Does your gym have a Hex or Trap bar?',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  op_injury_cur: {
    title: 'Current Injuries',
    subtitle: 'Do you have any current injuries?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  op_injury_weak: {
    title: 'Areas of Weakness',
    subtitle:
      'What are some areas of weakness you have that can manifest into injuries? Where are you prone to get hurt? What have you done in the past to keep these prone areas from developing into full blown injuries?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  op_injury_big: {
    title: 'Biggest Injuries',
    subtitle:
      "What are the biggest injuries you've had over the course of your running history?",
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  mental_injury_dur: {
    title: 'Durability',
    subtitle: 'How durable would you describe yourself as being?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  op_injury_soft: {
    title: 'Running Surfaces',
    subtitle:
      'Do you run on soft surfaces for easy days? How often do you change out shoes?',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  op_injury_shoe: {
    title: 'Shoes',
    subtitle: 'What type of shoes do you train in for easy and hard days?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  op_injury_exer: {
    title: 'Exercises that prevent injury',
    subtitle:
      'Are there any exercises you feel like you need to do to keep from getting hurt?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  op_injury_train: {
    title: 'Injury prevention',
    subtitle:
      'What type of training typically gives you the greatest chance of getting hurt?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  mental_injury_risk: {
    type: 'scale',
    title: 'How much risk do you want to take in your training?',
    subtitle:
      'High risk (training very hard) could yield high reward (running super fast) but the risk of injury increases as your training increases. On a scale of 1-10 (1 being almost no risk of injury but you won’t be training very hard or doing a lot of cross training and 10 being training as hard as possible with a pretty high risk of injury but if you survive you would crush your upcoming race) where would you put how much risk you want to take?',
    low_text: 'Low Risk / Easy Training',
    high_text: 'High Risk / High Training',
  },
  op_injury_help: {
    title: 'Additional Comments about Injuries',
    subtitle:
      'Is there anything else that would be helpful for me to know about your history with injuries?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  mental_tough_num: {
    title: 'Mentally Tough',
    subtitle:
      'On a scale of 1-10 how would you rate your level of mental toughness?',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  mental_moment: {
    title: 'Mental Moments',
    subtitle:
      'When are your most trying mental moments and how do you get through them?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  mental_vis: {
    title: 'Mental Visualization',
    subtitle: 'Do you visualize?',
    type: 'text',
    placeholder: 'Please enter your answer',
  },
  mental_growth: {
    title: 'Mental Growth',
    subtitle:
      'In what ways do you want to grow mentally? Do you have any thoughts on how you can get there?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  mental_cues: {
    title: 'Mental Cues',
    subtitle:
      'What are mental cues, mantras, etc that you use in training and racing?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  mental_help: {
    title: 'Additional comments about your mental strategy',
    subtitle:
      'Is there anything else that would be helpful for me to know about your mental side of running?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_into_running: {
    title: 'Running History Question',
    subtitle: 'How did you get into running?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  mental_believe: {
    title: 'Self Perception',
    subtitle: 'What do you believe about yourself?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  train_anything_else: {
    title: 'What else?',
    subtitle: 'Anything else we should talk about that I missed?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  gender: {
    title: 'Gender',
    type: 'radio_input',
    items: ['male', 'female', 'prefer_not_to_say'],
    placeholder: 'Please enter your gender',
  },
  train_how_you_heard: {
    title: 'How did you hear about Run Free Training?',
    type: 'radio_input',
    items: [
      'friend',
      'social_media',
      'email',
      'website',
      'coach',
      'run_free_coach',
    ],
  },
  train_gender_pref: {
    type: 'radio',
    title: "Do you have a preference with your coach's gender?",
    items: ['male_coach', 'female_coach', 'whatever_gender_is_good_with_me'],
  },
  train_additional_details: {
    title: 'Additional Details',
    subtitle:
      'Please list any additional details or clarifications about your goals.',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  op_details: {
    title:
      'Additional Comments about mobility, cross training, injury prevention, etc.',
    subtitle:
      'Please list any additional details or clarifications for this section.',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  mental_details: {
    title: 'Additional Comments',
    subtitle:
      'Please list any additional details or clarifications for this section.',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  mental_goals: {
    title: 'Goals',
    subtitle: 'What are your goals for the mental side of your training?',
    type: 'textarea',
    placeholder: 'Please enter your answer',
  },
  done: {
    title: 'Done',
    type: 'text',
    placeholder: 'Are you done?',
  },
}
