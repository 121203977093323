import { styled } from '@mui/material/styles'
import { ThemeConstants } from 'contexts/theme/constants'
import { Logo } from './Logo'
import { NavElements } from './NavElements'

export function NavBar() {
  return (
    <Wrapper>
      <InnerWrapper>
        <Logo />
        <NavElements />
      </InnerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled('header')`
  z-index: ${(p) => p.theme.zIndex.drawer + 1};
  box-shadow: 0 1px 0 0 ${(p) => p.theme.palette.border.light};
  height: ${ThemeConstants.NAV_BAR_HEIGHT}px;
  display: flex;
  // position: fixed;
  // top: 0;
  width: 100%;
  background-color: ${(p) => p.theme.palette.common.white};
  padding: 0 1.5rem;
  box-sizing: border-box;

  @supports (backdrop-filter: blur(10px)) {
    backdrop-filter: blur(10px);
    background-color: ${(p) =>
      p.theme.palette.common.white.replace(
        /rgba?(\(\s*\d+\s*,\s*\d+\s*,\s*\d+)(?:\s*,.+?)?\)/,
        'rgba$1,0.75)',
      )};
  }
`

const InnerWrapper = styled('div')`
  max-width: 960px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`
