import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import MuiDialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Row } from 'components'
import {
  useChangeRole,
  useCreateRelationships,
  useDeleteUser,
  useDialogUser,
  useRelationshipsById,
  useUsersByRole,
} from 'contexts/api/hooks'
import {
  GetRelationshipsById,
  GetUserByIdResponse,
  isRole,
  Role,
  UserByRole,
} from 'contexts/api/types'
import { useShowDialog } from 'contexts/dialog/Controller'
import { useCallback, useEffect, useState } from 'react'
import { Relationships } from 'router/constants'
import { useImmer } from 'use-immer'
import { titleCase } from 'utils/functions'
import { EditDialogState } from './types'
import { useDialog } from './UiProvider'

type FormData = {
  id: string
  name: string
  phoneNumber: string
  email: string
  role: Role
  relationships: { to: number; category: Relationships }[]
}

const INITIAL_EDIT_DATA: FormData = {
  id: '',
  name: '',
  phoneNumber: '',
  email: '',
  role: Role.COACH,
  relationships: [],
}

export const EditDialog = ({ open, id }: EditDialogState) => {
  const { user } = useDialogUser(id)
  const { mutateAsync: changeRole } = useChangeRole()
  const { mutateAsync: createRelationships } = useCreateRelationships()
  const { mutateAsync: deleteUser } = useDeleteUser()
  const { relationships } = useRelationshipsById(id)
  const showDialog = useShowDialog()
  const [mode, setMode] = useState<'edit' | 'add'>('edit')
  const [editData, produceEditData] = useImmer(INITIAL_EDIT_DATA)

  const { closeDialog } = useDialog()

  useEffect(() => {
    // console.log('relationships', relationships)
    if (user) {
      produceEditData(formFieldsFromQueries(user, relationships))
      setMode('edit')
    } else {
      produceEditData(INITIAL_EDIT_DATA)
      setMode('add')
    }
  }, [produceEditData, relationships, user])

  const handleEdit = useCallback(
    <K extends keyof FormData>(key: K) =>
      (val: FormData[K]) =>
        produceEditData((draft) => {
          draft[key] = val
        }),
    [produceEditData],
  )

  const handleClose = useCallback(() => {
    closeDialog()
  }, [closeDialog])

  const handleSubmit = useCallback(() => {
    if (mode === 'add') {
      // mutateUsers(updateFieldsFromFormFields(editData))
      window.alert('implement add')
    } else if (mode === 'edit') {
      if (!user) throw new Error('Attempt to edit with no user!')
      //Add relationships
      // delete relationships
      // change role
      if (user.role !== editData.role) {
        changeRole({ id: user.id, user: { role: editData.role } })
      }

      const newRelationships = editData.relationships.filter(
        (r) =>
          !user.relationships.find(
            (f) => f.id === r.to && f.category === r.category,
          ),
      )
      if (newRelationships.length) {
        createRelationships(
          newRelationships.map((nr) => ({
            from: user.id,
            to: nr.to,
            category: nr.category,
          })),
        )
      }

      const removeRelationships = user.relationships.filter(
        (f) =>
          !editData.relationships.find(
            (r) => f.id === r.to && f.category === r.category,
          ),
      )
      if (removeRelationships.length) {
        // TODO enable delete relationship
        window.alert('Removing relationships is not yet implemeneted')
      }
    }
    closeDialog()
  }, [
    mode,
    closeDialog,
    user,
    editData.role,
    editData.relationships,
    changeRole,
    createRelationships,
  ])

  const handleDelete = useCallback(
    () =>
      showDialog({
        title: 'Confirm delete',
        subtitle: `Are you sure you want to delete this user?`,
        onOption1: () => {
          closeDialog()
          user?.id && deleteUser({ id: user.id })
        },
      }),
    [showDialog, closeDialog, user?.id, deleteUser],
  )

  return (
    <Dialog onClose={handleClose} open={open} onBackdropClick={handleClose}>
      <DialogTitle>{user?.email}</DialogTitle>
      <Stack spacing={2}>
        <Stack>
          <Typography>{`id ${
            mode === 'add' ? ' (autogenerated)' : ''
          }`}</Typography>
          <TextField
            size='small'
            variant='outlined'
            disabled
            // placeholder={`id ${mode === 'add' ? ' (autogenerated)' : ''}`}
            onChange={(e) => handleEdit('id')(e.target.value)}
            // onSubmit={handleSubmit}
            value={user?.id}
            // onKeyDown={e => e.key === 'Enter' && handleSubmit()}
          />
        </Stack>
        <Stack>
          <Typography>{'Role'}</Typography>
          <Select
            size='small'
            variant='outlined'
            placeholder={'Role'}
            onChange={(e) => handleEdit('role')(e.target.value as Role)}
            onSubmit={handleSubmit}
            value={editData.role}
            onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}>
            {Object.entries(Role).map(([k, v]) => (
              <MenuItem value={v} key={v}>
                {k}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Stack>
          <Typography>{'Training Athletes'}</Typography>
          <UserAutocomplete
            type={Relationships.TRAINING}
            editRelationships={handleEdit('relationships')}
            editData={editData}
          />
        </Stack>
        <Stack>
          <Typography>{'Nutrition Athletes'}</Typography>
          <UserAutocomplete
            type={Relationships.NUTRITION}
            editRelationships={handleEdit('relationships')}
            editData={editData}
          />
        </Stack>
        <Stack>
          <Typography>{'Rest Athletes'}</Typography>
          <UserAutocomplete
            type={Relationships.REST}
            editRelationships={handleEdit('relationships')}
            editData={editData}
          />
        </Stack>
        <Stack>
          <Typography>{'One Percent Athletes'}</Typography>
          <UserAutocomplete
            type={Relationships.ONE_PERCENT}
            editRelationships={handleEdit('relationships')}
            editData={editData}
          />
        </Stack>
        <Stack>
          <Typography>{'Internal Game Athletes'}</Typography>
          <UserAutocomplete
            type={Relationships.INTERNAL_GAME}
            editRelationships={handleEdit('relationships')}
            editData={editData}
          />
        </Stack>
        {/* <Autocomplete<UserByRole, true>
            multiple
            autoHighlight
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            style={{ flex: 3 }}
            options={users}
            value={users.filter((e) =>
              editData.relationshipFrom.find((r) => r.to === e.id),
            )}
            onChange={(event, newValue) => {
              handleEdit('relationshipFrom')([
                ...editData.relationshipFrom.filter(
                  (r) => r.category !== Relationships.TRAINING,
                ),
                ...newValue.map((n) => ({
                  to: n.id,
                  category: Relationships.TRAINING,
                })),
              ])
            }}
            getOptionLabel={(option) => option?.email ?? ''}
            renderOption={(props, option) => (
              <Box component='li' {...props} key={option?.id}>
                {option?.name}
              </Box>
            )}
            renderInput={(params) => <TextField {...params} size='small' />}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const { key, ...rest } = getTagProps({ index })
                return (
                  <Chip key={key} label={option?.name} size='small' {...rest} />
                )
              })
            }
          /> */}
        <Row gap={20}>
          <Button variant='contained' color='info' onClick={handleSubmit}>
            {titleCase(mode)}
          </Button>
          <Button
            variant='contained'
            color='inherit'
            style={{ color: 'black' }}
            onClick={handleClose}>
            Cancel
          </Button>
          {editData.id && (
            <Button variant='contained' color='error' onClick={handleDelete}>
              Delete
            </Button>
          )}
        </Row>
      </Stack>
    </Dialog>
  )
}

const Dialog = styled(MuiDialog)({
  '.MuiDialog-paper': {
    padding: 40,
    minWidth: 800,
  },
})

const formFieldsFromQueries = (
  user: GetUserByIdResponse,
  relationships: GetRelationshipsById,
): FormData => {
  return {
    id: user.id.toString() ?? '',
    name: user.email ?? '',
    // createdAt: user.createdAt ?? '',
    phoneNumber: user.phoneNumber ?? '',
    email: user.email ?? '',
    role: isRole(user.role) ? user.role : Role.USER,
    // di: user.di,
    // formResponses: user.formResponses,
    // braintreeId: user.braintreeId,
    relationships: relationships.map((r) => ({
      to: r.to.id,
      category: r.category,
    })),
    // relationshipTo: user.relationshipTo,
  }
}

// const updateFieldsFromFormFields = (
//   data: FormData,
// ): GetUserResponse => {
//   return {
//     id: data.id,
//     name: data.email,
//     // createdAt: data.createdAt,
//     phone: data.phone,
//     email: data.email,
//     role: isRole(data.role) ? data.role : Role.USER,
//     di: data.di,
//     formResponses: data.formResponses,
//     braintreeId: data.braintreeId,
//     relationships: data.relationships,
//   }
// }

const UserAutocomplete = ({
  type,
  editRelationships,
  editData,
}: {
  type: Relationships
  editRelationships: (val: FormData['relationships']) => void
  editData: FormData
}) => {
  const { users } = useUsersByRole(Role.USER)
  return (
    <Autocomplete<UserByRole, true>
      multiple
      autoHighlight
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      style={{ flex: 3 }}
      options={users}
      value={users.filter((e) =>
        editData.relationships.find(
          (r) => r.to === e.id && r.category === type,
        ),
      )}
      onChange={(event, newValue) => {
        editRelationships([
          ...editData.relationships.filter((r) => r.category !== type),
          ...newValue.map((n) => ({
            to: n.id,
            category: type,
          })),
        ])
      }}
      getOptionLabel={(option) => option?.name ?? ''}
      renderOption={(props, option) => (
        <Box component='li' {...props} key={option?.id}>
          {option?.name}
        </Box>
      )}
      renderInput={(params) => <TextField {...params} size='small' />}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...rest } = getTagProps({ index })
          return <Chip key={key} label={option?.name} size='small' {...rest} />
        })
      }
    />
  )
}
