import { styled } from '@mui/material/styles'

export const FormLabel = styled('label')`
  font-family: Nunito;
  font-weight: 600;
  margin: 0;
  padding: 0;
  color: ${(p) => p.theme.palette.text.secondary};
  font-size: 0.875rem;
`
