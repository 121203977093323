import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Button, P, Title } from 'components'
import {
  useBraintreePlans,
  useBraintreeSubscription,
} from 'contexts/braintree/hooks'
import { PlanWithConfig } from 'contexts/braintree/types'
import { BraintreePlanId } from 'utils/config'
import { SubTitle } from './SubTitle'

const PlansTable = ({
  handleClick,
  selectedPlan,
  availablePlanIds,
}: {
  handleClick: (plan: PlanWithConfig) => void
  selectedPlan: PlanWithConfig | null
  availablePlanIds?: BraintreePlanId[]
}) => {
  const { braintreePlans, isLoading: isLoadingPlans } = useBraintreePlans()
  const { braintreeSubscription, isLoading: isLoadingSubscription } =
    useBraintreeSubscription()
  return (
    <Grid
      container
      spacing={2}
      alignItems='flex-end'
      justifyContent='center'
      style={{ padding: 20 }}>
      {isLoadingPlans || isLoadingSubscription ? (
        <CircularProgress />
      ) : (
        braintreePlans
          ?.filter((plan) =>
            availablePlanIds
              ? availablePlanIds.includes(plan.id as BraintreePlanId)
              : plan.id !== BraintreePlanId.SANDBOX_TRAINING_PEAKS_SPECIAL &&
                plan.id !== BraintreePlanId.PROD_TRAINING_PEAKS_SPECIAL,
          )
          .map((plan) => {
            const { id, title, subtitle, daysFree, body } = plan
            const activePlan = braintreeSubscription?.planId === id
            const otherActivePlan = !!braintreeSubscription && !activePlan
            return (
              <Grid item key={id} xs={12} md={6}>
                <PlanCard>
                  <Title>{title}</Title>
                  <SubTitle>{subtitle}</SubTitle>
                  <SubTitle>{daysFree}</SubTitle>
                  <CardContent>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        // height: 48,
                      }}>
                      <P>{body}</P>
                    </Box>
                  </CardContent>
                  <CardActions style={{ flexDirection: 'column' }}>
                    {isLoadingSubscription ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        fullWidth
                        sx={{
                          color:
                            selectedPlan?.id === plan.id ? 'white' : 'black',
                        }}
                        disabled={otherActivePlan}
                        onClick={() => handleClick(plan)}
                        variant={
                          selectedPlan?.id === plan.id
                            ? 'contained'
                            : 'outlined'
                        }>
                        {selectedPlan?.id === plan.id ? 'Selected' : 'Select'}
                      </Button>
                    )}
                  </CardActions>
                </PlanCard>
              </Grid>
            )
          })
      )}
    </Grid>
  )
}

const PlanCard = styled(Card)`
  text-align: center;
  background-color: ${(p) => p.theme.palette.background.paper} !important;
  padding: 1rem;
  // height: 400px;
  ${(props) => props.theme.breakpoints.up('sm')} {
    // height: 360px;
  }
`

export default PlansTable
