import AppProviders from 'contexts/AppProviders'
import Router from 'router/Router'
import { queryClient } from 'utils/setup'

function App() {
  return (
    <AppProviders queryClient={queryClient}>
      <Router />
    </AppProviders>
  )
}

export default App
