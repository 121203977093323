import { memo } from 'react'
import { styled } from '@mui/material/styles'

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

interface Props extends InputProps {
  id: string
  label: string
  className?: string
}

export const Radio = memo(({ id, label, className, ...restOf }: Props) => {
  return (
    <Wrapper className={className}>
      <input type='radio' id={id} {...restOf} />
      <label htmlFor={id}>{label}</label>
    </Wrapper>
  )
})

Radio.displayName = 'Radio'

const Wrapper = styled('div')`
  input[type='radio'] {
    margin: 0;
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0;

    + label {
      margin: 0;
      display: inline-block;
      padding-left: 1.375rem;
      padding-top: 0.0625rem;
      position: relative;
      cursor: pointer;
      font-size: 0.875rem;
      color: ${(p) => p.theme.palette.text.primary};
      z-index: 1;

      a {
        color: ${(p) => p.theme.palette.text.primary};
        text-decoration: none;
      }

      &::before {
        position: absolute;
        top: 0.25rem;
        left: 0;
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: ${(p) => p.theme.palette.background.elements};
        content: '';
        border: 2px solid ${(p) => p.theme.palette.border.default};
        transition: all 0.1s;
      }

      &::after {
        display: none;
        content: '';
        position: absolute;
        display: inline-block;
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 50%;
        top: 0.65rem;
        left: 0.4rem;
        background-color: ${(p) => p.theme.palette.background.elements};
      }

      &:hover {
        &::before {
          border-color: ${(p) => p.theme.palette.primary.main};
        }
      }
    }

    &:disabled {
      + label {
        opacity: 0.6;
        cursor: auto;

        &:hover {
          &::before {
            border-color: ${(p) => p.theme.palette.border.default};
          }
        }
      }
    }

    &:focus {
      + label {
        &::before {
          box-shadow: 0 0 0 3px
            ${(p) =>
              p.theme.palette.primary.main.replace(
                /rgba?(\(\s*\d+\s*,\s*\d+\s*,\s*\d+)(?:\s*,.+?)?\)/,
                'rgba$1,0.2)',
              )};
        }
      }
    }

    &:checked {
      + label {
        &::before {
          background-color: ${(p) => p.theme.palette.primary.main};
          border-color: ${(p) => p.theme.palette.primary.main};
        }

        &::after {
          display: inline-block;
        }
      }
    }
  }
`
