import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import BraintreeDropIn from 'components/BraintreeDropIn'
import { SubTitle } from 'components/SubTitle'
import { Title } from 'components'
import {
  createContext,
  Dispatch,
  ReactNode,
  Reducer,
  useContext,
  useReducer,
} from 'react'
import { styled } from '@mui/material/styles'
import { PlanWithConfig } from './types'

type State = {
  open: boolean
  plan: PlanWithConfig | undefined
}

type Action =
  | {
      type: 'close'
    }
  | {
      type: 'open' // default timeout of 2000
      payload: Omit<State, 'open'>
    }

const INITIAL_STATE: State = {
  plan: undefined,
  open: false,
}

const reducer: Reducer<State, Action> = (state: State, action: Action) => {
  switch (action.type) {
    case 'open':
      return {
        open: true,
        ...action.payload,
      }
    case 'close':
      return {
        ...state,
        open: false, // default value
        planId: undefined,
      }
    default:
      throw new Error('unrecognized settings action in snackbar reducer')
  }
}

const DispatchContext = createContext<Dispatch<Action>>(() => undefined)

export const useOpenPaymentModal = () => {
  const dispatch = useContext(DispatchContext)

  const openPaymentModal = (plan: PlanWithConfig) => {
    dispatch({ type: 'open', payload: { plan } })
  }

  return openPaymentModal
}

export const useClosePaymentModal = () => {
  const dispatch = useContext(DispatchContext)

  const closePaymentModal = () => dispatch({ type: 'close' })

  return closePaymentModal
}

const Controller = ({ children }: { children: ReactNode }) => {
  const [data, reduceData] = useReducer(reducer, INITIAL_STATE)
  const { open, plan } = data

  // const handleClick = useCallback(
  //   (option: '1' | '2') => () => {
  //     if (option === '1') {
  //       onOption1 && onOption1()
  //     } else if (option === '2') {
  //       onOption2 && onOption2()
  //     }
  //     reduceData({ type: 'closeDialog' })
  //   },
  //   [onOption1, onOption2],
  // )

  const handleClose = () => {
    console.log('close')
    reduceData({ type: 'close' })
  }

  return (
    <DispatchContext.Provider value={reduceData}>
      {children}
      {plan && (
        <PaymentModal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <ModalContentWrapper>
            <Title>Purchase {plan.name}</Title>
            <SubTitle>
              Please fill in your payment details to complete the subscription
            </SubTitle>
            <BraintreeDropIn plan={plan} />
          </ModalContentWrapper>
        </PaymentModal>
      )}
    </DispatchContext.Provider>
  )
}

const PaymentModal = styled(Modal)``

const ModalContentWrapper = styled(Box)`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  max-width: 90vw;
  background-color: ${(p) => p.theme.palette.background.paper};
  border-radius: 10px;
  border: 2px solid ${(p) => p.theme.palette.divider};
  padding: 32px;
`

export default Controller
