import { styled } from '@mui/material/styles'
import { Link } from 'components'
import { P } from 'components'
import { withPageWrapper } from 'components/withScreenWrapper'
import { Helmet } from 'react-helmet-async'

function RestrictedPage() {
  return (
    <>
      <Helmet>
        <title>Restricted Content</title>
        <meta name='description' content='Restricted Content' />
      </Helmet>
      <Title>
        Restricted Content!
        <span role='img' aria-label='Crying Face'>
          👮
        </span>
      </Title>
      <P>You must be an administrator or coach to view this page.</P>
      <Link to={process.env.PUBLIC_URL + '/'}>Return to Home Page</Link>
    </>
  )
}

const Title = styled('div')`
  font-weight: bold;
  color: ${(p) => p.theme.palette.text.primary};
  font-size: 3.375rem;
  span {
    font-size: 3.125rem;
  }
`

export default withPageWrapper(
  RestrictedPage,
  { width: 'full' },
  { justifyContent: 'center' },
)
