import { styled } from '@mui/material/styles'

export const RadioGroup = styled('div')`
  display: flex;
  flex-direction: column;

  .radio {
    margin-top: 0.25rem;
    margin-right: 1.5rem;
    margin-bottom: 0.25rem;
  }
`
