import Typography from '@mui/material/Typography'

export function NotificationsPage() {
  return (
    <>
      <Typography variant='h4' component='div'>
        Construction in progress...
      </Typography>
    </>
  )
}
