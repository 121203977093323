// import { UserData } from 'contexts/userData/types'
import produce, { Draft } from 'immer'
import { QueryKey, MutationKey, QueryClient, SetDataOptions } from 'react-query'
import { Updater } from 'react-query/types/core/utils'
import { QueryDataTypes, QueryKeyKey, QueryKeyTypes } from './config'
import { removeEmpty } from './functions'

declare module 'react-query' {
  class QueryClient {
    updateQueryCache<T extends QueryKeyKey>(
      queryKey: QueryKeyTypes[T],
      updater: Updater<QueryDataTypes[T] | undefined, QueryDataTypes[T]>,
      options?: SetDataOptions,
    ): void
    /**
     * Returns old data
     */
    optimisticUpdate<
      C extends CacheDataInterface,
      V extends DataUpdateInterface = DataUpdateInterface,
    >(queryKey: QueryKey, dataUpdate: V, table: keyof Draft<C>): C | undefined
    /**
     * Returns old data
     */
    optimisticInsert<
      C extends CacheDataInterface,
      V extends DataInsertInterface = DataInsertInterface,
    >(queryKey: QueryKey, dataInsert: V, table: keyof Draft<C>): C | undefined
    /**
     * Returns old data
     */
    optimisticDelete<
      C extends CacheDataInterface,
      V extends DataDeleteInterface = DataDeleteInterface,
    >(queryKey: QueryKey, dataInsert: V, table: keyof C): C | undefined
    /**
     * Only if it is the only related mutation
     */
    invalidateIfInactive(
      queryKeys: QueryKey[],
      mutationKeys: MutationKey[],
    ): boolean
  }
}

QueryClient.prototype.updateQueryCache = function <T extends QueryKeyKey>(
  this,
  queryKey: QueryKeyTypes[T],
  updater: Updater<QueryDataTypes[T] | undefined, QueryDataTypes[T]>,
  options?: SetDataOptions,
) {
  this.setQueryData<QueryDataTypes[T]>(queryKey, updater, options)
}

QueryClient.prototype.optimisticUpdate = function <
  CacheData extends CacheDataInterface,
  DataUpdate extends DataUpdateInterface = DataUpdateInterface,
>(
  this,
  queryKey: string,
  dataUpdate: DataUpdate,
  table: keyof Draft<CacheData>,
) {
  const oldData = this.getQueryData<CacheData>(queryKey)
  if (!oldData) return undefined

  const newData: CacheData = produce(oldData, (draft) => {
    const idx =
      draft[table].findIndex((d) => d.id === dataUpdate.where.id?._eq) ?? -1
    if (idx === -1 || !dataUpdate._set) return
    Object.assign(draft?.[table]?.[idx] ?? {}, removeEmpty(dataUpdate._set))
  })
  this.setQueryData<CacheData>(queryKey, newData)
  return oldData
}

// QueryClient.prototype.optimisticInsert = function<
//   CacheData extends CacheDataInterface,
//   DataInsert extends DataInsertInterface = DataInsertInterface
// >(
//   this,
//   queryKey: string,
//   dataInsert: DataInsert,
//   table: keyof Draft<CacheData>,
// ) {
//   const oldData = this.getQueryData<CacheData>(queryKey)
//   if (!oldData) return undefined

//   const newData: CacheData = produce(oldData, draft => {
//     draft[table].push(...dataInsert.map(o => removeConnectors(o)))
//   })
//   console.log('newData', newData)
//   this.setQueryData<CacheData>(queryKey, newData)
//   return oldData
// }

QueryClient.prototype.optimisticDelete = function <
  CacheData extends CacheDataInterface,
  DataDelete extends DataDeleteInterface = DataDeleteInterface,
>(this, queryKey: string, dataDelete: DataDelete, table: keyof CacheData) {
  const oldData = this.getQueryData<CacheData>(queryKey)
  if (!oldData) return undefined

  const newData = {
    [table]: oldData[table].filter(
      (d) => d.id && !dataDelete.where.id?._in?.includes(d.id),
    ),
  }

  this.setQueryData(queryKey, newData)
  return oldData
}

QueryClient.prototype.invalidateIfInactive = function (
  this,
  queryKeys: QueryKey[],
  mutationKeys: MutationKey[],
) {
  const otherMutations =
    !!mutationKeys.length &&
    mutationKeys.reduce(
      (accu, mutationKey) => accu + this.isMutating({ mutationKey }),
      0,
    ) > 1
  // Prevent overlapping mutation optimistic updates https://github.com/tannerlinsley/react-query/discussions/2245
  if (otherMutations) {
    console.log(`Active ${mutationKeys} mutations, no optimistic update`)
    return false
  } else {
    console.log(`Performing optimistic update for ${queryKeys}`)
    queryKeys.forEach((key) => this.invalidateQueries(key))
    return true
  }
}

type DataInsertInterface = IdObject[]

type DataDeleteInterface = {
  where: { id?: { _in?: string[] | null } | null }
}

type DataUpdateInterface = {
  where: { id?: { _eq?: string | null } | null }
  _set?: Record<string, unknown> | null
}

// type InsertInter
type CacheDataInterface = { [key: string]: IdObject[] }

type IdObject = { id?: string | null }

export { QueryClient }
