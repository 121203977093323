import { Grid, Skeleton, Stack } from '@mui/material'
import { useMutatePinVideo, useSelfUser, useVideos } from 'contexts/api/hooks'
import {
  Role,
  TabPinVideo,
  UpdatePinVideoById,
  VideoItem,
} from 'contexts/api/types'
import { Header } from 'pages/AthletePages/components'
import { useCallback } from 'react'
import { PinnedContent } from './PinnedContent'
import { useQueryClient } from 'react-query'

export const VideoPinned = ({ tabPinVideo }: { tabPinVideo?: TabPinVideo }) => {
  const { videos, isLoading } = useVideos()
  const { mutateAsync: mutatePinVideo } = useMutatePinVideo()
  const { role } = useSelfUser()
  const queryClient = useQueryClient()

  const isUser = role !== Role.ADMIN && role !== Role.COACH

  const mapVideoItemToUpdatePinVideo = (
    video: VideoItem,
    pinnedTab: TabPinVideo,
  ) => {
    const { id } = video
    const payload: UpdatePinVideoById = { id: id }
    if (id !== undefined) {
      switch (pinnedTab) {
        case TabPinVideo.PinnedInternal:
          payload.isPinnedInternal = false
          break
        case TabPinVideo.PinnedNutrition:
          payload.isPinnedNutrition = false
          break
        case TabPinVideo.PinnedOnePercent:
          payload.isPinnedOnePercent = false
          break
        case TabPinVideo.PinnedRest:
          payload.isPinnedRest = false
          break
        case TabPinVideo.PinnedTraining:
          payload.isPinnedTraining = false
          break
      }
    }
    return payload
  }

  const handlePinVideo = useCallback(
    (video: VideoItem, pinnedTab?: TabPinVideo) => {
      if (video.id !== undefined && pinnedTab !== undefined) {
        const payload = mapVideoItemToUpdatePinVideo(video, pinnedTab)
        if (payload) {
          mutatePinVideo(payload).then(() => {
            queryClient.invalidateQueries('videos')
          })
        }
      }
    },
    [mutatePinVideo, queryClient],
  )

  return isLoading ? (
    <>
      <Grid
        container
        item
        xs={12}
        alignItems='flex-end'
        justifyContent='center'>
        <Skeleton variant='circular' width={20} height={20} />
      </Grid>
    </>
  ) : (
    <>
      <Stack spacing={2}>
        {isUser || tabPinVideo ? (
          <>
            <Header>Videos</Header>
            <PinnedContent videos={videos} pinnedTab={tabPinVideo} />
          </>
        ) : (
          <>
            <PinnedContent
              headerName='Training'
              handlePinVideo={handlePinVideo}
              videos={videos}
              pinnedTab={TabPinVideo.PinnedTraining}
            />
            <PinnedContent
              headerName='Nutrition'
              handlePinVideo={handlePinVideo}
              videos={videos}
              pinnedTab={TabPinVideo.PinnedNutrition}
            />
            <PinnedContent
              headerName='One Percent'
              handlePinVideo={handlePinVideo}
              videos={videos}
              pinnedTab={TabPinVideo.PinnedOnePercent}
            />
            <PinnedContent
              headerName='Rest'
              handlePinVideo={handlePinVideo}
              videos={videos}
              pinnedTab={TabPinVideo.PinnedRest}
            />
            <PinnedContent
              headerName='Internal'
              handlePinVideo={handlePinVideo}
              videos={videos}
              pinnedTab={TabPinVideo.PinnedInternal}
            />
          </>
        )}
      </Stack>
    </>
  )
}
