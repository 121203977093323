import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import { Spacer, Title } from 'components'
import { FeedNewItem } from 'components/Feed'
import { RelationshipAutocomplete } from 'components/RelationshipAutocomplete'
import { withPageWrapper } from 'components/withScreenWrapper'
import { useSelfRelationships, useSelfUser } from 'contexts/api/hooks'
import { Role } from 'contexts/api/types'
import { useState } from 'react'
import { Relationships } from 'router/constants'
import { onlyUnique, titleCase } from 'utils/functions'
import { Header } from './components'

function CoachLandingPage() {
  const [category, setCategory] = useState<Relationships | null>(null)
  const { role } = useSelfUser()
  const { relationships } = useSelfRelationships()
  const availableRelationships = relationships
    .map((r) => r.category)
    .filter(onlyUnique)
  return (
    <>
      <Title style={{ marginBottom: '0.5rem' }}>
        Welcome, {role === Role.COACH ? 'Coach' : 'Admin'}!
      </Title>
      <Divider
        style={{
          borderColor: '#00000050',
          marginBottom: 30,
        }}
      />
      <Header>Please Choose an Athlete to View</Header>
      <Paper
        style={{
          flexDirection: 'column',
          padding: 20,
        }}>
        <RelationshipAutocomplete />
      </Paper>
      <Spacer vertical />
      <Header>Send a message to all your athletes</Header>
      <Stack direction='row' gap={3}>
        <div style={{ flex: 3 }}>
          <FeedNewItem toAll category={category} disabled={!category} />
        </div>
        <Select
          size='small'
          variant='outlined'
          placeholder={'Role'}
          onChange={(e) => setCategory(e.target.value as Relationships)}
          value={category}
          style={{ flex: 1 }}>
          {[
            ...Object.entries(Relationships)
              .filter(
                ([, v]) =>
                  v === Relationships.GENERAL ||
                  role === Role.ADMIN ||
                  availableRelationships.includes(v),
              )
              .sort((a) => (a[1] === Relationships.GENERAL ? -1 : 1))
              .map(([k, v]) => (
                <MenuItem value={v} key={v}>
                  {titleCase(k).replaceAll('_', ' ')}
                </MenuItem>
              )),
          ]}
        </Select>
      </Stack>
    </>
  )
}

export default withPageWrapper(
  CoachLandingPage,
  { width: 'max-width' },
  { alignItems: 'stretch' },
)
