import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import {
  GridCellParams,
  GridColumns,
  GridRowId,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { useUsersByRole } from 'contexts/api/hooks'
import { Role, UserByRole } from 'contexts/api/types'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { useUiDispatch } from './UiProvider'

type Row = UserByRole

export const useDataGrid = () => {
  const { users: coaches, isLoading: isLoadingCoaches } = useUsersByRole(
    Role.COACH,
  )
  const { users: admins, isLoading: isLoadingAdmins } = useUsersByRole(
    Role.ADMIN,
  )
  const isLoading = isLoadingCoaches || isLoadingAdmins
  const data = useMemo(
    () => (isLoading ? [] : [...coaches, ...admins]),
    [isLoading, coaches, admins],
  )
  // console.log('useDataGrid users', data)

  const [selectionModel, setSelectionModel] = useState<GridRowId[]>()
  const { setMode } = useUiDispatch()
  // const { mutateUpdate } = useMutate()

  useEffect(() => {
    if (selectionModel?.length) {
      setMode('selecting')
    } else {
      setMode('idle')
    }
  }, [selectionModel, setMode])

  const columns: GridColumns = useMemo(
    () => [
      { field: GridColumnsFields.ID, headerName: 'ID', width: 100 },
      {
        field: GridColumnsFields.NAME,
        headerName: 'Name',
        width: 120,
        valueGetter: ({ row }: GridValueGetterParams<unknown, Row>) =>
          `${row.name}`,
      },
      {
        field: GridColumnsFields.EMAIL,
        headerName: 'Email',
        width: 240,
        valueGetter: ({ row }: GridValueGetterParams<unknown, Row>) =>
          row.email,
      },
      // {
      //   field: GridColumnsFields.PHONE,
      //   headerName: 'Phone',
      //   width: 120,
      //   valueGetter: ({ row }: GridValueGetterParams<unknown, User>) =>
      //     row.phone,
      // },
      {
        field: GridColumnsFields.CREATED_AT,
        headerName: 'Created',
        width: 120,
        renderCell: ({ row }: GridCellParams<boolean, Row>) => (
          <Tooltip
            title={DateTime.fromISO(row.createdAt)
              .setZone('UTC', { keepLocalTime: true })
              .toISODate()}>
            <div>
              {DateTime.fromISO(row.createdAt)
                //TODO Fix time zones
                .setZone('UTC', { keepLocalTime: true })
                .toRelative()}
            </div>
          </Tooltip>
        ),
      },
      {
        field: GridColumnsFields.ROLE,
        headerName: 'Role',
        width: 70,
        valueGetter: ({ row }: GridValueGetterParams<unknown, Row>) => row.role,
      },
      // {
      //   field: GridColumnsFields.ROLE,
      //   headerName: 'Role',
      //   width: 70,
      //   valueGetter: ({
      //     row,
      //   }: GridValueGetterParams<unknown, Row>) =>
      //     row.role,
      // },
      // {
      //   field: GridColumnsFields.FORM_RESPONSES,
      //   headerName: 'Form Responses',
      //   width: 120,
      //   valueGetter: ({ row }: GridValueGetterParams<unknown, User>) =>
      //     Object.values(row.formResponses ?? {}).filter(isTruthy).length,
      // },
      //TODO: Implement the relationships. For coaches just show all users.
      {
        field: GridColumnsFields.USERS,
        headerName: 'Users',
        flex: 1,
        // width: 10,
        valueGetter: ({ row }: GridValueGetterParams<unknown, Row>) =>
          row.relationships?.map((r) => r.name),
        renderCell: ({ row }: GridCellParams<boolean, Row>) =>
          row.relationships?.map((r) => (
            <Chip
              key={`${r.id}-${r.category}`}
              label={r.name}
              size='small'
              deleteIcon={undefined}
              onClick={(event) => {
                event.stopPropagation()
              }}
            />
          )),
      },
    ],
    [],
  )

  return {
    columns,
    rows: data,
    selectionModel,
    setSelectionModel,
    isLoading,
  }
}

export enum GridColumnsFields {
  ID = 'id',
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
  ROLE = 'role',
  CREATED_AT = 'createdAt',
  USERS = 'users',
}
