import { useSelfUser } from 'contexts/api/hooks'
import { Role } from 'contexts/api/types'
import { useUiStore } from 'contexts/ui/Controller'
import { LoadingPage } from 'pages/LoadingPage'
import RestrictedPage from 'pages/RestrictedPage'
import { Outlet } from 'react-router-dom'

export const ProtectedCoachContent = () => {
  const { role, isLoading } = useSelfUser()
  const { viewUserId } = useUiStore()
  console.debug('ViewUserContentRoute', viewUserId)

  return isLoading ? (
    <LoadingPage />
  ) : role !== Role.ADMIN && role !== Role.COACH ? (
    <RestrictedPage />
  ) : (
    <Outlet />
  )
}
