import { Reducer } from 'utils/types'
import { Action, State } from './types'

export const reducer: Reducer<State, Action> = (
  state: State,
  action: Action,
) => {
  // console.log('Snackbar reducer action', action)
  switch (action.type) {
    case 'resetSnackbar':
      return {
        message: '',
        timeout: 0,
        type: 'Success',
        show: false,
      }
    case 'showSnackbar':
      return {
        ...state,
        show: true, // default value
        timeout: 3000, // default value
        type: 'Success', // default value
        ...action.payload,
      }
    default:
      throw new Error('unrecognized settings action in snackbar reducer')
  }
}
