import { CircularProgress } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { Button } from 'components'
import { Icon, UtilityIcon } from 'components/Icon'
import { apiFetch } from 'contexts/api/functions'
import {
  useFeed,
  useMutateAllFeeds,
  useMutateFeed,
  useSelfUser,
  useViewUser,
} from 'contexts/api/hooks'
import { UploadFileResponse } from 'contexts/api/types'
import { DateTime } from 'luxon'
import { Header } from 'pages/AthletePages/components'
import { useCallback, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { Relationships } from 'router/constants'
import { ApiPath, FEED_PAGE_SIZE } from 'utils/config'
import { getFileType, getFileUrlType, titleCase } from 'utils/functions'
import { MediaType } from 'utils/types'
import VideoSnapshot from 'video-snapshot'

// This contains react player css with !important
import './styles.css'
import { FeedVideo } from './FeedVideo'

export const FeedNewItem = ({
  category,
  disabled,
  toAll,
}: {
  category: Relationships | null
  disabled?: boolean
  toAll?: boolean
}) => {
  const { user: viewUser } = useViewUser()
  const { mutateAsync: mutateIndividualFeedAsync } = useMutateFeed()
  const { mutateAsync: mutateAllFeedsAsync } = useMutateAllFeeds()
  const [uploading, setUploading] = useState(false)
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [message, setMessage] = useState('')
  const [selectedFile, setSelectedFile] = useState<File>()
  const [videoPreview, setVideoPreview] = useState<null | string>(null)

  const preview = selectedFile
    ? {
        name: selectedFile.name,
        url: URL.createObjectURL(selectedFile),
        type: getFileType(selectedFile),
      }
    : undefined

  const changeHandler = useCallback(async (event) => {
    const [file]: File[] = event.target.files
    file && setSelectedFile(file)

    const snapshoter = new VideoSnapshot(file)
    const previewSrc = await snapshoter.takeSnapshot()

    setVideoPreview(previewSrc)
  }, [])

  const handleFileButtonClick = useCallback(() => {
    hiddenFileInput.current?.click()
  }, [])

  const handleSubmit = useCallback(async () => {
    if (!category || uploading) return
    // console.log('handleSubmit url', preview?.url, 'selectedFile', selectedFile)
    // upload file to S3, dispatch snackbar
    setUploading(true)
    const fileUrl = !selectedFile
      ? undefined
      : await apiFetch<UploadFileResponse>(ApiPath.FEED_UPLOAD_FILE, {
          body: selectedFile,
          method: 'POST',
        })
          .then((res) => {
            return res.fileLocation
          })
          .catch((err) => {
            console.error('feed submit fileurl ERROR', err)
            return undefined
          })

    if (toAll) {
      const payload = {
        bulk: toAll,
        category,
        text: message,
        ...(fileUrl && selectedFile
          ? {
              contentUrl: fileUrl,
              contentType: getFileType(selectedFile),
              fileName: selectedFile.name,
            }
          : {}),
      }
      mutateAllFeedsAsync(payload)
        .then(() => {
          setMessage('')
          setSelectedFile(undefined)
        })
        .finally(() => setUploading(false))
    } else if (viewUser?.id !== undefined) {
      const payload = {
        category,
        to: viewUser?.id,
        text: message,
        ...(fileUrl && selectedFile
          ? {
              contentUrl: fileUrl,
              contentType: getFileType(selectedFile),
              fileName: selectedFile.name,
            }
          : {}),
      }
      mutateIndividualFeedAsync(payload)
        .then(() => {
          setMessage('')
          setSelectedFile(undefined)
        })
        .finally(() => setUploading(false))
    } else {
      console.error('Error in feed post! viewUser?.id DNE')
      setUploading(false)
    }
  }, [
    category,
    message,
    mutateAllFeedsAsync,
    mutateIndividualFeedAsync,
    selectedFile,
    toAll,
    viewUser?.id,
    uploading,
  ])

  return (
    <Paper style={{ padding: 10 }}>
      <Stack direction='row' alignItems='flex-end'>
        <TextField
          multiline
          variant='standard'
          value={message}
          style={{ flex: 1 }}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (
              e.key === 'Enter' &&
              !e.shiftKey &&
              !!category &&
              !!message.trim()
            ) {
              e.preventDefault()
              handleSubmit()
            }
          }}
        />
        <Stack>
          {preview?.type === MediaType.Image ? (
            <img
              style={{ maxWidth: 80 }}
              src={preview.url}
              alt={preview.name}
            />
          ) : preview?.type === MediaType.Video ? (
            <ReactPlayer
              url={preview.url}
              light={videoPreview ?? ''}
              width={150}
              height={100}
              playIcon={<Icon path={UtilityIcon.PLAY} />}
              alt={preview.name}
            />
          ) : preview?.type === MediaType.Other ? (
            <div
              style={{
                width: 200,
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
              <Icon path={UtilityIcon.FILE} alt={preview.name} />
              <Typography variant='body2'>
                {truncateFileName(preview.name, 10)}
              </Typography>
            </div>
          ) : undefined}
          <Stack direction='row' justifyContent='flex-end' alignItems='center'>
            {uploading ? (
              <div>
                <CircularProgress size={20} />
              </div>
            ) : (
              <>
                {selectedFile ? (
                  <IconButton
                    onClick={() => {
                      setSelectedFile(undefined)
                      if (hiddenFileInput.current) {
                        hiddenFileInput.current.value = ''
                      }
                    }}>
                    <Icon path={UtilityIcon.CLEAR} />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleFileButtonClick}>
                    <input
                      ref={hiddenFileInput}
                      type='file'
                      style={{ display: 'none' }}
                      onChange={changeHandler}
                    />
                    <Icon path={UtilityIcon.ATTACH} />
                  </IconButton>
                )}
                <IconButton
                  onClick={handleSubmit}
                  disabled={disabled || (!message.trim() && !selectedFile)}>
                  <Icon path={UtilityIcon.SEND} />
                </IconButton>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  )
}

export const Feed = ({ category }: { category: Relationships }) => {
  const { user: selfUser } = useSelfUser()
  const { user: viewUser } = useViewUser()
  const { feed, error, isLoading } = useFeed(
    category,
    selfUser?.id === viewUser?.id ? undefined : viewUser?.id,
  )
  const theme = useTheme()
  const [pages, setPages] = useState(1)
  const noFeedAccess =
    error?.data.statusCode === 500 &&
    error?.data.message === 'Unable to find relationship!'

  const allFeedItems =
    feed
      ?.slice()
      ?.filter(
        (item) =>
          category === Relationships.GENERAL || item.category === category,
      )
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)) ?? []
  // console.debug('Feed allFeedItems', allFeedItems)

  const visibleFeedItems = allFeedItems?.slice(0, pages * FEED_PAGE_SIZE) ?? []
  const allLoaded = allFeedItems?.length === visibleFeedItems?.length

  // Show the items each as paper
  return isLoading || noFeedAccess ? null : (
    <>
      <Header>Feed</Header>
      <Stack spacing={1} style={{ marginTop: 3 }}>
        {category === Relationships.GENERAL ? null : (
          <FeedNewItem category={category} />
        )}
        {visibleFeedItems.map((item) => {
          const type = item.contentType ?? getFileUrlType(item.contentUrl ?? '')
          const preview = item.contentUrl
            ? {
                type,
                url: item.contentUrl,
                name: item.fileName,
              }
            : undefined

          return (
            <Paper key={item.category + '-' + item.createdAt}>
              <Stack spacing={2}>
                {preview?.url && preview?.type === MediaType.Image ? (
                  <FeedImage src={preview?.url} />
                ) : preview?.type === MediaType.Video ? (
                  <FeedVideo preview={preview} noSpecialPreview />
                ) : preview?.type === MediaType.Other ? (
                  <a
                    href={preview.url}
                    download={preview.name ?? 'file'}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      paddingLeft: '1rem',
                      paddingTop: '1rem',
                      marginBottom: -16,
                      cursor: 'pointer',
                    }}>
                    <Icon path={UtilityIcon.FILE} alt={preview.name} />
                    <Typography variant='body2'>
                      {preview.name
                        ? truncateFileName(preview.name, 10)
                        : undefined}
                    </Typography>
                  </a>
                ) : null}
                <Stack padding={2}>
                  {item.text}
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography
                      fontSize={12}
                      color={theme.palette.text.secondary}>
                      {item.from.name}, {titleCase(item.category)}
                    </Typography>
                    <Tooltip
                      title={DateTime.fromISO(item.createdAt)
                        .setZone('UTC', { keepLocalTime: true })
                        .toISO()}>
                      <Typography
                        fontSize={12}
                        color={theme.palette.text.secondary}>
                        {DateTime.fromISO(item.createdAt).toRelative()}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          )
        })}
        <Button
          style={{ padding: 10 }}
          variant='contained'
          disabled={allLoaded}
          onClick={() => setPages((p) => p + 1)}>
          {allLoaded ? "You're all caught up!" : 'Load more feed items'}
        </Button>
      </Stack>
    </>
  )
}

const FeedImage = ({ src }: { src: string }) => {
  const [height, setHeight] = useState(100)
  return (
    <img
      onLoad={(data) =>
        setHeight(
          (data.currentTarget.naturalHeight / data.currentTarget.naturalWidth) *
            data.currentTarget.width,
        )
      }
      style={{ height: height }}
      src={src}
    />
  )
}

const truncateFileName = (str: string, n: number) => {
  const name = str.split('.').slice(0, -1).join('.')
  const ext = str.split('.').slice(-1).join('.')
  const truncatedName = name.length > n ? name.substr(0, n - 1) + '[...]' : name
  return truncatedName + '.' + ext
}
