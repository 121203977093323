import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { GridCellParams, GridRowId } from '@mui/x-data-grid'
import { Button, ConditionalWrapper, DataGrid } from 'components'
import { withPageWrapper } from 'components/withScreenWrapper'
import { useState } from 'react'
import { UiProvider, useDialog } from './UiProvider'
import { GridColumnsFields, useDataGrid } from './useDataGrid'

enum Action {
  NONE,
  DELETE,
  ADD,
  DUPLICATE,
}

type UserFields = {
  id: number
  name: string
}

function RelationsPageWrapper() {
  return (
    <UiProvider>
      <RelationsPage />
    </UiProvider>
  )
}

function RelationsPage() {
  const { columns, rows, isLoading } = useDataGrid()
  const [selection, setSelection] = useState<GridRowId[]>([])
  // const { mutateDuplicate, mutateDelete } = useMutate()
  const { showDialog } = useDialog()
  const [action, setAction] = useState<Action>(Action.NONE)
  // const { showIds } = useSettings()

  return (
    <Wrapper>
      <DataGrid
        initialState={{
          columns: {
            columnVisibilityModel: {
              [GridColumnsFields.ID]: false,
            },
          },
          sorting: {
            sortModel: [{ field: GridColumnsFields.NAME, sort: 'asc' }],
          },
        }}
        rows={rows}
        columns={columns}
        density='compact'
        autoPageSize
        disableSelectionOnClick
        onCellClick={(data: GridCellParams<UserFields>) => {
          if (data.field === '__check__') return // select
          showDialog(data.row.id)
        }}
        checkboxSelection
        loading={isLoading}
        selectionModel={selection}
        onSelectionModelChange={setSelection}
      />
      <Stack
        direction='row'
        justifyContent='flex-end'
        gap='1rem'
        style={{ marginTop: '1rem' }}>
        <Stack direction='row' gap={2}>
          {selection.length === 0 ? (
            <Button
              loading={action === Action.ADD}
              variant='contained'
              onClick={() => {
                setAction(Action.ADD)
                showDialog(undefined)
                setTimeout(() => setAction(Action.NONE), 1000)
              }}>
              Add
            </Button>
          ) : (
            <Button
              variant='contained'
              loading={action === Action.DUPLICATE}
              onClick={() => {
                setAction(Action.DUPLICATE)
                // mutateDuplicate(selection.map(a => `${a}`)).then(() =>
                //   setAction(Action.NONE),
                // )
              }}>
              Duplicate
            </Button>
          )}
          <ConditionalWrapper
            condition={selection.length !== 1}
            wrapper={(children) => (
              <Tooltip title='Can edit only 1 item at a time'>
                <div>{children}</div>
              </Tooltip>
            )}>
            <Button
              variant='contained'
              disabled={selection.length !== 1}
              onClick={() =>
                showDialog(
                  rows
                    .map((r) => ({ ...r, id: r.id }))
                    .find(({ id }) => id === selection[0])?.id,
                )
              }>
              Edit
            </Button>
          </ConditionalWrapper>
          <Button
            variant='contained'
            disabled={selection.length === 0}
            loading={action === Action.DELETE}
            onClick={() => {
              setAction(Action.DELETE)
              // mutateDelete(selection.map(a => `${a}`)).then(() =>
              //   setAction(Action.NONE),
              // )
            }}>
            Delete
          </Button>
        </Stack>
      </Stack>
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export default withPageWrapper(
  RelationsPageWrapper,
  { width: 'max-width' },
  { alignItems: 'stretch' },
)
