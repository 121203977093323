import { Subscription } from 'braintree'
import { IntakeFormData } from 'pages/IntakeFormPage/constants'
import { Relationships } from 'router/constants'
import { MediaType } from 'utils/types'

export type LoadingStatus = 'not_initialized' | 'success' | 'error' | 'no_user'

export type UserBare = {
  id: number
  name: string | null
  firstName: string | null
  lastName: string | null
  username: string
  email: string
  profilePictureUrl: string | null
  role: Role
  signedWaiver: boolean
  signedDate: string
  age: number
}

export enum Role {
  COACH = 'coach',
  USER = 'user',
  ADMIN = 'admin',
}
export const isRole = (role: string | undefined): role is Role =>
  Object.values(Role).includes(role as Role)

export enum OverrideOptions {
  NO_MANUAL_OVERRIDE = 'no-manual-override',
  PROVIDE_SUBSCRIPTION = 'provide-subscription',
  REMOVE_SUBSCRIPTION = 'remove-subscription',
}

/*
 *
 * User Query Responses
 *
 */

export type GetSelfUserResponse = UserBare & {
  phoneNumber: string
  role: Role
  manualOverride: OverrideOptions
  braintreeId: string | null
  subscriptionId: string | null
  formResponses: Partial<IntakeFormData> | null
  // relationshipFrom: Relation[] // No data
  // relationshipTo: Relation[] // no data
}

export type UpdateUserByIdVariables = {
  user: Partial<{
    role: Role
    email: string
    name: string
    phoneNumber: string
  }>
  id: number
}

export type OverrideUserByIdVariables = {
  user: Partial<{
    manualOverride: OverrideOptions
  }>
  id: number
}

export type UserByRole = UserBare &
  TimeStamps & {
    phoneNumber: string
    manualOverride: OverrideOptions
    formResponses: Partial<IntakeFormData> | null
    relationships: GetUserRelationship[]
  }

export type GetUserByRoleResponse = UserByRole[]

export type GetUserByIdResponse = UserBare & {
  phoneNumber: string
  role: Role
  manualOverride: OverrideOptions
  formResponses: Partial<IntakeFormData> | null
  relationships: GetUserRelationship[]
  subscription?: Subscription
} & TimeStamps

export type UpdateUserResponse = UserBare & {
  role: Role
  manualOverride: OverrideOptions
  formResponses: Partial<IntakeFormData> | null
  braintreeId: string | null
  subscriptionId: string | null
  phoneNumber: string
  // relationshipFrom: Relation[] // No data
  // relationshipTo: Relation[] // no data
}

export type DeleteUserVariables = {
  id: number
}

export type UpdateUserWaiver = {
  age: string
  signed: boolean
}

export type GetWaiverResponse = {
  success: boolean
}

/*
 *
 * Relation Query Responses
 *
 */

export type TimeStamps = {
  createdAt: string
  updatedAt: string
}

export type GetUserRelationship = {
  category: Relationships
  id: number
  username: string
  email: string
  profilePictureUrl: string | null
  phoneNumber: string
  name: string
  manualOverride: OverrideOptions
}

export type AllFeedPost = {
  success: boolean
  messagesSent: number
}

export type FeedItem = {
  category: Relationships
  createdAt: string
  updatedAt: string
  // TODO Not sure about this
  from: Partial<GetUserByIdResponse>
  text?: string
  time: string // ISO String
  contentUrl?: string
  contentType?: MediaType
  fileName?: string
}
export type GetRelationship = TimeStamps & {
  id: number
  category: Relationships
  to: UserBare
}

export type GetSelfRelationships = GetRelationship[]

export type GetRelationshipsById = GetRelationship[]

export type RemoveRelationshipVariable = {
  from: number
  to: number
  category: Relationships
}

export type CreateRelationshipVariable = {
  from: number
  to: number
  category: Relationships
}

/*
 *
 * Feed Query Responses
 *
 */
export type UploadFileResponse = {
  success: boolean
  fileLocation: string
}

export type GetFeedResponse = FeedItem[]

export type BaseCreateFeedVariables = {
  category: Relationships
  bulk?: boolean
  text?: string
  contentUrl?: string
  contentType?: MediaType
  fileName?: string
}

export type CreateFeedVariables = BaseCreateFeedVariables & {
  to: number
}

/*
 *
 * Video Query Responses
 *
 */
export type BaseCreateVideoVariables = {
  contentUrl?: string
  contentType?: MediaType
  fileName?: string
}

export type VideoItem = {
  id: number
  contentUrl: string
  contentType: MediaType
  fileName: string
  updatedAt: string
  createdAt: string
  isPinnedTraining: boolean
  isPinnedInternal: boolean
  isPinnedNutrition: boolean
  isPinnedOnePercent: boolean
  isPinnedRest: boolean
}

export type UpdatePinVideoById = {
  id: number
  isPinnedTraining?: boolean
  isPinnedInternal?: boolean
  isPinnedNutrition?: boolean
  isPinnedOnePercent?: boolean
  isPinnedRest?: boolean
}

export enum TabPinVideo {
  PinnedTraining,
  PinnedInternal,
  PinnedNutrition,
  PinnedOnePercent,
  PinnedRest,
}

export type GetVideoResponse = VideoItem[]
/*
 *
 * Auth Query Responses
 *
 */

export type LoginResponseBody = {
  user: UserBare & {
    phoneNumber: string
    role: Role
    braintreeId: string
    subscriptionId: string | null
    formResponses: Partial<IntakeFormData> | null
    // relationshipFrom: Relation[] // provided but no data
    // relationshipTo: Relation[] // provided but no data
  }
  refreshToken: string
  accessToken: string
}

export type RegisterResponseBody = {
  user: UserBare & {
    phoneNumber: string
    role: Role
    braintreeId: string | null
  }
  refreshToken: string
  accessToken: string
}

export type VerifyUserResponseBody = {
  success: boolean
}

export type LogoutResponseBody = {
  success: boolean
}

export type RefreshResponseBody = {
  user: UserBare &
    TimeStamps & {
      phoneNumber: string
      password: string
      role: Role
      di: boolean
      braintreeId: string | null
      subscriptionId: string | null
      formResponses: Partial<IntakeFormData> | null
    }
  accessToken: string
  refreshToken: string
}

export type StartResetPassword = {
  success: boolean
}

export type FinishResetPassword = {
  success: boolean
}
