import { Header } from '.'
import TrainingPeaks from '../../../images/trainingpeaks-login-button.svg'

export const TrainingPeaksButton = () => {
  return (
    <div>
      <Header>Training Peaks</Header>
      <a
        href='https://home.trainingpeaks.com/login'
        target='_blank'
        rel='noreferrer'>
        <img
          style={{
            // large shadow
            borderRadius: 10,
            boxShadow:
              '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
          }}
          src={TrainingPeaks}
          width='236px'
        />
      </a>
    </div>
  )
}
