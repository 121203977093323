import { Component, ReactNode } from 'react'
// import { captureSentryException } from '../utils/sentry'

type ErrorBoundaryProps = {
  errorComponent?: ReactNode
  file: string
  children: ReactNode
}

type ErrorStateProps = {
  hasError?: boolean
  errorComponent: ReactNode
  file: string
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorStateProps> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
      errorComponent: props.errorComponent ?? (
        <div
          style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <span>Something went wrong.</span>
        </div>
      ),
      file: props.file,
    }
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    console.warn(`ErrorBoundary error ${error}`)
    return { hasError: true }
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    console.error('ErrorBoundary componentDidCatch', error, errorInfo)
    // You can also log the error to an error reporting service
    // captureSentryException(new Error('ErrorBoundary error'), {
    //   error,
    //   errorInfo,
    //   file: this.state.file,
    // })
    //this.logErrorToServices(error.toString(), errorInfo.componentStack)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.state.errorComponent
    }

    return this.props.children
  }
}

export default ErrorBoundary
