import { P } from 'components'
import { Content } from 'pages/IntakeFormPage/components/Content'
import { FormLabel } from 'pages/IntakeFormPage/components/FormLabel'
import { Item } from 'pages/IntakeFormPage/components/Item'
import { RadioForm } from 'pages/IntakeFormPage/components/RadioForm'
import { FormKey } from 'pages/IntakeFormPage/constants'
import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { VerticalRadio } from './VerticalRadio'

type FormScaleItemProps = {
  name: FormKey // key of the form data
  initialValue: string | undefined
  wasSubmitted: boolean
  title: string
  subtitle?: string
  lowText: string
  highText: string
}
export const FormScaleItem = ({
  name,
  initialValue,
  title,
  subtitle,
  lowText,
  highText,
}: FormScaleItemProps) => {
  const [value, setValue] = useState<number | undefined>(
    initialValue !== undefined ? parseInt(initialValue) : undefined,
  )

  // Update value on initial value change
  useEffect(() => {
    setValue(initialValue !== undefined ? parseInt(initialValue) : undefined)
  }, [initialValue])

  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  return (
    <Item>
      <Content>
        <RadioForm>
          <FormLabel>{title}</FormLabel>
          {subtitle && <P style={{ marginBottom: 5 }}>{subtitle}</P>}
          <ScaleGroup>
            <P style={{ marginBottom: 5, marginRight: 10 }}>{lowText}</P>
            {items.map((item) => (
              <VerticalRadio
                key={item}
                id={`${name}-${item}`}
                label={String(item)}
                className='radio'
                name={name}
                value={item}
                checked={value === item}
                onChange={() => setValue(item)}
              />
            ))}
            <P style={{ marginBottom: 5, marginLeft: 10 }}>{highText}</P>
          </ScaleGroup>
        </RadioForm>
      </Content>
    </Item>
  )
}

export const ScaleGroup = styled('div')`
  display: flex;
  flex-direction: row;

  .radio {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    width: 2rem;
  }
`
