import { Grid } from '@mui/material'
import { VideoHistory, VideoPinned, VideoUpload } from 'components/Video'
import { withPageWrapper } from 'components/withScreenWrapper'

const VideoPage = () => {
  return (
    <>
      <Grid container columnSpacing={1} marginTop={1}>
        <Grid item xs={12} md={8} marginBottom={2}>
          <VideoPinned />
        </Grid>
        <Grid item xs={12} md={4}>
          <VideoUpload />
        </Grid>
      </Grid>
      <Grid container spacing={5} marginTop={1}>
        <Grid item xs={12} md={12}>
          <VideoHistory adminView />
        </Grid>
      </Grid>
    </>
  )
}

export default withPageWrapper(
  VideoPage,
  { width: 'max-width' },
  { alignItems: 'stretch' },
)
