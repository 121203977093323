import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Button, Title, TitleSection } from 'components'
import { Feed } from 'components/Feed'
import { Icon } from 'components/Icon'
import { withPageWrapper } from 'components/withScreenWrapper'
import { useViewUser } from 'contexts/api/hooks'
import { useState } from 'react'
import { Path, Relationships } from 'router/constants'
import { Goal, Header } from './components'
import { IntakeFormSummary } from './components/IntakeFormSummary'
import { TrainingPeaksButton } from './components/TrainingPeaksButton'
import { VideoHistory, VideoPinned } from 'components/Video'
import { TabPinVideo } from 'contexts/api/types'

const TrainingPage = () => {
  const { user, formResponses, isSelf } = useViewUser()

  const titlePrefix = isSelf ? (
    ''
  ) : (
    <>
      <TitleSection> {`${user?.name}`}</TitleSection> -{' '}
    </>
  )
  return (
    <>
      <Title style={{ marginBottom: '0.5rem' }}>{titlePrefix}Training</Title>
      <Divider
        style={{
          borderColor: '#00000050',
          marginBottom: 30,
        }}
      />
      <Grid container spacing={3} paddingBottom={2}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={3} paddingBottom={2}>
            <IntakeFormSummary category={Path.TRAINING} />
            <TrainingPeaksButton />
            <TrainingPaceCalculator />
          </Stack>
          <Grid item xs={12}>
            <VideoPinned tabPinVideo={TabPinVideo.PinnedTraining}/>
          </Grid>
          <Grid item xs={12}>
            <VideoHistory />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={3}>
            <Stack alignItems='stretch' spacing={2}>
              <Header>Goals / Races</Header>
              <Goal
                title='400m Next Year Goal'
                subtitle={formResponses?.train_400_nextyear_goal}
                icon={<Icon path={Path.TRAINING} />}
              />
              <Goal
                title='800m Next Year Goal'
                subtitle={formResponses?.train_800_nextyear_goal}
                icon={<Icon path={Path.TRAINING} />}
              />
              <Goal
                title='Mile Next Year Goal'
                subtitle={formResponses?.train_mile_nextyear_goal}
                icon={<Icon path={Path.TRAINING} />}
              />
              <Goal
                title='5000m Next Year Goal'
                subtitle={formResponses?.train_5000_nextyear_goal}
                icon={<Icon path={Path.TRAINING} />}
              />
              <Goal
                title='10000m Next Year Goal'
                subtitle={formResponses?.train_10000_nextyear_goal}
                icon={<Icon path={Path.TRAINING} />}
              />
              <Goal
                title='Half Marathon Next Year Goal'
                subtitle={formResponses?.train_half_nextyear_goal}
                icon={<Icon path={Path.TRAINING} />}
              />
              <Goal
                title='Marathon Next Year Goal'
                subtitle={formResponses?.train_marathon_nextyear_goal}
                icon={<Icon path={Path.TRAINING} />}
              />
              <Goal
                title='400m Lifetime Goal'
                subtitle={formResponses?.train_400_lifetime_goal}
                icon={<Icon path={Path.TRAINING} />}
              />
              <Goal
                title='800m Lifetime Goal'
                subtitle={formResponses?.train_800_lifetime_goal}
                icon={<Icon path={Path.TRAINING} />}
              />
              <Goal
                title='Mile Lifetime Goal'
                subtitle={formResponses?.train_mile_lifetime_goal}
                icon={<Icon path={Path.TRAINING} />}
              />
              <Goal
                title='5000m Lifetime Goal'
                subtitle={formResponses?.train_5000_lifetime_goal}
                icon={<Icon path={Path.TRAINING} />}
              />
              <Goal
                title='10000m Lifetime Goal'
                subtitle={formResponses?.train_10000_lifetime_goal}
                icon={<Icon path={Path.TRAINING} />}
              />
              <Goal
                title='Half Marathon Lifetime Goal'
                subtitle={formResponses?.train_half_lifetime_goal}
                icon={<Icon path={Path.TRAINING} />}
              />
              <Goal
                title='Marathon Lifetime Goal'
                subtitle={formResponses?.train_marathon_lifetime_goal}
                icon={<Icon path={Path.TRAINING} />}
              />
            </Stack>
            <Feed category={Relationships.TRAINING} />
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

const TrainingPaceCalculator = () => {
  const [hour, setHour] = useState<string>('0')
  const [minute, setMinute] = useState<string>('0')
  const [second, setSecond] = useState<string>('0')
  const [race, setRace] = useState<string>('1')
  return (
    <div>
      <Header>Pacing Profile</Header>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
        <Typography style={{ marginRight: 5 }}>Goal Race:</Typography>
        <select
          name='goal_race'
          id='goal_race'
          onChange={(e) => setRace(e.target.value)}>
          <option value='1'>Marathon</option>
          <option value='2'>1/2 Marathon</option>
          <option value='3'>20K</option>
          <option value='4'>15K</option>
          <option value='5'>10K/10000m</option>
          <option value='6'>5K/5000m </option>
          <option value='7'>3200m</option>
          <option value='8'>3000m</option>
          <option value='9'>3K Steeple</option>
          <option value='10'>1 mile</option>
          <option value='11'>1500m</option>
          <option value='12'>800m</option>
          <option value='13'>400m</option>
          <option value='14'>100m</option>
          <option value='15'>10000m XC</option>
          <option value='16'>8000m XC</option>
          <option value='17'>6000m XC</option>
          <option value='18'>5000m XC</option>
        </select>
        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20 }}>
          <Typography style={{ marginRight: 5 }}>Goal time:</Typography>
          <select
            name='goal_hh'
            id='goal_hh'
            onChange={(e) => setHour(e.target.value)}>
            <option value='0'>00</option>
            <option value='1'>01</option>
            <option value='2'>02</option>
            <option value='3'>03</option>
            <option value='4'>04</option>
            <option value='5'>05</option>
          </select>
          :
          <select
            name='goal_mm'
            id='goal_mm'
            onChange={(e) => setMinute(e.target.value)}>
            <option value='0'>00</option>
            <option value='1'>01</option>
            <option value='2'>02</option>
            <option value='3'>03</option>
            <option value='4'>04</option>
            <option value='5'>05</option>
            <option value='6'>06</option>
            <option value='7'>07</option>
            <option value='8'>08</option>
            <option value='9'>09</option>
            <option value='10'>10</option>
            <option value='11'>11</option>
            <option value='12'>12</option>
            <option value='13'>13</option>
            <option value='14'>14</option>
            <option value='15'>15</option>
            <option value='16'>16</option>
            <option value='17'>17</option>
            <option value='18'>18</option>
            <option value='19'>19</option>
            <option value='20'>20</option>
            <option value='21'>21</option>
            <option value='22'>22</option>
            <option value='23'>23</option>
            <option value='24'>24</option>
            <option value='25'>25</option>
            <option value='26'>26</option>
            <option value='27'>27</option>
            <option value='28'>28</option>
            <option value='29'>29</option>
            <option value='30'>30</option>
            <option value='31'>31</option>
            <option value='32'>32</option>
            <option value='33'>33</option>
            <option value='34'>34</option>
            <option value='35'>35</option>
            <option value='36'>36</option>
            <option value='37'>37</option>
            <option value='38'>38</option>
            <option value='39'>39</option>
            <option value='40'>40</option>
            <option value='41'>41</option>
            <option value='42'>42</option>
            <option value='43'>43</option>
            <option value='44'>44</option>
            <option value='45'>45</option>
            <option value='46'>46</option>
            <option value='47'>47</option>
            <option value='48'>48</option>
            <option value='49'>49</option>
            <option value='50'>50</option>
            <option value='51'>51</option>
            <option value='52'>52</option>
            <option value='53'>53</option>
            <option value='54'>54</option>
            <option value='55'>55</option>
            <option value='56'>56</option>
            <option value='57'>57</option>
            <option value='58'>58</option>
            <option value='59'>59</option>
          </select>
          :
          <select
            name='goal_ss'
            id='goal_ss'
            onChange={(e) => setSecond(e.target.value)}>
            <option value='0'>00</option>
            <option value='1'>01</option>
            <option value='2'>02</option>
            <option value='3'>03</option>
            <option value='4'>04</option>
            <option value='5'>05</option>
            <option value='6'>06</option>
            <option value='7'>07</option>
            <option value='8'>08</option>
            <option value='9'>09</option>
            <option value='10'>10</option>
            <option value='11'>11</option>
            <option value='12'>12</option>
            <option value='13'>13</option>
            <option value='14'>14</option>
            <option value='15'>15</option>
            <option value='16'>16</option>
            <option value='17'>17</option>
            <option value='18'>18</option>
            <option value='19'>19</option>
            <option value='20'>20</option>
            <option value='21'>21</option>
            <option value='22'>22</option>
            <option value='23'>23</option>
            <option value='24'>24</option>
            <option value='25'>25</option>
            <option value='26'>26</option>
            <option value='27'>27</option>
            <option value='28'>28</option>
            <option value='29'>29</option>
            <option value='30'>30</option>
            <option value='31'>31</option>
            <option value='32'>32</option>
            <option value='33'>33</option>
            <option value='34'>34</option>
            <option value='35'>35</option>
            <option value='36'>36</option>
            <option value='37'>37</option>
            <option value='38'>38</option>
            <option value='39'>39</option>
            <option value='40'>40</option>
            <option value='41'>41</option>
            <option value='42'>42</option>
            <option value='43'>43</option>
            <option value='44'>44</option>
            <option value='45'>45</option>
            <option value='46'>46</option>
            <option value='47'>47</option>
            <option value='48'>48</option>
            <option value='49'>49</option>
            <option value='50'>50</option>
            <option value='51'>51</option>
            <option value='52'>52</option>
            <option value='53'>53</option>
            <option value='54'>54</option>
            <option value='55'>55</option>
            <option value='56'>56</option>
            <option value='57'>57</option>
            <option value='58'>58</option>
            <option value='59'>59</option>
          </select>
        </div>
      </div>
      <a
        style={{
          textDecorationLine: 'none',
        }}
        href={`https://pacingcalc.com/?goal_race=${race}&goal_hh=${hour}&goal_mm=${minute}&goal_ss=${second}`}
        target='_blank'
        rel='noreferrer'>
        <Button fullWidth variant='contained'>
          Training Pace Calculator
        </Button>
      </a>
    </div>
  )
}

export default withPageWrapper(
  TrainingPage,
  { width: 'max-width' },
  { alignItems: 'stretch' },
)
