import CircularProgress from '@mui/material/CircularProgress'
import { withPageWrapper } from 'components/withScreenWrapper'

function LoadingPageContent() {
  return <CircularProgress size='5rem' />
}

export const LoadingPage = withPageWrapper(
  LoadingPageContent,
  {
    width: 'full',
  },
  {
    justifyContent: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
)
