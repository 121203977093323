import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { CircularProgress } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { IsEmail, IsPositive, IsString, Length, Min } from 'class-validator'
import { InputWrapper, Link, Row, Spacer } from 'components'
import { Icon, UtilityIcon } from 'components/Icon'
import { SubTitle } from 'components/SubTitle'
import { register as authRegister, verifyUser } from 'contexts/auth/functions'
import { useDispatchDialog } from 'contexts/dialog'
import rft_logo from 'images/rft_logo.png'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Path } from 'router/constants'
import { setStorageItem, StorageItems } from 'utils/storage'
import { Button } from '../pages/IntakeFormPage/components/Button'
import { Content } from '../pages/IntakeFormPage/components/Content'
import { ErrorMessage } from '../pages/IntakeFormPage/components/ErrorMessage'
import { FormGroup } from '../pages/IntakeFormPage/components/FormGroup'
import { FormLabel } from '../pages/IntakeFormPage/components/FormLabel'
import { WaiverPage } from 'pages/WaiverPage'
import { Transform } from 'class-transformer'

export class RegisterInput {
  @Length(1, undefined, { message: 'First name required' })
  firstName: string

  @Length(1, undefined, { message: 'Last name required' })
  lastName: string

  @IsEmail(undefined, { message: 'Please enter a valid email' })
  email: string

  @Length(8, undefined, { message: 'Minimum 8 characters' })
  password: string

  @Length(8, undefined, {
    message: 'Minimum 8 characters',
  })
  passwordConfirm: string

  @IsString()
  phone: string
}

export class WaiverInput {
  @Transform((age) => parseInt(age.value))
  @Min(18, { message: 'You must be 18 years or older' })
  @IsPositive()
  age: string
}

const resolver = classValidatorResolver(RegisterInput)
const resolver2 = classValidatorResolver(WaiverInput)

export const Register = ({
  onPressLogin,
  onSubmitCallback,
}: {
  onPressLogin?: () => void
  onSubmitCallback?: () => void
}) => {
  const dispatchDialog = useDispatchDialog()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [authState, setAuthState] = useState<'register' | 'waiver'>('register')
  const [fullName, setFullName] = useState<string>('')

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm<RegisterInput>({ resolver, mode: 'all' })

  const {
    register: waiverRegister,
    getValues: secondGetValues,
    handleSubmit: waiverHandleSubmit,
    formState: { errors: waiverErrors },
  } = useForm<WaiverInput>({ resolver: resolver2, mode: 'all' })

  const onSubmit = handleSubmit(
    ({ passwordConfirm, ...data }) => {
      setLoading(true)
      if (passwordConfirm !== data.password) {
        setError('passwordConfirm', { message: 'Passwords do not match' })
        setLoading(false)
      } else {
        setStorageItem(StorageItems.LAST_SUBSCRIPTION_MODAL_DATE, null)
        verifyUser(data)
          .then((res) => {
            if (res.success) {
              setAuthState('waiver')
            }

            setFullName(getValues('firstName') + ' ' + getValues('lastName'))
          })
          .catch((err) => {
            console.log('error', err)
            if (err?.data?.message === 'User already exists') {
              setError('email', {
                message: 'This email already exists. Try a different one.',
              })
            } else if (err?.data?.message === 'User deleted') {
              setError('email', {
                message: 'This account was deleted. Reach out to an admin.',
              })
            } else if (
              err?.data?.message ===
              'User with this phone number already exists'
            ) {
              setError('phone', {
                message:
                  'This phone number already exists. Try a different one',
              })
            } else {
              dispatchDialog({
                type: 'showDialog',
                payload: {
                  title: 'Unable to create account',
                  subtitle:
                    'Possibly an account with this email or phone number already exists',
                  option2Text: null,
                },
              })
            }
          })
          .finally(
            () => setLoading(false)
          )
      }
    },
    (err) => {
      setLoading(false)
      console.log('register err', err)
      dispatchDialog({
        type: 'showDialog',
        payload: {
          title: 'Register Error',
          subtitle: 'Please correct issues to continue',
          option2Text: null,
        },
      })
    }
  )

  const onWaiverSubmit = waiverHandleSubmit(() => {
    const payload = {
      ...getValues(),
      ...secondGetValues(),
      signed: true
    }

    authRegister(payload)
      .then(() => {
        setAuthState('register')
        onSubmitCallback?.()
      })
  })

  return (
    <>
      <FormGroup onSubmit={onSubmit} style={{ paddingBottom: 0 }}>
        <div style={{ display: authState !== 'register' ? 'none' : '' }}>
          <Row justifyContent='center' style={{ marginBottom: 30 }}>
            <img src={rft_logo} style={{ height: 80 }} alt='logo' />
          </Row>
          <SubtitleWrapper style={{ justifyContent: 'center' }}>
            <SubTitle>Login to your account</SubTitle>
          </SubtitleWrapper>
          <FormContent>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
              }}>
              <div style={{ flex: 1 }}>
                <FormLabel htmlFor={`first-name-input`}>First name</FormLabel>
                <InputWrapper>
                  <TextField
                    style={{ width: '100%' }}
                    variant='standard'
                    id={`$lastName-input`}
                    type={'text'}
                    autoComplete={'given-name'}
                    placeholder={'Enter your first name'}
                    aria-describedby={
                      errors.firstName ? `firstName-error` : undefined
                    }
                    {...register('firstName')}
                  />
                  {errors.firstName ? (
                    <ErrorMessage
                      role='alert'
                      id={`firstName-error`}
                      className='firstName-message'>
                      {errors.firstName.message}
                    </ErrorMessage>
                  ) : null}
                </InputWrapper>
              </div>
              <div style={{ flex: 1 }}>
                <FormLabel htmlFor={`last-name-input`}>Last name</FormLabel>
                <InputWrapper>
                  <TextField
                    style={{ width: '100%' }}
                    variant='standard'
                    id={`$firstName-input`}
                    type={'text'}
                    autoComplete={'family-name'}
                    placeholder={'Enter your last name'}
                    aria-describedby={
                      errors.lastName ? `lastName-error` : undefined
                    }
                    {...register('lastName')}
                  />
                  {errors.lastName ? (
                    <ErrorMessage
                      role='alert'
                      id={`lastName-error`}
                      className='lastName-message'>
                      {errors.lastName.message}
                    </ErrorMessage>
                  ) : null}
                </InputWrapper>
              </div>
            </div>
          </FormContent>
          <FormContent>
            <FormLabel htmlFor={`email-input`}>Email</FormLabel>
            <InputWrapper>
              <TextField
                style={{ width: '100%' }}
                variant='standard'
                id={`$email-input`}
                type={'text'}
                autoComplete={'email'}
                autoCapitalize='none'
                placeholder={'Enter your email'}
                aria-describedby={errors.email ? `email-error` : undefined}
                {...register('email')}
              />
              {errors.email ? (
                <ErrorMessage
                  role='alert'
                  id={`email-error`}
                  className='error-message'>
                  {errors.email.message}
                </ErrorMessage>
              ) : null}
            </InputWrapper>
          </FormContent>
          <FormContent>
            <FormLabel htmlFor={`phone-number-input`}>Phone</FormLabel>
            <InputWrapper>
              <TextField
                style={{ width: '100%' }}
                variant='standard'
                id={`$phone-input`}
                type={'tel'}
                autoComplete={'tel'}
                placeholder={'Enter your phone number'}
                aria-describedby={errors.phone ? `phone-error` : undefined}
                {...register('phone')}
              />
              {errors.phone ? (
                <ErrorMessage
                  role='alert'
                  id={`phone-error`}
                  className='error-message'>
                  {errors.phone.message}
                </ErrorMessage>
              ) : null}
            </InputWrapper>
          </FormContent>
          <FormContent>
            <FormLabel htmlFor={`password-input`}>Password</FormLabel>
            <InputWrapper>
              <PasswordWrapper>
                <TextField
                  style={{ width: '100%' }}
                  variant='standard'
                  id={`$password-input`}
                  type={showPassword ? 'text' : 'password'}
                  autoComplete={'new-password'}
                  autoCapitalize='none'
                  placeholder={'Enter a password'}
                  aria-describedby={errors.password ? `password-error` : undefined}
                  {...register('password')}
                />
                <IconButton
                  component='div'
                  className='password-adornment'
                  aria-label='toggle password visibility'
                  onClick={() => setShowPassword((p) => !p)}
                  // onMouseDown={(e) => e.preventDefault()}
                  edge='end'>
                  {showPassword ? (
                    <Icon path={UtilityIcon.EYE_OFF} />
                  ) : (
                    <Icon path={UtilityIcon.EYE} />
                  )}
                </IconButton>
              </PasswordWrapper>
              {errors.password ? (
                <ErrorMessage
                  role='alert'
                  id={`password-error`}
                  className='error-message'>
                  {errors.password.message}
                </ErrorMessage>
              ) : null}
            </InputWrapper>
          </FormContent>
          <FormContent>
            <FormLabel htmlFor={`password-input`}>Confirm Password</FormLabel>
            <InputWrapper>
              <PasswordWrapper>
                <TextField
                  style={{ width: '100%' }}
                  variant='standard'
                  id={`$confirm-password-input`}
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder={'Confirm your password'}
                  autoCapitalize='none'
                  aria-describedby={
                    errors.passwordConfirm ? `confirm-password-error` : undefined
                  }
                  {...register('passwordConfirm')}
                />
                <IconButton
                  component='div'
                  className='password-adornment'
                  aria-label='toggle confirm password visibility'
                  onClick={() => setShowConfirmPassword((p) => !p)}
                  // onMouseDown={(e) => e.preventDefault()}
                  edge='end'>
                  {showConfirmPassword ? (
                    <Icon path={UtilityIcon.EYE_OFF} />
                  ) : (
                    <Icon path={UtilityIcon.EYE} />
                  )}
                </IconButton>
              </PasswordWrapper>
              {errors.passwordConfirm ? (
                <ErrorMessage
                  role='alert'
                  id={`password-error`}
                  className='error-message'>
                  {errors.passwordConfirm.message}
                </ErrorMessage>
              ) : null}
            </InputWrapper>
          </FormContent>
          <Spacer vertical size={20} />
          <ButtonGroup>
            <Button variant='contained' type='submit'>
              {loading ? <CircularProgress color='secondary' /> : 'Register'}
            </Button>
          </ButtonGroup>
          <Typography style={{ alignSelf: 'center', marginTop: 5 }}>
            Already have an account?
            {onPressLogin ? (
              <Button onClick={onPressLogin} style={{ marginLeft: 6 }}>Login now</Button>
            ) : (
              <Link to={`/${Path.AUTH}/${Path.LOGIN}`} style={{ marginLeft: 6 }}>Login now</Link>
            )}
          </Typography>
        </div>
      </FormGroup >
      <div style={{ display: authState !== 'waiver' ? 'none' : '' }}>
        <WaiverPage onPressBack={() => setAuthState('register')} fullName={fullName} onSubmit={onWaiverSubmit} register={waiverRegister} errors={waiverErrors} />
      </div>
    </>
  )
}

const FormContent = styled(Content)`
  padding: 0.25rem 0;
`

const ButtonGroup = styled('div')`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  button {
    margin-bottom: 1rem;
  }
`

const PasswordWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .password-adornment {
    color: ${(p) => p.theme.palette.text.primary};
  }
`

const SubtitleWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 2rem;

  h3 {
    margin-right: 2rem;
  }
`