import axios from 'axios'
import {
  GetSelfUserResponse,
  RefreshResponseBody,
  UpdateUserResponse,
} from 'contexts/api/types'
import {
  FormKey,
  IntakeFormData,
  REQUIRED_FIELDS,
} from 'pages/IntakeFormPage/constants'
import { ApiPath, QueryKeyKey } from 'utils/config'
import { API_HOST, IS_PRODUCTION } from 'utils/constants'
import dummyIntakeForm from 'utils/dummy_data.json'
import { queryClient } from 'utils/setup'
import { getStorageItem, setStorageItem, StorageItems } from 'utils/storage'

export const isValidForm = (formData: FormData) => {
  const fieldValues: Partial<IntakeFormData> = Object.fromEntries(
    formData.entries(),
  )
  const formIsValid = Object.keys(fieldValues).every(
    (name) =>
      !getFieldError(name as FormKey, fieldValues[name as FormKey] as string),
  )
  return formIsValid
}

export function getFieldError(name: FormKey, value: string) {
  // Required fields must not be blank
  if (REQUIRED_FIELDS.includes(name) && !value) {
    return 'This field is required'
  } else if (!value) {
    // If not required, then blank is ok
    return ''
  }

  // if (name === 'email' && !isEmail(value)) {
  //   return 'Please enter a valid email address'
  // } else
  if (name === 'age') {
    if (!(parseInt(value) > 5) || !(parseInt(value) < 99)) {
      return 'Please enter a valid number between 5 and 99.'
    }
  } else if (name === 'weight' && !(parseInt(value) > 0)) {
    return 'Please enter a valid number greater than 0.'
  } else if (name === 'train_years_running' && isNaN(parseInt(value))) {
    return 'Please enter a valid number.'
  } else if (
    name === 'train_current_typical_volume' &&
    !(parseInt(value) > 0)
  ) {
    return 'Please enter a valid number greater than 0.'
  } else if (
    name === 'train_runner_high_weekly_ever' &&
    !(parseInt(value) > 0)
  ) {
    return 'Please enter a valid number greater than 0.'
  } else if (name === 'train_runner_high_weekly_6' && !(parseInt(value) > 0)) {
    return 'Please enter a valid number greater than 0.'
  } else if (name === 'train_runner_low_6' && !(parseInt(value) > 0)) {
    return 'Please enter a valid number greater than 0.'
  } else if (name === 'sleep_hours' && !(parseInt(value) > 0)) {
    return 'Please enter a valid number greater than 0.'
  } else if (name === 'sleep_screen' && !(parseInt(value) >= 0)) {
    return 'Please enter a valid number greater than or equal to 0.'
  } else if (name === 'op_weight_race_goal' && !(parseInt(value) > 0)) {
    return 'Please enter a valid number greater than 0.'
  }
}

// type ApiPath = 'payments' | 'auth' | 'users'
type UrlPath = `${ApiPath}${string}`
type Method = 'GET' | 'POST' | 'PATCH' | 'DELETE'
type Options = {
  method?: Method
  body?: object
  headers?: HeadersInit
}
export const apiFetch = async <T>(path: UrlPath, options?: Options) => {
  // const method = 'GET'
  const isFile = path.includes(ApiPath.FEED_UPLOAD_FILE)
  const token = getStorageItem(StorageItems.ACCESS_TOKEN)
  const default_headers = {
    credentials: 'include',
    ...(isFile ? {} : { 'Content-Type': 'application/json' }),
    ...(token ? { Authorization: 'Bearer ' + token } : {}),
  }

  const fileFormData = new FormData()
  if (isFile) {
    fileFormData.append('file', options?.body as Blob)
  }

  // const headers = new Headers(default_headers)
  const payload = {
    withCredentials: true,
    url: `${API_HOST}${path}`,
    method: options?.method ?? 'GET',
    headers: default_headers,
    ...(options?.body
      ? {
          data: isFile ? fileFormData : options.body,
        }
      : {}),
  }
  !IS_PRODUCTION && console.log('apiFetch', path, payload)

  let response

  try {
    response = await axios.request(payload)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    // If we fail due to 401, then try to refresh the token
    if (err?.response?.statusText === 'Unauthorized') {
      const refreshResponse = await axios.request<RefreshResponseBody>({
        withCredentials: true,
        url: `${API_HOST}${ApiPath.REFRESH}`,
        method: 'POST',
        headers: default_headers,
      })
      // If we succeeded then store new user and try this again
      if (refreshResponse.status === 200) {
        setStorageItem(
          StorageItems.ACCESS_TOKEN,
          refreshResponse.data.accessToken,
        )
        updateUserCache(refreshResponse.data.user)
        response = await axios.request(payload)
        return response.data as T
      }
    }
    // Doing this because the app depends on the `.message` property.
    err.response.message = err.response.statusText
    throw err.response
  }

  return response.data as T
}

export const devLoadIntakeForm = async () => {
  apiFetch<UpdateUserResponse>(ApiPath.USER_PATCH, {
    method: 'PATCH',
    body: {
      formResponses: dummyIntakeForm,
    },
  })
}

export const updateUserCache = (user: Partial<GetSelfUserResponse>) => {
  queryClient.setQueryData([QueryKeyKey.VIEW_USER, user.id], user)
  queryClient.setQueryData(QueryKeyKey.SELF_USER, user)
  queryClient.setQueryData([QueryKeyKey.DIALOG_USER, user.id], user)
}

export const hasCompletedForm = (formResponses: Partial<IntakeFormData>) =>
  formResponses.done === 'true'
