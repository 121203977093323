
import { Box, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material'
import { InputWrapper, Row, Title } from 'components'
import styled from 'styled-components'
import rft_logo from 'images/rft_logo.png'
import { SubTitle } from 'components/SubTitle'
import { Button } from 'pages/IntakeFormPage/components/Button'
import { WaiverInput } from 'components/Register'
import { FieldErrors, UseFormRegister, useForm } from 'react-hook-form'
import { ErrorMessage } from 'pages/IntakeFormPage/components/ErrorMessage'
import { FormGroup } from 'pages/IntakeFormPage/components/FormGroup'
import { useMutateUserWaiver, useViewUser } from 'contexts/api/hooks'
import { Path } from 'router/constants'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import { useShowDialog } from 'contexts/dialog/Controller'

type AcceptedTerms = {
  agree1: boolean;
  agree2: boolean;
  agree3: boolean;
};

export const WaiverPage = ({
  fullName,
  register,
  errors,
  onSubmit,
  onPressBack,
}: {
  fullName?: string
  register?: UseFormRegister<WaiverInput>
  errors?: FieldErrors<WaiverInput>
  onSubmit?: () => void
  onPressBack?: () => void
}) => {

  const resolver = classValidatorResolver(WaiverInput)
  const showDialog = useShowDialog()

  const {
    register: formRegister,
    handleSubmit,
    formState: { errors: formErrors },
    getValues,
  } = useForm<WaiverInput>({ resolver, mode: 'all' })

  const [name, setName] = useState<string | undefined | null>('')
  const [signedDate, setSignedDate] = useState<string>('')
  const { mutateAsync: mutateUserWaiver } = useMutateUserWaiver()
  const errorMessage = errors?.age?.message || formErrors.age?.message;
  const [acceptedTerms, setAcceptedTerms] = useState<AcceptedTerms>({
    'agree1': false,
    'agree2': false,
    'agree3': false,
  });

  const navigate = useNavigate()
  const { user } = useViewUser()

  useEffect(() => {
    const signedDate = user?.signedWaiver ?
      DateTime.fromISO(user.signedDate.split('T'[0])[0]).setZone('UTC', { keepLocalTime: true }).toISODate() :
      new Date().toISOString().split('T')[0];

    const name = fullName ? fullName : user?.name;

    setName(name);
    setSignedDate(signedDate);
  }, [fullName, user])

  const onBack = onPressBack ? onPressBack : () => {
    navigate(`/${Path.ATHLETE}/${Path.ACCOUNT}`)
  };

  const onSubmitForm = onSubmit ? onSubmit : handleSubmit(() => {

    if (user?.id !== undefined && getValues('age') !== undefined) {
      const payload = {
        ...getValues(),
        signed: true,
      }
      mutateUserWaiver(payload)
        .then(() => {
          showDialog({
            title: 'Waiver Signed',
            subtitle: 'You have successfully signed the waiver.',
            option2Text: null,
          })
          navigate(`/${Path.ATHLETE}/${Path.ACCOUNT}`)
        })
      }
  });

  const handleChange = (name: keyof AcceptedTerms) => {
    setAcceptedTerms({
      ...acceptedTerms,
      [name]: !acceptedTerms[name],
    });
  };

  return (
    <FormGroup onSubmit={onSubmitForm} style={{ paddingBottom: 0 }}>
      <ModalContentWrapper>
        <Row justifyContent='center' style={{ marginBottom: 20 }}>
          <img src={rft_logo} style={{ height: 60 }} alt='logo' />
        </Row>
        <Title style={{ fontStyle: 'italic', fontSize: 16 }}>WAIVER AND INDEMNIFICATION AGREEMENT</Title>
        <div style={{ textAlign: 'left', marginBottom: 30 }}>
          <SubTitle style={{ fontSize: 14, fontWeight: 400, paddingBottom: 10 }}>
            In consideration of RUNFREE, LLC making available it’s staff, Coaching services, educational materials, camp
            programming and any other services, and facilities, I am duly aware of the risks and hazards inherent in participating
            in an exercise program AND nutrition program and / or on the premises, and assume all risk of injury or loss of life
            to myself and loss of or damage to property arising out of or in any way connected with my participation in such
            exercise program, and hereby release RUNFREE, LLC, and their respective agents, servants, and employees from
            any and all liability as to any right of action that may accrue either to  myself, my heirs, successors, assigns, or
            personal representatives for any such injury, loss of life, or loss of or damage to any property which I may suffer
            whatsoever.
          </SubTitle>
          <SubTitle style={{ fontSize: 14, fontWeight: 400 }}>
            I further agree to indemnify and hold harmless RUNFREE, LLC, the RUNFREE, LLC, LLC, and their respective agents,
            servants, and employees from any and all claims, demands, actions, and judgments arising at any time out of
            or in any way connected with any participation in any RUNFREE, LLC activity or nutrition program.
            I understand and appreciate that RUNFREE, LLC is a nutrition and exercise program.  I assume all risks for myself
            and my property at any time I enter the premises or participate in a fitness activity or nutrition alteration on the
            premises and at my home.
          </SubTitle>
          <InputWrapper>
            <FormControlLabel control={
              <Checkbox color="success" required checked={user?.signedWaiver || acceptedTerms.agree1} disabled={user?.signedWaiver} />
            } onChange={() => handleChange('agree1')} label="Agree" name={'agree1'} />
          </InputWrapper>
          <SubTitle style={{ fontSize: 14, fontWeight: 400, paddingBottom: 10 }}>
            I understand that I must be cleared by my medical provider prior to beginning any fitness activity or nutrition
            program and certify that I have received the approval of my medical provider prior to beginning any / any new
            fitness activity or nutrition program.  I certify that I am physically able to participate in an exercise program and
            nutrition program and will monitor my physical capacity responsibly in order to avoid potential harm.
          </SubTitle>
          <InputWrapper>
            <FormControlLabel control={
              <Checkbox color="success" required checked={user?.signedWaiver || acceptedTerms.agree2} disabled={user?.signedWaiver} />
            } onChange={() => handleChange('agree2')} label="Agree" name={'agree2'} />
          </InputWrapper>
          <SubTitle style={{ fontSize: 14, fontWeight: 400, paddingBottom: 10 }}>
            I acknowledge that exercise and nutrition programs test a person’s physical and mental limits and carries with it the
            potential for death, serious injury, and property loss.  The risks include, but are not limited to, those caused by
            terrain, facilities, condition of athletes, equipment, vehicular traffic, actions of other people, including but not
            limited to participants, volunteers, employees, personal trainers, instructors, and lack of hydration.
            I HEREBY ASSUME THE RISKS OF PARTICIPATION AND / OR VOLUNTEERING IN THE FITNESS EXERCISE PROGRAM and NUTRITION ALTERATION PROGRAM.
            I realize that liability my arise from negligence or carelessness on the part of the persons or entities being released,
            from dangerous or defective equipment or property owned, maintained, controlled, or utilized by them or because of their possible liability without fault.
            In consideration of permitting me to participate in this exercise program, I hereby take action for myself, my executors, administrators,
            heirs, next of kin, successors and assigns as follows:
          </SubTitle>
          <SubTitle style={{ fontSize: 14, fontWeight: 400, paddingBottom: 10, paddingLeft: 30 }}>
            A - WAIVE, RELEASE, AND DISCHARGE from any and all liability for my death, disability, personal injury,
            property damage, property theft or actions of any kind which may hereafter accrue to me, the following
            entities or persons: RUNFREE, LLC, the RUNFREE, LLC, LLC, their directors, officers, employees, volunteers,
            representatives, and agents
          </SubTitle>
          <SubTitle style={{ fontSize: 14, fontWeight: 400, paddingBottom: 10, paddingLeft: 30 }}>
            B - INDEMNIFY AND HOLD HARMLESS the entities or persons mentioned in this paragraph from any and all
            liabilities or claims made as a result of participation in this facility, whether caused by
            negligence or otherwise.
          </SubTitle>
          <SubTitle style={{ fontSize: 14, fontWeight: 400 }}>
            I hereby consent to receive treatment which may be deemed advisable in the event of my injury, accident,
            and / or illness during my participation in this activity.
          </SubTitle>
          <InputWrapper>
            <FormControlLabel control={
              <Checkbox color="success" required checked={user?.signedWaiver || acceptedTerms.agree3} disabled={user?.signedWaiver} />
            } onChange={() => handleChange('agree3')} label="Agree" name={'agree3'} />
          </InputWrapper>
          <SubTitle style={{ fontSize: 14, fontWeight: 400, paddingBottom: 10 }}>
            I certify that I am 18 years of age or older; I have read this document and I understand it’s content.
          </SubTitle>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent='flex-end'>
            <Grid item xs={6}>
              <InputWrapper>
                <TextField
                  style={{ width: '100%' }}
                  variant='standard'
                  id={`$name-input`}
                  name={'name'}
                  type={'text'}
                  autoComplete={'name'}
                  disabled
                  value={name}
                />
              </InputWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWrapper>
                <TextField
                  style={{ width: '100%' }}
                  variant='standard'
                  id={`$age-input`}
                  type={'text'}
                  placeholder={'Age'}
                  value={user?.age}
                  aria-describedby={errors?.age ?? formErrors.age ? `age-error` : undefined}
                  disabled={user?.signedWaiver ? true : false}
                  {...register?.('age') ? register('age') : { ...formRegister('age') }}
                />
                {errorMessage && (
                  <ErrorMessage role="alert" id="age-error" className="error-message">
                    {errorMessage}
                  </ErrorMessage>
                )}
              </InputWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWrapper>
                <TextField
                  style={{ width: '100%' }}
                  variant='standard'
                  id={`$date-input`}
                  name={'date'}
                  type={'date'}
                  autoComplete={'date'}
                  placeholder={'Date'}
                  disabled
                  value={signedDate}
                />
              </InputWrapper>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 20 }}>
            <Button variant='outlined' onClick={onBack}>
              Back
            </Button>
            {user?.signedWaiver ? null : (
              <Button variant='contained' type="submit">
                Accept
              </Button>
            )}
          </div>
        </div>
      </ModalContentWrapper>
    </FormGroup>
  )
}

const ModalContentWrapper = styled(Box)`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 90vw;
  background-color: white;
  border-radius: 10px;
  border: 2px;
  padding: 32px 60px;
  overflow-y: auto;
`