import { Icon, UtilityIcon } from 'components/Icon'
import { useCallback, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import VideoSnapshot from 'video-snapshot'

export const FeedVideo = ({ preview, isLock, noSpecialPreview }: {
  preview: {
    url: string
  },
  noSpecialPreview?: boolean
  isLock?: boolean
}) => {
  const [videoPreview, setVideoPreview] = useState('')

  const loadImageFromUrl = useCallback(async () => {
    const blob = await fetch(preview?.url).then((r) => r.blob())

    const snapshoter = new VideoSnapshot(blob)
    const previewSrc = await snapshoter.takeSnapshot()

    setVideoPreview(previewSrc)
  }, [preview?.url])

  useEffect(() => {
    if(!noSpecialPreview){
      loadImageFromUrl()
    }
  }, [preview.url, loadImageFromUrl, noSpecialPreview])

  return (
    <ReactPlayer
      url={preview?.url}
      light={videoPreview ?? ''}
      controls={isLock ?? true}
      width={'100%'}
      height={'100%'}
      playIcon={<Icon path={UtilityIcon.PLAY} />}
    />
  )
}
