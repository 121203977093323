import { Component, ComponentType, FunctionComponent } from 'react'
import CSS from 'csstype'
import { ThemeConstants } from 'contexts/theme/constants'
import { styled } from '@mui/material/styles'
import { useWindowSizes } from 'utils/hooks'
import ErrorBoundary from './ErrorBoundary'

type WrapperOptions = {
  width?: 'max-width' | 'full' // page is max width
  maxWidth?: number
  verticalCentering?: 'flex-start' | 'center' // page is max width
  ignoreHeader?: boolean
}

// https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb
export const withPageWrapper = <P extends Record<string, never>>(
  Comp: ComponentType<P>,
  options?: WrapperOptions,
  wrapperStyleAugment?: CSS.Properties<string | number>,
): ComponentType<P> => {
  return class WithScreen extends Component<P> {
    render() {
      const Wrapper =
        options?.width === 'full' ? FullWidthPageWrapper : MaxWidthPageWrapper
      const verticalCenteringStyle = {
        justifyContent: options?.verticalCentering ?? 'flex-start',
      }
      return (
        <ErrorBoundary file={Comp.displayName ?? 'Unknown'}>
          <WindowSizes>
            {({ showSidebar, showHeader }) => (
              <Wrapper
                showSidebar={showSidebar}
                showHeader={showHeader}
                ignoreHeader={options?.ignoreHeader}
                maxWidth={options?.maxWidth}
                style={{ ...verticalCenteringStyle, ...wrapperStyleAugment }}>
                <Comp {...this.props} />
              </Wrapper>
            )}
          </WindowSizes>
        </ErrorBoundary>
      )
    }
  }
}

const MaxWidthPageWrapper = styled('div', {
  shouldForwardProp: (p) =>
    p !== 'maxWidth' &&
    p !== 'showSidebar' &&
    p !== 'showHeader' &&
    p !== 'ignoreHeader',
})<{
  maxWidth?: number
  showSidebar: boolean
  showHeader: boolean
  ignoreHeader?: boolean
}>`
  height: calc(
    100vh -
      ${(p) =>
        p.showHeader && !p.ignoreHeader ? ThemeConstants.NAV_BAR_HEIGHT : 0}px
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 320px;
  // Use padding and content-box to ensure scrollbar is at rightmost side
  padding-top: 0;
  padding-bottom: 0;
  padding-left: max(
    50vw + ${(p) => (p.showSidebar ? ThemeConstants.SIDEBAR_WIDTH : 0)}px -
      ${(p) => (p.maxWidth ?? ThemeConstants.SCREEN_MAX_WIDTH) / 2}px,
    ${(p) => (p.showSidebar ? ThemeConstants.SIDEBAR_WIDTH : 0)}px,
    1rem
  );
  padding-right: max(
    50vw - ${(p) => (p.maxWidth ?? ThemeConstants.SCREEN_MAX_WIDTH) / 2}px,
    1rem
  );
  box-sizing: content-box;
  max-width: ${(p) => p.maxWidth ?? ThemeConstants.SCREEN_MAX_WIDTH}px;
  overflow-y: auto;
`

const FullWidthPageWrapper = styled('div', {
  shouldForwardProp: (p) =>
    p !== 'maxWidth' &&
    p !== 'showSidebar' &&
    p !== 'showHeader' &&
    p !== 'ignoreHeader',
})<{
  maxWidth?: number
  showSidebar: boolean
  showHeader: boolean
  ignoreHeader?: boolean
}>`
  height: calc(
    100vh -
      ${(p) =>
        p.showHeader && !p.ignoreHeader ? ThemeConstants.NAV_BAR_HEIGHT : 0}px
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 320px;
  // Use padding and content-box to ensure scrollbar is at rightmost side
  box-sizing: border-box;
  overflow-y: auto;
`

type WindowSizesChildren = (
  windowSizes: ReturnType<typeof useWindowSizes>,
) => JSX.Element

interface IScreenWidthProps {
  children: WindowSizesChildren
}

export const WindowSizes: FunctionComponent<IScreenWidthProps> = ({
  children,
}) => {
  const windowSizes = useWindowSizes()

  return children(windowSizes)
}
