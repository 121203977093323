import { IconButton, styled } from "@mui/material";

export const IconButtonWrapper = styled(IconButton)`
	opacity: 0.5;
	
	&:hover {
		opacity: 1;
	}
`
export const IconWrapper = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.7);
		opacity: 0.3;
	}
`
