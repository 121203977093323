import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
  useReducer,
} from 'react'
import { INITIAL_STATE } from './constants'
import { reducer } from './reducer'
import { Action, DialogState } from './types'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

const DispatchContext = createContext<Dispatch<Action>>(() => undefined)

export const useDispatch = () => useContext(DispatchContext)
export const useShowDialog = () => {
  const dispatchDialog = useDispatch()
  const showDialog = useCallback(
    (payload: Omit<DialogState, 'open'>) =>
      dispatchDialog({
        type: 'showDialog',
        payload,
      }),
    [dispatchDialog],
  )
  return showDialog
}

const Controller = ({ children }: { children: ReactNode }) => {
  const [data, reduceData] = useReducer(reducer, INITIAL_STATE)
  const {
    open,
    title,
    subtitle,
    onOption1,
    onOption2,
    option1Text = 'Ok',
    option2Text = 'Cancel',
  } = data

  const handleClick = useCallback(
    (option: '1' | '2') => () => {
      if (option === '1') {
        onOption1 && onOption1()
      } else if (option === '2') {
        onOption2 && onOption2()
      }
      reduceData({ type: 'closeDialog' })
    },
    [onOption1, onOption2],
  )

  return (
    <DispatchContext.Provider value={reduceData}>
      {children}
      <StyledDialog open={open} aria-labelledby='interim-user-dialog-title'>
        <DialogContent dividers>
          <Typography variant='h6'>{title}</Typography>
          <Typography gutterBottom>{subtitle}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClick('1')} color='primary'>
            {option1Text}
          </Button>
          {option2Text === null || option2Text === false ? null : (
            <Button onClick={handleClick('2')} variant='outlined'>
              {option2Text}
            </Button>
          )}
        </DialogActions>
      </StyledDialog>
    </DispatchContext.Provider>
  )
}

const StyledDialog = styled(Dialog)({
  '.MuiPaper-root': {
    backgroundColor: 'white',
  },
})

export default Controller
