import { useTheme } from '@mui/material/styles'
import {
  ClipboardText,
  Door,
  DotsThreeOutlineVertical,
  Eye,
  EyeSlash,
  File,
  FlagCheckered,
  IconProps,
  Lock,
  Notebook,
  Paperclip,
  PaperPlaneRight,
  PencilSimple,
  PlayCircle,
  PushPin,
  PushPinSimple,
  ShoppingCartSimple,
  SquaresFour,
  Tag,
  UserCircle,
  UserList,
  X,
  VideoCamera,
  FileVideo,
  LinkSimpleHorizontal,
  Trash
} from 'phosphor-react'
import { Path } from 'router/constants'
import { ICON_SIZE } from 'utils/config'

import { ReactComponent as InternalIcon } from '../assets/internal.svg'
import { ReactComponent as NutritionIcon } from '../assets/nutrition.svg'
import { ReactComponent as OnePercentIcon } from '../assets/onepercent.svg'
import { ReactComponent as RestIcon } from '../assets/rest.svg'
import { ReactComponent as TrainingIcon } from '../assets/training.svg'

export const enum UtilityIcon {
  ATTACH = 'attach',
  CART = 'cart',
  CLEAR = 'clear',
  EDIT = 'edit',
  EYE = 'eye',
  EYE_OFF = 'eye-off',
  MENU = 'menu',
  PLAY = 'play',
  SEND = 'send',
  FLAG = 'flag',
  FILE = 'file',
  PUSHPIN = 'push-pin',
  PUSHPINSIMPLE = 'push-pin-simple',
  LOCK = 'lock',
  VIDEO = 'video',
  LINK = 'link',
  LINK_FILL = 'link-fill',
  TRASH = 'trash',
}

export function Icon({
  path,
  ...rest
}: { path: Path | UtilityIcon } & IconProps) {
  // console.debug('rerender icon')
  const theme = useTheme()

  const def: IconProps = {
    weight: 'duotone',
    size: ICON_SIZE,
    fill: theme.palette.text.primary,
    // fill: theme.palette.primary.main,
  }

  switch (path) {
    case Path.DASHBOARD:
      return (
        <SquaresFour
          {...def}
          alt='Dashboard'
          weight='fill'
          fill={rest.color}
          {...rest}
        />
      )
    case Path.TRAINING:
      return <TrainingIcon width={30} height={30} fill={rest.color} />
    case Path.NUTRITION:
      return <NutritionIcon width={30} height={30} fill={rest.color} />
    case Path.ONE_PERCENT:
      return (
        <OnePercentIcon
          width={30}
          height={30}
          fill={rest.color}
          stroke={rest.color}
        />
      )
    case Path.ONE_PERCENT_SPLIT2:
      return <OnePercentIcon width={30} height={30} fill={rest.color} />
    case Path.REST:
      return (
        <RestIcon
          width={30}
          height={30}
          fill={rest.color}
          stroke={rest.color}
        />
      )
    case Path.INTERNAL_GAME:
      return <InternalIcon width={30} height={30} fill={rest.color} />

    case Path.COACH_RELATIONS:
      return (
        <UserList {...def} alt='Coach Relations' weight='regular' {...rest} />
      )
    case Path.USER_RELATIONS:
      return (
        <UserList {...def} alt='User Relations' weight='regular' {...rest} />
      )
    case Path.INTAKE_FORM:
      return (
        <ClipboardText
          {...def}
          alt='Intake'
          weight='regular'
          fill=''
          {...rest}
        />
      )
    case Path.SUBSCRIPTION:
      return <Tag {...def} alt='Subscription' weight='regular' {...rest} />
    case Path.ACCOUNT:
      return (
        <UserCircle {...def} alt='Account' weight='regular' fill='' {...rest} />
      )
    case Path.ABOUT:
      return (
        <Notebook {...def} alt='About' weight='regular' fill='' {...rest} />
      )
    case Path.LOGOUT:
      return <Door {...def} alt='Logout' weight='regular' fill='' {...rest} />
    case Path.VIDEOS:
      return (
        <VideoCamera
          {...def}
          alt='Video'
          weight='regular'
          fill=''
          {...rest}
        />
      )
    case UtilityIcon.EYE:
      return <Eye {...def} alt='Eye' weight='regular' size={24} {...rest} />
    case UtilityIcon.EYE_OFF:
      return (
        <EyeSlash
          {...def}
          alt='EyeSlash'
          weight='regular'
          size={24}
          fill=''
          {...rest}
        />
      )
    case UtilityIcon.EDIT:
      return (
        <PencilSimple
          {...def}
          alt='Edit'
          weight='regular'
          fill=''
          size={27}
          {...rest}
        />
      )
    case UtilityIcon.FILE:
      return <File {...def} alt='File' weight='regular' size={24} {...rest} />
    case UtilityIcon.SEND:
      return (
        <PaperPlaneRight
          {...def}
          alt='Send'
          weight='regular'
          fill=''
          size={25}
          {...rest}
        />
      )
    case UtilityIcon.ATTACH:
      return (
        <Paperclip
          {...def}
          alt='Attach'
          weight='regular'
          fill=''
          size={25}
          {...rest}
        />
      )
    case UtilityIcon.FLAG:
      return (
        <FlagCheckered
          {...def}
          alt='Attach'
          weight='duotone'
          fill=''
          size={25}
          {...rest}
        />
      )
    case UtilityIcon.CLEAR:
      return (
        <X {...def} alt='Clear' weight='regular' fill='' size={25} {...rest} />
      )
    case UtilityIcon.PLAY:
      return (
        <PlayCircle {...def} alt='Play' weight='regular' fill='' {...rest} />
      )
    case UtilityIcon.CART:
      return (
        <ShoppingCartSimple
          {...def}
          alt='Cart'
          weight='duotone'
          fill=''
          {...rest}
        />
      )
    case UtilityIcon.MENU:
      return (
        <DotsThreeOutlineVertical
          {...def}
          alt='Menu'
          weight='duotone'
          size={24}
          fill=''
          {...rest}
        />
      )
    case UtilityIcon.PUSHPIN:
      return (
        <PushPin
          {...def}
          alt='UnPin'
          weight='fill'
          fill=''
          {...rest}
        />
      )
    case UtilityIcon.PUSHPINSIMPLE:
      return (
        <PushPinSimple
          {...def}
          alt='Pin'
          weight='fill'
          fill=''
          {...rest}
        />
      )
    case UtilityIcon.LOCK:
      return (
        <Lock
          {...def}
          alt='Lock'
          weight='fill'
          fill=''
          {...rest}
        />
      )
    case UtilityIcon.VIDEO:
      return (
        <FileVideo
          {...def}
          alt='Video'
          weight='regular'
          fill=''
          {...rest}
        />
      )
    case UtilityIcon.LINK:
      return (
        <LinkSimpleHorizontal
          {...def}
          alt='Link'
          weight='regular'
          fill=''
          {...rest}
        />
      )
    case UtilityIcon.LINK_FILL:
      return (
        <LinkSimpleHorizontal
          {...def}
          alt='Link Fill'
          weight='bold'
          fill=''
          {...rest}
        />
      )
    case UtilityIcon.TRASH:
      return (
        <Trash
          {...def}
          alt='Delete'
          weight='regular'
          fill=''
          {...rest}
        />
      )
    default:
      return null
  }
}
