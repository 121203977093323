import {
  AllFeedPost,
  BaseCreateFeedVariables,
  BaseCreateVideoVariables,
  CreateFeedVariables,
  CreateRelationshipVariable,
  DeleteUserVariables,
  FeedItem,
  GetFeedResponse,
  GetRelationshipsById,
  GetSelfRelationships,
  GetSelfUserResponse,
  GetUserByIdResponse,
  GetUserByRoleResponse,
  GetVideoResponse,
  OverrideUserByIdVariables,
  RemoveRelationshipVariable,
  Role,
  UpdatePinVideoById,
  UpdateUserByIdVariables,
  UpdateUserResponse,
  UpdateUserWaiver,
  VideoItem,
} from 'contexts/api/types'
import { useAuthStore } from 'contexts/auth/Controller'
import { useShowDialog } from 'contexts/dialog/Controller'
import { useDispatchSnackbar } from 'contexts/snackbar'
import { UserId, useUiStore } from 'contexts/ui/Controller'
import { IntakeFormData } from 'pages/IntakeFormPage/constants'
import { useEffect, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Relationships } from 'router/constants'
import {
  ApiPath,
  DialogUserQueryKey,
  FeedQueryKey,
  QueryKeyKey,
  RelationshipsQueryKey,
  UsersByRoleQueryKey,
  ViewUserQueryKey,
} from 'utils/config'
import { StorageItems, useStorage } from 'utils/storage'
import { NestError } from 'utils/types'
import { apiFetch, hasCompletedForm } from './functions'
import { BraintreeQueryKeys } from 'contexts/braintree/hooks'

export const getSelfUser = async (): Promise<GetSelfUserResponse> =>
  apiFetch<GetSelfUserResponse>(ApiPath.USER_GET_SELF).catch((err) => {
    console.error('getSelfUser error', err)
    throw err
  })

export const getUserById = async (id: UserId) =>
  apiFetch<GetUserByIdResponse>(`${ApiPath.USER_GET_BY_ID}/${id}`)

export const useDialogUser = (id: number | undefined) => {
  const { data: user, ...rest } = useQuery<
    GetUserByIdResponse | undefined,
    Error,
    GetUserByIdResponse | undefined,
    DialogUserQueryKey
  >(
    [QueryKeyKey.DIALOG_USER, id],
    async () => (id ? getUserById(id) : undefined),
    {
      enabled: !!id,
    },
  )

  const dialogUserData = {
    user,
    id: user?.id,
    formResponses: user?.formResponses,
    email: user?.email,
    phone: user?.phoneNumber,
    ...rest,
  }

  // console.debug('useViewUser', viewUserData)
  return dialogUserData
}

export const useViewUser = () => {
  const queryClient = useQueryClient()
  const { role, id } = useSelfUser()
  const { viewUserId } = useUiStore()
  const userId = role === Role.USER ? id : viewUserId
  const { data: user, ...rest } = useQuery<
    GetUserByIdResponse | undefined,
    Error,
    GetUserByIdResponse | undefined,
    ViewUserQueryKey
  >(
    [QueryKeyKey.VIEW_USER, userId],
    async () => (userId ? getUserById(userId) : undefined),
    {
      enabled: !!userId,
      onSuccess: (userData) => {
        // console.debug('useViewUser onSuccess')
        if (role === Role.USER) {
          const selfUserData = queryClient.getQueryData(QueryKeyKey.SELF_USER)
          if (selfUserData !== userData) {
            console.log('Updating Self User data from in View User data')
            queryClient.setQueryData(QueryKeyKey.SELF_USER, userData)
          }
        }
      },
    },
  )

  const viewUserData = {
    user,
    id: user?.id,
    formResponses: user?.formResponses,
    email: user?.email,
    phoneNumber: user?.phoneNumber,
    name: user?.name,
    firstName: user?.firstName,
    lastName: user?.lastName,
    isSelf: user?.id === id,
    subscription: user?.subscription,
    manualOverride: user?.manualOverride,
    ...rest,
  }

  // console.debug(
  //   'useViewUser',
  //   [QueryKeyKey.VIEW_USER, userId],
  //   viewUserData.user?.formResponses.caffine_1,
  //   viewUserData,
  // )
  return viewUserData
}

export const useSelfUser = () => {
  const { selfUser: user, isLoading, isLoggedIn } = useAuthStore()

  const selfUserData = useMemo(
    () => ({
      user,
      id: user?.id,
      formResponses: user?.formResponses,
      role: user?.role ?? Role.USER,
      email: user?.email,
      manualOverride: user?.manualOverride,
      isLoading,
      isLoggedIn,
      isDoneWithIntakeForm: hasCompletedForm(user?.formResponses ?? {}),
    }),
    [isLoading, isLoggedIn, user],
  )
  return selfUserData
}

export const useMutateIntakeForm = () => {
  const queryClient = useQueryClient()
  const dispatchSnackBar = useDispatchSnackbar()

  const mutation = useMutation<
    UpdateUserResponse,
    Error,
    Partial<IntakeFormData>
  >(
    async (formResponses) => {
      const body = {
        // id,  // TODO PUBLISH: Need to accept an ID to edit a different user
        formResponses,
      } // Mutates the view user
      return await apiFetch<UpdateUserResponse>(ApiPath.USER_PATCH, {
        method: 'PATCH',
        body,
      })
    },
    {
      onSuccess: (userResponse) => {
        // TODO change relationships type for PATCH response
        const userData = {
          ...userResponse,
          relationships: [],
          updatedAt: '',
          createdAt: '',
        }
        queryClient.setQueriesData<GetUserByIdResponse>(
          [QueryKeyKey.VIEW_USER, userResponse.id],
          userData,
        )
        queryClient.setQueriesData<GetUserByIdResponse>(
          QueryKeyKey.SELF_USER,
          userData,
        )
        queryClient.setQueriesData<GetUserByIdResponse>(
          [QueryKeyKey.DIALOG_USER, userResponse.id],
          userData,
        )
        // updateUserQueryData(id, {
        //   ...userResponse,
        //   relationships: [],
        //   createdAt: '',
        //   updatedAt: '',
        // })
        dispatchSnackBar({
          type: 'showSnackbar',
          payload: { message: `Successfully saved form data` },
        })
      },
      onError: (error) => {
        dispatchSnackBar({
          type: 'showSnackbar',
          payload: { message: `Unable to save data ${error}`, type: 'Error' },
        })
      },
    },
  )

  return mutation
}

const getFeed = (type: Relationships, id: UserId | undefined) =>
  id === undefined
    ? apiFetch<GetFeedResponse>(`${ApiPath.FEED_GET}?category=${type}`)
    : apiFetch<GetFeedResponse>(`${ApiPath.FEED_GET}/${id}?category=${type}`)

export const useFeed = (type: Relationships, id: UserId | undefined) => {
  const token = useStorage(StorageItems.ACCESS_TOKEN)
  const { data: feed, ...rest } = useQuery<
    GetFeedResponse | undefined,
    NestError,
    GetFeedResponse | undefined,
    FeedQueryKey
  >([QueryKeyKey.FEED, id, type], async () => getFeed(type, id), {
    enabled: !!token,
  })
  // console.debug('useFeed', type, feed)
  return { feed, ...rest }
}

export const useMutateFeed = () => {
  // const { user } = useViewUser()
  const queryClient = useQueryClient()

  const mutation = useMutation<FeedItem, Error, CreateFeedVariables>(
    async (body) => {
      return await apiFetch<FeedItem>(ApiPath.FEED_CREATE, {
        method: 'POST',
        body,
      })
    },
    {
      onSuccess: () => {
        // console.log('useMutateFeed onSuccess', user?.id, variables.category)
        queryClient.invalidateQueries({
          predicate: (key) => key.queryKey.includes(QueryKeyKey.FEED),
        })
        //   QueryKeyKey.FEED,
        //   user?.id,
        //   Relationships.GENERAL,
        // ]) // Should invalidate all feed types
        // queryClient.invalidateQueries([
        //   QueryKeyKey.FEED,
        //   user?.id,
        //   variables.category,
        // ]) // Should invalidate all feed types
      },
    },
  )

  return mutation
}

export const useMutateAllFeeds = () => {
  const dispatchSnackbar = useDispatchSnackbar()

  const queryClient = useQueryClient()
  const mutation = useMutation<AllFeedPost, Error, BaseCreateFeedVariables>(
    async (body) => {
      return await apiFetch<AllFeedPost>(ApiPath.FEED_CREATE_ALL_ATHLETES, {
        method: 'POST',
        body,
      })
    },
    {
      onSuccess: (resp) => {
        queryClient.invalidateQueries({
          predicate: (key) => key.queryKey.includes(QueryKeyKey.FEED),
        }) // Should invalidate all feed types
        dispatchSnackbar({
          type: 'showSnackbar',
          payload: {
            message: `Message sent to ${resp.messagesSent} feeds`,
            timeout: 4000,
          },
        })
      },
    },
  )

  return mutation
}

const getVideos = () => apiFetch<GetVideoResponse>(ApiPath.VIDEO_GET)

export const useVideos = () => {
  const { data: videos, refetch, ...rest } = useQuery<
    GetVideoResponse | undefined,
    NestError,
    GetVideoResponse | undefined
  >([QueryKeyKey.VIDEO], async () => getVideos(), {
    enabled: true,
  })

  useEffect(() => {
    refetch()
  }, [refetch])

  return { videos, ...rest }
}

export const useMutateVideo = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation<VideoItem, Error, BaseCreateVideoVariables>(
    async (body) => {
      return await apiFetch<VideoItem>(ApiPath.VIDEO_CREATE, {
        method: 'POST',
        body,
      })
    },
    {
      onSuccess: () => {
        console.log('useMutateVideo onSuccess')

        queryClient.invalidateQueries({
          predicate: (key) => key.queryKey.includes(QueryKeyKey.VIDEO),
        })
      }
    }
  )

  return mutation
}

export const useMutatePinVideo = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation<UpdatePinVideoById, Error, UpdatePinVideoById>(
    ({ id, isPinnedInternal, isPinnedNutrition, isPinnedOnePercent, isPinnedRest, isPinnedTraining }) => {
      const body = {
        isPinnedInternal,
        isPinnedNutrition,
        isPinnedOnePercent,
        isPinnedRest,
        isPinnedTraining
      }
      return apiFetch(`${ApiPath.VIDEO_PIN}/${id}`, {
        method: 'PATCH',
        body: body
      })
    },
    {
      onSuccess: () => {
        console.log('useMutatePinVideo onSuccess')

        queryClient.invalidateQueries({
          predicate: (key) =>
            key.queryKey.includes(QueryKeyKey.VIDEO) ||
            key.queryKey.includes(QueryKeyKey.SELF_USER) ||
            key.queryKey.includes(BraintreeQueryKeys.SUBSCRIPTIONS)
        })
      }
    }
  )

  return mutation
}

export const useMutateDeleteVideo = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation<number, Error, number>(
    (id) => {
      console.log("useMutateDeleteVideo", id)
      return apiFetch(`${ApiPath.VIDEO_DELETE}/${id}`, {
        method: 'DELETE',
      })
    },
    {
      onSuccess: () => {
        console.log('useMutateDeleteVideo onSuccess')

        queryClient.invalidateQueries({
          predicate: (key) => key.queryKey.includes(QueryKeyKey.VIDEO),
        })
      }
    }
  )

  return mutation
}

export const useMutateUserWaiver = () => {
  const queryClient = useQueryClient()
  const { id } = useSelfUser()
  const mutation = useMutation<UpdateUserWaiver, Error, UpdateUserWaiver>(
    (body) => {
      return apiFetch(`${ApiPath.USER_SIGNED_WAIVER}/${id}`, {
        method: 'PATCH',
        body: body
      })
    },
    {
      onSuccess: () => {
        console.log('useMutateUserWaiver onSuccess')

        queryClient.invalidateQueries({
          predicate: (key) =>
            key.queryKey.includes(QueryKeyKey.WAIVER) ||
            key.queryKey.includes(QueryKeyKey.SELF_USER) ||
            key.queryKey.includes(QueryKeyKey.VIEW_USER)
        })
      }
    }
  )

  return mutation
}

const getUserByRole = async (role: Role) =>
  apiFetch<GetUserByRoleResponse>(`${ApiPath.USER_GET_BY_ROLE}/${role}`)

export const useUsersByRole = (role: Role) => {
  const { role: userRole } = useSelfUser()
  const token = useStorage(StorageItems.ACCESS_TOKEN)
  const enabled = !!token && userRole === Role.ADMIN
  const {
    data: users,
    refetch,
    ...rest
  } = useQuery<
    GetUserByRoleResponse | undefined,
    Error,
    GetUserByRoleResponse | undefined,
    UsersByRoleQueryKey
  >([QueryKeyKey.USERS_LIST, role], async () => getUserByRole(role), {
    enabled,
  })

  useEffect(() => {
    refetch()
  }, [enabled, refetch])

  console.log('getUserByRole enabled', !!token, userRole === Role.ADMIN, users)

  return { users: users ?? [], ...rest }
}

const getSelfRelationships = () =>
  apiFetch<GetSelfRelationships>(ApiPath.RELATIONSHIPS_GET_SELF)

const getRelationshipsById = (id: number) =>
  apiFetch<GetRelationshipsById>(`${ApiPath.RELATIONSHIPS_GET_BY_ID}/${id}`)

export const useSelfRelationships = () => {
  const { id } = useSelfUser()
  const token = useStorage(StorageItems.ACCESS_TOKEN)
  const { data: relationships, ...rest } = useQuery<
    GetSelfRelationships | undefined,
    Error,
    GetSelfRelationships | undefined,
    RelationshipsQueryKey
  >([QueryKeyKey.RELATIONSHIPS, id], async () => getSelfRelationships(), {
    enabled: !!token,
  })
  return { relationships: relationships ?? [], ...rest }
}

export const useRelationshipsById = (id: number | undefined) => {
  const token = useStorage(StorageItems.ACCESS_TOKEN)
  const { data: relationships, ...rest } = useQuery<
    GetRelationshipsById | undefined,
    Error,
    GetRelationshipsById | undefined,
    RelationshipsQueryKey
  >(
    [QueryKeyKey.RELATIONSHIPS, id],
    async () => (id ? getRelationshipsById(id) : undefined),
    {
      enabled: !!token && !!id,
    },
  )
  return { relationships: relationships ?? [], ...rest }
}

export const useAllUsers = () => {
  const { users: admins, isLoading: adminsLoading } = useUsersByRole(Role.ADMIN)
  const { users: coaches, isLoading: coachesLoading } = useUsersByRole(
    Role.COACH,
  )
  const { users, isLoading: usersLoading } = useUsersByRole(Role.USER)

  const isLoading = adminsLoading || coachesLoading || usersLoading
  const allUsers = isLoading
    ? []
    : [...(admins ?? []), ...(coaches ?? []), ...(users ?? [])]
  return { allUsers, isLoading }
}

export const useCreateRelationships = () => {
  const showDialog = useShowDialog()
  const queryClient = useQueryClient()

  const mutation = useMutation<unknown, Error, CreateRelationshipVariable[]>(
    (relationships) => {
      return Promise.all(
        relationships.map((body) =>
          apiFetch(ApiPath.RELATIONSHIP_CREATE, {
            method: 'POST',
            body,
          }),
        ),
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeyKey.DIALOG_USER)
        queryClient.invalidateQueries(QueryKeyKey.VIEW_USER)
        queryClient.invalidateQueries(QueryKeyKey.RELATIONSHIPS)
        queryClient.invalidateQueries([QueryKeyKey.USERS_LIST])
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        if (
          err.statusCode === 400 &&
          err.message === 'Relationship already exists!'
        ) {
          showDialog({
            title: 'Relationship Error',
            subtitle: 'This relationship already exists',
            option2Text: null,
          })
        }
      },
    },
  )

  return mutation
}

export const useRemoveRelationships = () => {
  const showDialog = useShowDialog()
  const queryClient = useQueryClient()

  const mutation = useMutation<unknown, Error, RemoveRelationshipVariable[]>(
    (relationships) => {
      return Promise.all(
        relationships.map((body) =>
          apiFetch(ApiPath.RELATIONSHIP_CREATE, {
            method: 'DELETE',
            body,
          }),
        ),
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeyKey.DIALOG_USER)
        queryClient.invalidateQueries(QueryKeyKey.VIEW_USER)
        queryClient.invalidateQueries(QueryKeyKey.RELATIONSHIPS)
        queryClient.invalidateQueries([QueryKeyKey.USERS_LIST])
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        if (
          err.statusCode === 400 &&
          err.message === 'Relationship already exists!'
        ) {
          showDialog({
            title: 'Relationship Error',
            subtitle: 'This relationship already exists',
            option2Text: null,
          })
        }
      },
    },
  )

  return mutation
}

export const useChangeRole = () => {
  const queryClient = useQueryClient()
  const showDialog = useShowDialog()

  const mutation = useMutation<
    UpdateUserByIdVariables,
    Error,
    UpdateUserByIdVariables
  >(
    ({ user, id }) => {
      return apiFetch(`${ApiPath.USER_PATCH_BY_ID}/${id}`, {
        method: 'PATCH',
        body: user,
      })
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeyKey.DIALOG_USER)
        queryClient.invalidateQueries(QueryKeyKey.VIEW_USER)
        queryClient.invalidateQueries(QueryKeyKey.RELATIONSHIPS)
        queryClient.invalidateQueries([QueryKeyKey.USERS_LIST])
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        if (err.statusCode === 400 && err.message === 'Unable to edit user!') {
          showDialog({
            title: 'Edit user error',
            subtitle: 'Unable to edit user',
            option2Text: null,
          })
        }
      },
    },
  )
  return mutation
}

// export const useUpdateUserQueryData = () => {
//   const queryClient = useQueryClient()

//   const updateUserQueryData = useCallback(
//     (id: number | undefined, data: QueryDataTypes['user']) => {
//       queryClient.updateQueryCache<QueryKeyKey.VIEW_USER>(
//         [QueryKeyKey.VIEW_USER, id],
//         data,
//       )
//       queryClient.updateQueryCache<QueryKeyKey.SELF_USER>(
//         [QueryKeyKey.SELF_USER, id],
//         data,
//       )
//       queryClient.updateQueryCache<QueryKeyKey.DIALOG_USER>(
//         [QueryKeyKey.DIALOG_USER, id],
//         data,
//       )
//     },
//     [queryClient],
//   )

//   return updateUserQueryData
// }

export const useDeleteUser = () => {
  // const { user } = useViewUser()
  const queryClient = useQueryClient()

  const mutation = useMutation<undefined, Error, DeleteUserVariables>(
    async ({ id }) => {
      return await apiFetch<undefined>(`${ApiPath.USER_DELETE}/${id}`, {
        method: 'DELETE',
      })
    },
    {
      onSuccess: () => {
        // console.log('useMutateFeed onSuccess', user?.id, variables.category)
        queryClient.invalidateQueries({
          predicate: (key) =>
            key.queryKey.includes(QueryKeyKey.USERS_LIST) ||
            key.queryKey.includes(QueryKeyKey.USER_BASE),
        })
        //   QueryKeyKey.FEED,
        //   user?.id,
        //   Relationships.GENERAL,
        // ]) // Should invalidate all feed types
        // queryClient.invalidateQueries([
        //   QueryKeyKey.FEED,
        //   user?.id,
        //   variables.category,
        // ]) // Should invalidate all feed types
      },
    },
  )

  return mutation
}

export const useManualOverride = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation<unknown, Error, OverrideUserByIdVariables>(
    async ({ id, user }) => {
      console
      return apiFetch(`${ApiPath.USER_PATCH_BY_ID}/${id}`, {
        method: 'PATCH',
        body: user,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (key) =>
            key.queryKey.includes(QueryKeyKey.VIEW_USER) ||
            key.queryKey.includes(QueryKeyKey.USERS_LIST) ||
            key.queryKey.includes(QueryKeyKey.DIALOG_USER),
        })
      },
    },
  )

  return mutation
}
