import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { withPageWrapper } from 'components/withScreenWrapper'

const AboutPage = () => {
  return (
    <div style={{ paddingBottom: 30, paddingTop: 30 }}>
      <Typography
        variant='h3'
        component='div'
        textAlign={'center'}
        fontWeight={700}>
        Frequently Asked Questions
      </Typography>
      <Stack p={2} mt={4}>
        <Typography variant='h5' style={{ paddingTop: 20 }} fontWeight={600}>
          How do I use the Athlete Portal?
        </Typography>
        The athlete portal is a platform to help you set goals, track your
        progress, and interact with your coaches. Once you start your
        subscription, you will have access to your assigned coach for weekly
        training plans, a Nutritionist Coach for consulting, and our ‘1%’ coach
        for a running form analysis and mobility support.
        <Typography variant='h5' style={{ paddingTop: 20 }} fontWeight={600}>
          How do I get started with online coaching?
        </Typography>
        After completing payment, this will begin your onboarding process that
        takes approximately 10 days. You will be guided to complete the Athlete
        Intake Questionnaire which will be assessed by our Executive Coach, Ryan
        Hall. He will then assign you to one of our coaches that best fits your
        goals.
        <Typography variant='h5' style={{ paddingTop: 20 }} fontWeight={600}>
          How much does online coaching cost?
        </Typography>
        We have one standard price of $199.99 per month with a 6 month minimum
        commitment. Sign up for 12 months and get your first month free.
        <Typography variant='h5' style={{ paddingTop: 20 }} fontWeight={600}>
          What is the Athlete Intake Questionnaire?
        </Typography>
        The Athlete Intake Questionnaire is a thorough examination of your
        experience within each of the ‘Holistic Five’ principles. This helps us
        take an in-depth look at your running history, set specific holistic
        goals, and use this information to guide you to optimal performance.
        <Typography variant='h5' style={{ paddingTop: 20 }} fontWeight={600}>
          How do I receive a running form analysis?
        </Typography>
        Once you subscribe as an athlete, you will be guided to submit videos of
        yourself running on a treadmill for a review. This will help identify
        any inefficiencies in your running form and prescribe workouts to
        increase speed and/or prevent injury.
        <Typography variant='h5' style={{ paddingTop: 20 }} fontWeight={600}>
          Can I receive a running form analysis if I am not a subscriber?
        </Typography>
        Yes! We offer running form analyses to anyone for a one-time fee of
        $199.99. This will include a review of your running form, cues to improve
        efficiency, and prescribed workouts to help counter inefficiencies that
        could lead to injury.
        <Typography variant='h5' style={{ paddingTop: 20 }} fontWeight={600}>
          What should I expect from my coach?
        </Typography>
        Your coach will assign weekly training plans through Training Peaks
        (downladable app), be available to give real time feedback or
        adjustments as needed, and consult on race day strategies. You and your
        coach will decide the best way to communicate, whether through email,
        text, zoom calls, etc.
        <Typography variant='h5' style={{ paddingTop: 20 }} fontWeight={600}>
          What is the ‘Holistic Five’?
        </Typography>
        We have coined the term ‘Holistic Five’ to represent our method of
        coaching through five holistic principles including Training, Nutrition,
        Internal Game, Rest, and the ‘1%’.
        <Typography variant='h5' style={{ paddingTop: 20 }} fontWeight={600}>
          What is the 1%?
        </Typography>
        The ‘1%’ is one of our ‘Holistic Five’ principles that describes all of
        the little things you do to gain a marginal impact on your performance.
        This includes cross training, mobility, and more.
        <Typography variant='h5' style={{ paddingTop: 20 }} fontWeight={600}>
          For any additional questions, please reach out to{' '}
          <a
            target='_blank'
            href='mailto:support@runfreetraining.com'
            rel='noreferrer'>
            support@runfreetraining.com
          </a>
        </Typography>
      </Stack>
    </div>
  )
}

export default withPageWrapper(
  AboutPage,
  { width: 'max-width' },
  { alignItems: 'stretch' },
)
