import { Role } from 'contexts/api/types'

export enum Relationships {
  TRAINING = 'training',
  NUTRITION = 'nutrition',
  ONE_PERCENT = 'one-percent',
  REST = 'rest',
  INTERNAL_GAME = 'internal-game',
  GENERAL = 'general', // Needed for Feed and Relationship types
}

export enum Path {
  HOME = '',
  AUTH = 'access',
  LOGIN = 'login',
  LOGOUT = 'logout',
  RESET_PASSWORD = 'reset-password',
  REGISTER = 'register',
  INTAKE_FORM = 'intake-form',
  INTAKE_FORM_COMPLETE = 'complete',
  ATHLETE = 'athlete',
  SETTINGS = 'settings',
  NOTIFICATIONS = 'notifications',
  ACCOUNT = 'account-info',
  COACH_LANDING = 'coach-landing',
  SUBSCRIPTION = 'subscription',
  SPECIAL_SUBSCRIPTION = 'special-subscription',
  COACH_RELATIONS = 'coach-relationships',
  USER_RELATIONS = 'user-relationships',

  DASHBOARD = 'dashboard',
  TRAINING = 'training',
  NUTRITION = 'nutrition',
  ONE_PERCENT = 'one-percent',
  REST = 'rest',
  INTERNAL_GAME = 'internal-game',
  GENERAL = 'general', // Needed for Feed and Relationship types
  ABOUT = 'about',
  VIDEOS = 'videos',

  ONE_PERCENT_SPLIT2 = 'one-percent-2',
  TRAINING_SPLIT2 = 'training-2',
  INTERNAL_GAME_SPLIT2 = 'internal-game-2',

  WAIVER = 'waiver'
}

export const PATH_TO_HELMET: {
  [key in Path]?: { title?: string; description: string }
} = {
  [Path.HOME]: {
    title: 'Run Free Training',
    description: '',
  },
}

type SidebarItem = {
  text: string
  link: string
  icon_path: Path
  allowed_roles: Role[]
}

export const topSidebarItems: SidebarItem[] = [
  {
    text: 'Dashboard',
    icon_path: Path.DASHBOARD,
    link: `/${Path.ATHLETE}/${Path.DASHBOARD}`,
    allowed_roles: [Role.USER, Role.COACH, Role.ADMIN],
  },
  {
    text: 'Training',
    icon_path: Path.TRAINING,
    link: `/${Path.ATHLETE}/${Path.TRAINING}`,
    allowed_roles: [Role.USER, Role.COACH, Role.ADMIN],
  },
  {
    text: 'Nutrition',
    icon_path: Path.NUTRITION,
    link: `/${Path.ATHLETE}/${Path.NUTRITION}`,
    allowed_roles: [Role.USER, Role.COACH, Role.ADMIN],
  },
  {
    text: 'The 1%',
    icon_path: Path.ONE_PERCENT,
    link: `/${Path.ATHLETE}/${Path.ONE_PERCENT}`,
    allowed_roles: [Role.USER, Role.COACH, Role.ADMIN],
  },
  {
    text: 'Rest',
    icon_path: Path.REST,
    link: `/${Path.ATHLETE}/${Path.REST}`,
    allowed_roles: [Role.USER, Role.COACH, Role.ADMIN],
  },
  {
    text: 'Internal',
    icon_path: Path.INTERNAL_GAME,
    link: `/${Path.ATHLETE}/${Path.INTERNAL_GAME}`,
    allowed_roles: [Role.USER, Role.COACH, Role.ADMIN],
  },
]

export const bottomSidebarItems: SidebarItem[] = [
  {
    text: 'Coachs',
    icon_path: Path.COACH_RELATIONS,
    link: `/${Path.ATHLETE}/${Path.COACH_RELATIONS}`,
    allowed_roles: [Role.ADMIN],
  },
  {
    text: 'Users',
    icon_path: Path.USER_RELATIONS,
    link: `/${Path.ATHLETE}/${Path.USER_RELATIONS}`,
    allowed_roles: [Role.ADMIN],
  },
  {
    text: 'Videos',
    icon_path: Path.VIDEOS,
    link: `/${Path.ATHLETE}/${Path.VIDEOS}`,
    allowed_roles: [Role.COACH, Role.ADMIN],
  },
  {
    text: 'Intake',
    icon_path: Path.INTAKE_FORM,
    link: `/${Path.ATHLETE}/${Path.INTAKE_FORM}`,
    allowed_roles: [Role.USER, Role.COACH, Role.ADMIN],
  },
  {
    text: 'Subscription',
    icon_path: Path.SUBSCRIPTION,
    link: `/${Path.ATHLETE}/${Path.SUBSCRIPTION}`,
    allowed_roles: [Role.USER],
  },
  {
    text: 'Account',
    icon_path: Path.ACCOUNT,
    link: `/${Path.ATHLETE}/${Path.ACCOUNT}`,
    allowed_roles: [Role.USER, Role.COACH, Role.ADMIN],
  },
  {
    text: 'About',
    icon_path: Path.ABOUT,
    link: `/${Path.ATHLETE}/${Path.ABOUT}`,
    allowed_roles: [Role.USER],
  },
  {
    text: 'Logout',
    icon_path: Path.LOGOUT,
    link: `/${Path.LOGOUT}`,
    allowed_roles: [Role.USER, Role.COACH, Role.ADMIN],
  },
]
