import { styled } from '@mui/material/styles'

export const Item = styled('li')`
  display: flex;
  margin: 1.5rem 0 1.5rem 0;
  // margin: 1.5rem 0 1.5rem 0.5rem;

  .feature-icon {
    width: 6.25rem;
    height: 6.25rem;
    margin-right: 2.25rem;
    flex-shrink: 0;
  }
`
