import Tooltip from '@mui/material/Tooltip'
import { FORM_CATEGORIES } from 'pages/IntakeFormPage/constants'
import { NewPage } from 'pages/IntakeFormPage/hooks'
import { CSSProperties, HTMLProps } from 'react'
import { styled } from '@mui/material/styles'
import { snakeCaseToTitleCase } from 'utils/functions'
import { useWindowSizes } from 'utils/hooks'
import Fab from '@mui/material/Fab'
import { useNavigate } from 'react-router-dom'
import { Path } from 'router/constants'
import { Icon, UtilityIcon } from './Icon'

export const PROGRESS_BAR_WRAPPER_CLASS = 'progress-bar-wrapper'

export type MDProgressBarProps = {
  current: number
  total: number
  onChange: (newPage: NewPage) => () => void
  marks?: number[]
  style?: CSSProperties
}

type DotProps = {
  index: number
  onClick: () => void
}

/**
 * Template: This is for content that is in a scrollView and needs to fill the screen if content isn't too large
 */
const MDProgressBar = ({
  current,
  total,
  onChange,
  marks,
}: MDProgressBarProps) => {
  const navigate = useNavigate()
  const { smallProgressBar } = useWindowSizes()
  if (total <= 0 || current < 0 || current > total) {
    throw new Error(
      'MDProgressBar invalid current, total combo. Total must be larger than 0 and current must be 0 or larger',
    )
  }

  if (marks && marks.filter((mark) => mark > total || mark < 0).length) {
    throw new Error(
      'MDProgressBar invalid mark. Marks must be larger than 0 and less than or equal to total',
    )
  }

  return (
    <Wrapper className={PROGRESS_BAR_WRAPPER_CLASS}>
      <div
        style={{
          position: 'absolute',
          left: 15,
          right: 15,
          top: '49%',
          backgroundColor: 'black',
          height: 1,
          pointerEvents: 'none',
        }}
      />
      <Status>
        <ProgressBar style={{ flex: current }} />
        <ProgressBar
          style={{ flex: total - current + 1, backgroundColor: 'transparent' }}
        />
      </Status>
      <DotContainer>
        {[...Array(total)].map((_, n) => (
          <Dot key={n} index={n} onClick={onChange(n)} />
        ))}
        <Fab
          style={
            smallProgressBar ? { height: 24, width: 24, minHeight: 0 } : {}
          }
          size={'small'}
          onClick={() => navigate(`/${Path.ATHLETE}/${Path.SUBSCRIPTION}`)}>
          <Icon path={UtilityIcon.FLAG} size={smallProgressBar ? 14 : 24} />
        </Fab>
      </DotContainer>
    </Wrapper>
  )
}

export default MDProgressBar

const Dot = ({ index, onClick }: DotProps) => {
  const { smallProgressBar } = useWindowSizes()
  const pageText = snakeCaseToTitleCase(FORM_CATEGORIES[index]).replace(
    '-2',
    ' Part 2',
  )
  return (
    <Tooltip title={pageText}>
      <DotWrapper onClick={onClick}>
        <DotItem small={smallProgressBar} />
      </DotWrapper>
    </Tooltip>
  )
}

const Wrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const Status = styled('div')`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
`

const ProgressBar = styled('div')`
  background-color: ${(p) => p.theme.palette.primary.main};
  justify-content: space-around;
  border-radius: 20px;
  transition: flex 0.5s ease-in-out;
`
const DotContainer = styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  background-color: transparent;
`

const DotWrapper = styled('div')`
  display: flex;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
    transform: scale(1.3);
    transition: background-color 0.5s;
    transition: transform 0.5s ease;
  }
`
const DotItem = styled('div', { shouldForwardProp: (p) => p !== 'small' })<
  HTMLProps<HTMLDivElement> & { small: boolean }
>`
  height: ${(p) => (p.small ? '5px' : '10px')};
  width: ${(p) => (p.small ? '5px' : '10px')};
  background-color: ${(p) => p.theme.palette.text.secondary};
  border-radius: 50%;
  display: inline-block;
  margin: ${(p) => (p.small ? '10px' : '15px')};
`
