import { createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import MuiThemeProvider from '@mui/system/ThemeProvider' // Must use this and not from @mui/material/styles
import {
  Children,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { getStorageItem, StorageItems, useStoreOnChange } from 'utils/storage'
import { getDesignTokens, ThemeKey } from './constants'

type KeyStore = {
  chosenMode: ThemeKey
  mode: 'light' | 'dark'
}

type ColorModeDispatchType = {
  changeColorMode: (mode: ThemeKey) => void
}

const ColorModeDispatchContext = createContext<ColorModeDispatchType>({
  changeColorMode: () => undefined,
})

const ThemeKeyStore = createContext<KeyStore>({
  chosenMode: 'system',
  mode: 'dark',
})

export const useColorModeDispatch = () => useContext(ColorModeDispatchContext)
export const useThemeKey = () => useContext(ThemeKeyStore)

export const ThemeProvider = (props: { children: ReactNode }) => {
  // const isSystemDark = useIsSystemDark()
  const [chosenMode, setChosenMode] = useState<ThemeKey>(
    getStorageItem(StorageItems.THEME) ?? 'system',
  )

  // const mode: 'light' | 'dark' =
  //   chosenMode !== 'system' ? chosenMode : isSystemDark ? 'dark' : 'light'
  const mode = 'light'

  const changeColorMode = useCallback(
    (mode: ThemeKey) => setChosenMode(mode),
    [],
  )

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode])

  useStoreOnChange(StorageItems.THEME, chosenMode)

  return (
    <ColorModeDispatchContext.Provider value={{ changeColorMode }}>
      <ThemeKeyStore.Provider value={{ chosenMode, mode }}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          {Children.only(props.children)}
        </MuiThemeProvider>
      </ThemeKeyStore.Provider>
    </ColorModeDispatchContext.Provider>
  )
}
