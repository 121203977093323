import { Grid, IconButton } from '@mui/material'
import { withPageWrapper } from 'components/withScreenWrapper'
import styled from 'styled-components'
import { useWindowSizes } from 'utils/hooks'
import rft_logo from 'images/rft_logo.png'
import PlansTable from 'components/PlansTable'
import { useState } from 'react'
import { PlanWithConfig } from 'contexts/braintree/types'
import { Register } from 'components/Register'
import BraintreeDropIn from 'components/BraintreeDropIn'
import { ArrowBack } from '@mui/icons-material'

const PublicSubscriptionPage = () => {
  const { isMedium } = useWindowSizes()
  const [selectedPlan, setselectedPlan] = useState<PlanWithConfig | null>(null)
  const [showDropdIn, setshowDropdIn] = useState(false)
  const [step, setstep] = useState(0)
  return (
    <Grid container height='100%'>
      {(isMedium || step === 0) && (
        <RightWrapper item md={6}>
          {!isMedium && step === 0 && (
            <div
              style={{
                fontSize: 20,
                fontWeight: 'bold',
                color: 'white',
              }}>
              <div>
                <img
                  src={rft_logo}
                  style={{ height: 100, marginBottom: 20, marginTop: 20 }}
                  alt='logo'
                />
              </div>
              Please select a plan to proceed
            </div>
          )}
          <div>
            <PlansTable
              handleClick={(plan: PlanWithConfig) => {
                setstep(1)
                setselectedPlan(plan)
              }}
              selectedPlan={selectedPlan}
            />
          </div>
        </RightWrapper>
      )}
      {(isMedium || step === 1) && (
        <LeftWrapper item xs={12} md={6}>
          {!isMedium && (
            <IconButton
              onClick={() => setstep(0)}
              sx={{
                justifyContent: 'flex-start',
              }}>
              <ArrowBack sx={{ color: 'black' }} fontSize='large' />
            </IconButton>
          )}
          {selectedPlan ? (
            <>
              {showDropdIn ? (
                <BraintreeDropIn
                  plan={selectedPlan}
                  style={{ fontSize: 30, fontWeight: '700' }}
                />
              ) : (
                <Register onSubmitCallback={() => setshowDropdIn(true)} />
              )}
            </>
          ) : (
            <div
              style={{
                fontSize: 30,
                fontWeight: 'bold',
              }}>
              <div>
                <img
                  src={rft_logo}
                  style={{ height: 150, marginBottom: 20 }}
                  alt='logo'
                />
              </div>
              Please select a plan to proceed
            </div>
          )}
        </LeftWrapper>
      )}
    </Grid>
  )
}

const LeftWrapper = styled(Grid)`
  display: flex;
  flex: 4;
  flex-direction: column;
  justify-content: center;
  padding: 0px 10vw;
`
const RightWrapper = styled(Grid)`
  display: flex;
  flex: 5;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background-color: #262525;
`
export default withPageWrapper(
  PublicSubscriptionPage,
  { width: 'full', ignoreHeader: true },
  { flexDirection: 'row', justifyContent: 'stretch' },
)
