import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Paper,
  Popover,
  Skeleton,
  Stack,
} from '@mui/material'
import { FeedVideo } from 'components/Feed/FeedVideo'
import { Icon, UtilityIcon } from 'components/Icon'
import { SubTitle } from 'components/SubTitle'
import {
  useMutateDeleteVideo,
  useMutatePinVideo,
  useSelfUser,
  useVideos,
} from 'contexts/api/hooks'
import {
  OverrideOptions,
  Role,
  TabPinVideo,
  UpdatePinVideoById,
} from 'contexts/api/types'
import { useBraintreeSubscription } from 'contexts/braintree/hooks'
import { Header } from 'pages/AthletePages/components'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Path } from 'router/constants'
import { FEED_PAGE_SIZE } from 'utils/config'
import { getFileUrlType } from 'utils/functions'
import { useWindowSizes } from 'utils/hooks'
import { MediaType } from 'utils/types'
import { IconButtonWrapper, IconWrapper } from './styles'
import React from 'react'
import { useShowDialog } from 'contexts/dialog/Controller'

export const VideoHistory = (params?: { adminView?: true }) => {
  const showDialog = useShowDialog()
  const navigate = useNavigate()
  const windowSizes = useWindowSizes()
  const { videos, isLoading } = useVideos()
  const { mutateAsync: mutatePinVideo } = useMutatePinVideo()
  const { mutateAsync: mutateDeleteVideo } = useMutateDeleteVideo()
  const [pages, setPages] = useState(1)
  const { role, user } = useSelfUser()
  const { braintreeSubscription } = useBraintreeSubscription()
  const [isShowMore, setIsShowMore] = useState(false)
  const manualOverride = user?.manualOverride

  const visibleVideoItems = videos?.slice(0, pages * FEED_PAGE_SIZE) ?? []
  const allLoaded = videos?.length === visibleVideoItems?.length
  const [anchorEls, setAnchorEls] = React.useState<
    Array<HTMLButtonElement | null>
  >(new Array(videos?.length).fill(null))

  const subscription =
    manualOverride === OverrideOptions.NO_MANUAL_OVERRIDE
      ? braintreeSubscription
      : manualOverride === OverrideOptions.PROVIDE_SUBSCRIPTION
      ? true
      : false

  const handleOpenPopover = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    const updatedAnchorEls = [...anchorEls]
    updatedAnchorEls[index] = event.currentTarget
    setAnchorEls(updatedAnchorEls)
  }

  const handleClosePopover = useCallback(
    (index: number) => {
      const updatedAnchorEls = [...anchorEls]
      updatedAnchorEls[index] = null
      setAnchorEls(updatedAnchorEls)
    },
    [anchorEls],
  )

  const open = Boolean(anchorEls)

  const mapTabPinVideoToUpdatePinVideo = (
    id: number,
    tabPinVideo: TabPinVideo,
  ): UpdatePinVideoById => {
    switch (tabPinVideo) {
      case TabPinVideo.PinnedInternal:
        return { isPinnedInternal: true, id: id }
      case TabPinVideo.PinnedNutrition:
        return { isPinnedNutrition: true, id: id }
      case TabPinVideo.PinnedOnePercent:
        return { isPinnedOnePercent: true, id: id }
      case TabPinVideo.PinnedRest:
        return { isPinnedRest: true, id: id }
      case TabPinVideo.PinnedTraining:
        return { isPinnedTraining: true, id: id }
    }
  }

  const handlePinVideo = useCallback(
    (id: number, tabPinVideo: TabPinVideo) => {
      const payload = mapTabPinVideoToUpdatePinVideo(id, tabPinVideo)

      handleClosePopover(id)

      if (id !== undefined && payload !== null) {
        mutatePinVideo(payload).then(() => {
          showDialog({
            title: 'Success',
            subtitle: 'The video has been pinned.',
            option2Text: null,
          })
        })
      }
    },
    [handleClosePopover, mutatePinVideo, showDialog],
  )

  const handleDeleteVideo = useCallback(
    (id: number) => {
      const confirmDelete = window.confirm(
        'Are you sure you want to delete this video?',
      )
      if (confirmDelete) {
        mutateDeleteVideo(id).then(() => {
          showDialog({
            title: 'Success',
            subtitle: 'The video has been deleted.',
            option2Text: null,
          })
        })
      }
    },
    [mutateDeleteVideo, showDialog],
  )

  return isLoading ? (
    <Grid container item xs={12} alignItems='flex-end' justifyContent='center'>
      <Skeleton variant='circular' width={20} height={20} />
    </Grid>
  ) : (
    <>
      {visibleVideoItems?.length > 0 && (
        <Stack paddingBottom={2}>
          <Button
            style={{ padding: 10, marginTop: 10 }}
            fullWidth
            variant='contained'
            onClick={() => setIsShowMore((s) => !s)}>
            <span style={{ color: 'white', fontWeight: 600 }}>
              {isShowMore ? 'Show Less' : 'Show Videos'}
            </span>
          </Button>
        </Stack>
      )}
      {isShowMore || params?.adminView ? (
        <>
          <Header>Video List</Header>
          {visibleVideoItems?.length === 0 ? (
            <Grid
              container
              item
              xs={12}
              alignItems='center'
              justifyContent='left'>
              <Stack width={windowSizes.isMobile ? 'auto' : 340} height={200}>
                <Paper
                  style={{
                    padding: 10,
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  No Videos
                </Paper>
              </Stack>
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              md={12}
              spacing={3}
              paddingTop={1}
              paddingBottom={2}>
              {visibleVideoItems?.map((video) => {
                const type =
                  video.contentType ?? getFileUrlType(video.contentUrl ?? '')
                const preview = video.contentUrl
                  ? {
                      type,
                      url: video.contentUrl,
                      name: video.fileName,
                    }
                  : undefined

                return (
                  <Grid
                    container
                    key={video.id}
                    item
                    xs={12}
                    md={6}
                    spacing={1}
                    direction={windowSizes.isDesktop ? 'column' : 'row'}>
                    <Stack
                      direction='column'
                      justifyContent='flex-start'
                      position='relative'
                      width={role === Role.USER ? 270 : 'auto'}>
                      {preview?.url && preview.type === MediaType.Video ? (
                        <>
                          {!params?.adminView ? (
                            <>
                              <div style={{ height: 160 }}>
                                {!subscription &&
                                role !== Role.ADMIN &&
                                role !== Role.COACH ? (
                                  <Stack
                                    onClick={() =>
                                      navigate(
                                        `/${Path.ATHLETE}/${Path.SUBSCRIPTION}`,
                                      )
                                    }>
                                    {isLoading ? null : (
                                      <IconWrapper>
                                        <Icon
                                          style={{
                                            position: 'absolute',
                                            zIndex: 1,
                                            margin: 10,
                                          }}
                                          path={UtilityIcon.LOCK}
                                        />
                                        <div style={{ height: 160 }}>
                                          <FeedVideo
                                            preview={preview}
                                            isLock={!!subscription}
                                          />
                                        </div>
                                        <div className='overlay'></div>
                                      </IconWrapper>
                                    )}
                                  </Stack>
                                ) : (
                                  <Paper style={{ borderRadius: 0 }}>
                                    <div style={{ height: 160 }}>
                                      <FeedVideo preview={preview} />
                                    </div>
                                  </Paper>
                                )}
                              </div>
                            </>
                          ) : (
                            <Paper style={{ borderRadius: 0 }}>
                              <div
                                style={{
                                  height: 240,
                                  width: windowSizes.isDesktop ? 'auto' : 360,
                                }}>
                                <IconButtonWrapper
                                  onClick={(event) =>
                                    handleOpenPopover(event, video.id)
                                  }
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    zIndex: 1,
                                    backgroundColor: 'white',
                                  }}>
                                  <Icon path={UtilityIcon.PUSHPIN} />
                                </IconButtonWrapper>
                                <Popover
                                  id={open ? 'simple-popover' : undefined}
                                  open={Boolean(anchorEls[video.id])}
                                  anchorEl={anchorEls[video.id]}
                                  onClose={() => handleClosePopover(video.id)}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}>
                                  <ButtonGroup
                                    orientation='vertical'
                                    aria-label='vertical outlined button group'>
                                    <Button
                                      style={{ fontWeight: 600 }}
                                      disabled={video.isPinnedTraining}
                                      onClick={() =>
                                        handlePinVideo(
                                          video.id,
                                          TabPinVideo.PinnedTraining,
                                        )
                                      }>
                                      Training
                                    </Button>
                                    <Button
                                      style={{ fontWeight: 600 }}
                                      disabled={video.isPinnedNutrition}
                                      onClick={() =>
                                        handlePinVideo(
                                          video.id,
                                          TabPinVideo.PinnedNutrition,
                                        )
                                      }>
                                      Nutrition
                                    </Button>
                                    <Button
                                      style={{ fontWeight: 600 }}
                                      disabled={video.isPinnedOnePercent}
                                      onClick={() =>
                                        handlePinVideo(
                                          video.id,
                                          TabPinVideo.PinnedOnePercent,
                                        )
                                      }>
                                      One Percent
                                    </Button>
                                    <Button
                                      style={{ fontWeight: 600 }}
                                      disabled={video.isPinnedRest}
                                      onClick={() =>
                                        handlePinVideo(
                                          video.id,
                                          TabPinVideo.PinnedRest,
                                        )
                                      }>
                                      Rest
                                    </Button>
                                    <Button
                                      style={{ fontWeight: 600 }}
                                      disabled={video.isPinnedInternal}
                                      onClick={() =>
                                        handlePinVideo(
                                          video.id,
                                          TabPinVideo.PinnedInternal,
                                        )
                                      }>
                                      Internal
                                    </Button>
                                  </ButtonGroup>
                                </Popover>
                                <FeedVideo preview={preview} />
                              </div>
                            </Paper>
                          )}
                        </>
                      ) : null}
                    </Stack>
                    <Stack
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'
                      spacing={2}
                      width={windowSizes.isDesktop ? 'auto' : 360}>
                      <Stack>
                        <SubTitle style={{ fontSize: 18 }}>
                          {video.fileName}
                        </SubTitle>
                      </Stack>
                      {!params?.adminView ? null : (
                        <Stack>
                          <IconButton>
                            <Icon
                              path={UtilityIcon.TRASH}
                              onClick={() => {
                                handleDeleteVideo(video.id)
                              }}
                            />
                          </IconButton>
                        </Stack>
                      )}
                    </Stack>
                  </Grid>
                )
              })}
            </Grid>
          )}
          {visibleVideoItems?.length === 0 ? null : (
            <Stack alignItems='center' paddingBottom={2}>
              <Button
                style={{ padding: 10, width: '50%' }}
                variant='contained'
                disabled={allLoaded}
                onClick={() => setPages((p) => p + 1)}>
                <span style={{ color: 'white', fontWeight: 600 }}>
                  {allLoaded ? "You're all caught up!" : 'Load more videos'}
                </span>
              </Button>
            </Stack>
          )}
        </>
      ) : null}
    </>
  )
}
