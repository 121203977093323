import { getFieldError } from 'contexts/api/functions'
import { InputWrapper } from 'components'
import { ChangeEvent, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { snakeCaseToTitleCase } from 'utils/functions'
import { FormKey } from '../constants'
import { Content } from './Content'
import { ErrorMessage } from './ErrorMessage'
import { FormLabel } from './FormLabel'
import { Item } from './Item'
import { Radio } from './Radio'
import { RadioForm } from './RadioForm'
import { RadioGroup } from './RadioGroup'
import { FormTextField } from './FormTextField'
import Color from 'color'

type FormRadioItemProps = {
  name: FormKey // key of the form data
  initialValue: string | undefined
  items: string[]
  wasSubmitted: boolean
  placeholder?: string
  title: string
  subtitle?: string
  allowOther?: boolean
}
export const FormRadioItem = ({
  name,
  items,
  initialValue,
  wasSubmitted,
  title,
  allowOther,
}: FormRadioItemProps) => {
  const [value, setValue] = useState(initialValue ?? '')
  const [inputValue, setInputValue] = useState(
    initialValue && items.includes(initialValue) ? '' : initialValue,
  )
  const errorMessage = getFieldError(name, value)
  const displayErrorMessage = wasSubmitted && errorMessage

  // Update value on initial value change
  useEffect(() => {
    setValue(initialValue ?? '')
  }, [initialValue])

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    // console.debug('FormRadioItem handleInputChange', event.currentTarget.value)
    setInputValue(event.currentTarget.value)
    setValue(event.currentTarget.value)
  }

  return (
    <Item>
      <Content>
        <RadioForm>
          <FormLabel>{title}</FormLabel>
          <RadioGroup>
            {items.map((item) => (
              <Radio
                key={`${name}-${item}`}
                id={`${name}-${item}`}
                label={snakeCaseToTitleCase(item)}
                className='radio'
                name={name}
                value={item}
                checked={value === item}
                onChange={(e) => setValue(e.currentTarget.value)}
              />
            ))}
            {allowOther && (
              <RadioInputWrapper>
                <Radio
                  id={`${name}-other`}
                  label='Other:'
                  className='radio'
                  name={name}
                  value={inputValue !== '' ? inputValue : 'Other'}
                  checked={
                    items.filter((item) => value === item).length === 0 &&
                    value !== ''
                  }
                  // checked={true}
                  onChange={(e) => setValue(e.currentTarget.value)}
                />

                <InputWrapper>
                  <RadioInput
                    variant='filled'
                    // type='text'
                    // placeholder='Please enter a custom value'
                    value={inputValue ?? ''}
                    onChange={handleInputChange}
                  />
                </InputWrapper>
              </RadioInputWrapper>
            )}
            {displayErrorMessage ? (
              <ErrorMessage
                role='alert'
                id={`${name}-error`}
                className='error-message'>
                {errorMessage}
              </ErrorMessage>
            ) : null}
          </RadioGroup>
        </RadioForm>
      </Content>
    </Item>
  )
}

const RadioInputWrapper = styled('div')`
  display: flex;
  flex-direction: row;
`

const RadioInput = styled(FormTextField)`
  color: ${(p) => p.theme.palette.text.primary};
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;

  &::placeholder {
    font-weight: 300;
    color: ${(p) => p.theme.palette.text.secondary};
  }

  &:focus {
    border-color: ${(p) => p.theme.palette.primary.main};
  }

  .MuiFilledInput-input {
    &:focus {
      box-shadow: 0 0 0 3px
        ${(p) => Color(p.theme.palette.primary.main).alpha(0.4).hsl().string()};
    }
  }
`
