import { getFieldError } from 'contexts/api/functions'
import { InputWrapper } from 'components'
import { P } from 'components'
import { TextAreaGrow, TextAreaWrapper } from 'components/TextAreaGrow'
import { useEffect, useState } from 'react'
import { FormItemTextType, FormKey } from '../constants'
import { Content } from './Content'
import { ErrorMessage } from './ErrorMessage'
import { FormLabel } from './FormLabel'
import { Item } from './Item'
import { FormTextField } from './FormTextField'

type FormTextItemProps = {
  name: FormKey
  initialValue: string | undefined
  title: string
  type: FormItemTextType['type']
  autoComplete?: FormItemTextType['autoComplete']
  subtitle?: string
  wasSubmitted: boolean
  placeholder?: string
}
export const FormTextItem = ({
  name,
  initialValue,
  title,
  type,
  autoComplete,
  subtitle,
  wasSubmitted,
  placeholder,
}: FormTextItemProps) => {
  const [value, setValue] = useState(initialValue ?? '')
  const [touched, setTouched] = useState(false)
  const errorMessage = getFieldError(name, value)
  const displayErrorMessage = (wasSubmitted || touched) && errorMessage
  const errorMessageComponent = displayErrorMessage ? (
    <ErrorMessage role='alert' id={`${name}-error`} className='error-message'>
      {errorMessage}
    </ErrorMessage>
  ) : null

  // Update value on initial value change
  useEffect(() => {
    setValue(initialValue ?? '')
  }, [initialValue])

  return (
    <Item>
      <Content>
        <FormLabel htmlFor={`${name}-input`}>{title}</FormLabel>
        {subtitle && (
          <P
            style={{
              marginBottom: 5,
              marginTop: 0,
              fontFamily: 'Nunito',
              fontSize: 14,
            }}>
            {subtitle}
          </P>
        )}
        {type === 'textarea' ? (
          <TextAreaWrapper>
            <TextAreaGrow
              id={`${name}-input`}
              name={name}
              autoComplete={autoComplete}
              onChange={(event) => {
                // console.debug('TextAreaGrow onChange', event.currentTarget.value)
                setValue(event.currentTarget.value)
                const parent: HTMLDivElement | undefined = event.currentTarget
                  .parentNode as HTMLDivElement
                parent.dataset.replicatedValue = event.currentTarget.value
              }}
              onBlur={() => setTouched(true)}
              value={value}
              placeholder={placeholder}
              aria-describedby={
                displayErrorMessage ? `${name}-error` : undefined
              }
            />
            {errorMessageComponent}
          </TextAreaWrapper>
        ) : (
          <InputWrapper>
            <FormTextField
              variant='filled'
              id={`${name}-input`}
              name={name}
              type={type}
              autoComplete={autoComplete}
              onChange={(event) => {
                // console.debug('FormTextField onChange', event.currentTarget.value)
                setValue(event.currentTarget.value)
              }}
              onBlur={() => setTouched(true)}
              value={value}
              placeholder={placeholder}
              // pattern='[a-z]{3,10}'
              aria-describedby={
                displayErrorMessage ? `${name}-error` : undefined
              }
            />
            {errorMessageComponent}
          </InputWrapper>
        )}
      </Content>
    </Item>
  )
}
