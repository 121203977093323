import { memo } from 'react'
import { styled } from '@mui/material/styles'

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

interface Props extends InputProps {
  id: string
  label: string
  className?: string
}

export const VerticalRadio = memo(
  ({ id, label, className, ...restOf }: Props) => {
    return (
      <Wrapper className={className}>
        <input type='radio' id={id} {...restOf} />
        <label htmlFor={id}>{label}</label>
      </Wrapper>
    )
  },
)

VerticalRadio.displayName = 'VerticalRadio'

const Wrapper = styled('div')`
  text-align: center;

  input[type='radio'] {
    margin: 0;
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0;

    + label {
      margin: 0;
      text-align: center;
      display: inline-block;
      width: 1rem; // to keep labels centered below
      padding-top: 0.0625rem;
      position: relative;
      cursor: pointer;
      font-size: 0.875rem;
      color: ${(p) => p.theme.palette.text.primary};
      z-index: 1;

      a {
        color: ${(p) => p.theme.palette.text.primary};
        text-decoration: none;
      }

      &::before {
        position: absolute;
        top: 1.5rem;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: ${(p) => p.theme.palette.background.elements};
        content: '';
        border: 2px solid ${(p) => p.theme.palette.border.default};
        transition: all 0.1s;
      }

      &::after {
        display: none;
        content: '';
        position: absolute;
        display: inline-block;
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 50%;
        top: 1.8125rem;
        left: 0.3125rem;
        background-color: ${(p) => p.theme.palette.background.elements};
      }
      &::after {
        display: none;
        content: '';
        position: absolute;
        display: inline-block;
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 50%;
        top: 1.8125rem;
        left: 0.3125rem;
        background-color: ${(p) => p.theme.palette.background.elements};
      }

      &:hover {
        &::before {
          border-color: ${(p) => p.theme.palette.primary.main};
        }
      }
    }

    &:disabled {
      + label {
        opacity: 0.6;
        cursor: auto;

        &:hover {
          &::before {
            border-color: ${(p) => p.theme.palette.border.default};
          }
        }
      }
    }

    &:focus {
      + label {
        &::before {
          box-shadow: 0 0 0 3px
            ${(p) =>
              p.theme.palette.primary.main.replace(
                /rgba?(\(\s*\d+\s*,\s*\d+\s*,\s*\d+)(?:\s*,.+?)?\)/,
                'rgba$1,0.2)',
              )};
        }
      }
    }

    &:checked {
      + label {
        &::before {
          background-color: ${(p) => p.theme.palette.primary.main};
          border-color: ${(p) => p.theme.palette.primary.main};
        }

        &::after {
          display: inline-block;
        }
      }
    }
  }
`
