import {
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  TextField,
} from '@mui/material'
import { Icon, UtilityIcon } from 'components/Icon'
import { apiFetch } from 'contexts/api/functions'
import { useMutateVideo, useSelfUser } from 'contexts/api/hooks'
import { UploadFileResponse } from 'contexts/api/types'
import { Header } from 'pages/AthletePages/components'
import { useCallback, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { ApiPath } from 'utils/config'
import { getFileType } from 'utils/functions'
import { MediaType } from 'utils/types'
import VideoSnapshot from 'video-snapshot'

export const VideoUpload = () => {
  const { user: selfUser } = useSelfUser()
  const { mutateAsync: mutateVideoAsync } = useMutateVideo()
  const [selectedFile, setSelectedFile] = useState<File>()
  const [videoPreview, setVideoPreview] = useState<null | string>(null)
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [uploading, setUploading] = useState(false)
  const [videoTitle, setVideoTitle] = useState<string>('')
  const [videoUrl, setVideoUrl] = useState<string>('')
  const [showUrlField, setShowUrlField] = useState<boolean>(false)
  const [validUrl, setValidUrl] = useState<boolean>(false)

  const preview =
    !selectedFile && videoUrl
      ? {
          name: videoTitle,
          url: videoUrl,
          type: MediaType.Video,
        }
      : selectedFile
      ? {
          name: selectedFile.name,
          url: URL.createObjectURL(selectedFile),
          type: getFileType(selectedFile),
        }
      : undefined

  const ResetFields = () => {
    setVideoUrl('')
    setValidUrl(false)
    setVideoPreview(null)
    setVideoTitle('')
  }

  const changeHandler = useCallback(async (event) => {
    const [file]: File[] = event.target.files
    file && setSelectedFile(file)

    const snapshoter = new VideoSnapshot(file)
    const previewSrc = await snapshoter.takeSnapshot()

    setVideoPreview(previewSrc)
  }, [])

  const handleVideoUrlChange = useCallback((event) => {
    setVideoUrl(event.target.value)
    setValidUrl(ReactPlayer.canPlay(event.target.value))
  }, [])

  const handleFileButtonClick = useCallback(() => {
    hiddenFileInput.current?.click()
  }, [])

  const handleSubmit = useCallback(async () => {
    if (uploading) return
    setUploading(true)

    if (
      (selectedFile || (videoUrl && validUrl)) &&
      selfUser?.id !== undefined
    ) {
      const isFileUpload = !!selectedFile

      const fileUrl = !selectedFile
        ? undefined
        : await apiFetch<UploadFileResponse>(ApiPath.FEED_UPLOAD_FILE, {
            body: selectedFile,
            method: 'POST',
          })
            .then((res) => {
              return res.fileLocation
            })
            .catch((err) => {
              console.error('feed submit fileurl ERROR', err)
              return undefined
            })

      const contentUrl = isFileUpload ? fileUrl : videoUrl

      const payload = {
        fromId: selfUser.id,
        ...(contentUrl
          ? {
              contentUrl,
              contentType: isFileUpload
                ? getFileType(selectedFile)
                : MediaType.Video,
              fileName: videoTitle,
            }
          : {}),
      }

      mutateVideoAsync(payload)
        .then(() => {
          if (isFileUpload) {
            setSelectedFile(undefined)
          } else {
            ResetFields()
          }
          setVideoTitle('')
        })
        .finally(() => setUploading(false))
    } else {
      setUploading(false)
    }
  }, [
    uploading,
    selectedFile,
    videoUrl,
    validUrl,
    selfUser?.id,
    videoTitle,
    mutateVideoAsync,
  ])

  return (
    <>
      <Header style={{ fontSize: 22, paddingBottom: 16 }}>Upload Video</Header>
      <Paper style={{ padding: 10 }}>
        {preview && ReactPlayer.canPlay(preview?.url) ? (
          <ReactPlayer
            url={preview?.url}
            light={videoPreview ?? ''}
            width={250}
            height={100}
            playIcon={<Icon path={UtilityIcon.PLAY} />}
            alt={preview?.name}
          />
        ) : null}
        <Stack
          display='flex'
          direction='row'
          alignItems='flex-start'
          justifyContent='flex-end'>
          <Stack direction='column' width='100%'>
            <TextField
              multiline
              required
              id='standard-basic'
              label='Add a Video Title'
              variant='standard'
              style={{ flex: 1 }}
              value={videoTitle}
              onChange={(e) => {
                setVideoTitle(e.target.value)
              }}
            />
            {showUrlField ? (
              <TextField
                multiline
                required
                id='standard-basic'
                label='Insert a Video URL'
                variant='standard'
                style={{ flex: 1 }}
                value={videoUrl}
                onChange={(e) => handleVideoUrlChange(e)}
              />
            ) : null}
          </Stack>
          <Stack
            display='flex'
            direction='row'
            alignItems='flex-start'
            justifyContent='flex-end'>
            {uploading ? (
              <div>
                <CircularProgress size={20} />
              </div>
            ) : (
              <>
                {selectedFile ? (
                  <IconButton
                    onClick={() => {
                      ResetFields()
                      setSelectedFile(undefined)
                      if (hiddenFileInput.current) {
                        hiddenFileInput.current.value = ''
                      }
                    }}>
                    <Icon path={UtilityIcon.CLEAR} />
                  </IconButton>
                ) : (
                  <>
                    <IconButton
                      onClick={() => {
                        ResetFields()
                        setShowUrlField(!showUrlField)
                      }}>
                      <Icon
                        path={
                          showUrlField
                            ? UtilityIcon.LINK_FILL
                            : UtilityIcon.LINK
                        }
                      />
                    </IconButton>
                    <IconButton
                      onClick={handleFileButtonClick}
                      disabled={showUrlField ?? false}>
                      <input
                        ref={hiddenFileInput}
                        type='file'
                        style={{ display: 'none' }}
                        onChange={changeHandler}
                      />
                      <Icon path={UtilityIcon.VIDEO} />
                    </IconButton>
                  </>
                )}
                <IconButton
                  onClick={handleSubmit}
                  disabled={
                    showUrlField
                      ? videoTitle === '' || !validUrl
                      : videoTitle === '' || selectedFile === undefined
                  }>
                  <Icon path={UtilityIcon.SEND} />
                </IconButton>
              </>
            )}
          </Stack>
        </Stack>
      </Paper>
    </>
  )
}
