import { styled } from '@mui/material/styles'
import MuiButton from '@mui/material/Button'

export const Button = styled(MuiButton)(({ disabled }) => ({
  borderRadius: '5px',
  fontWeight: '600',
  padding: '20px',
  ...(disabled
    ? {}
    : {
        // color: theme.palette.background.default,
      }),
}))
