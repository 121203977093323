// import { useAuth } from 'auth'
// import { getDetail, getIdentifier, getRandomEtymology } from 'componentData'
import { useLocation } from 'react-router-dom'

export const usePathParams = () => {
  const location = useLocation()

  let page: string | undefined = undefined
  let subpage: string | undefined = undefined
  let subsubpage: string | undefined = undefined

  const pathParts = location.pathname.split('/')
  if (pathParts.length >= 2) {
    page = pathParts[1]
  }

  if (pathParts.length >= 3) {
    subpage = pathParts[2]
  }

  if (pathParts.length >= 4) {
    subsubpage = pathParts[3]
  }

  return { page, subpage, subsubpage }
}
