import { styled } from '@mui/material/styles'
import MuiTextField from '@mui/material/TextField'
import Color from 'color'

export const FormTextField = styled(MuiTextField)`
  width: 100%;
  .MuiFilledInput-input {
    padding: 8px 12px;
    border-radius: 15px;

    &:focus {
      box-shadow: 0 0 0 3px
        ${(p) => Color(p.theme.palette.primary.main).alpha(0.4).hsl().string()};
    }
  }
  .MuiFilledInput-root {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .MuiFilledInput-root:before,
  .MuiFilledInput-root:after {
    border-bottom: none !important;
  }
`
