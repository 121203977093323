import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { GridCellParams, GridRowId } from '@mui/x-data-grid'
import { Button, ConditionalWrapper, DataGrid } from 'components'
import { withPageWrapper } from 'components/withScreenWrapper'
import { useDeleteUser } from 'contexts/api/hooks'
import { useState } from 'react'
import { UiProvider, useDialog } from './UiProvider'
import { GridColumnsFields, useDataGrid } from './useDataGrid'

enum Action {
  NONE,
  DELETE,
  ADD,
  DUPLICATE,
}

type UserFields = {
  name: string
}

function RelationsPageWrapper() {
  return (
    <UiProvider>
      <UserRelationsPage />
    </UiProvider>
  )
}

function UserRelationsPage() {
  const { columns, rows, isLoading } = useDataGrid()
  const [selection, setSelection] = useState<GridRowId[]>([])
  const { mutateAsync: mutateDelete } = useDeleteUser()

  const { showDialog } = useDialog()

  const [action, setAction] = useState<Action>(Action.NONE)

  return (
    <Wrapper>
      <StyledDataGrid
        initialState={{
          columns: {
            columnVisibilityModel: {
              [GridColumnsFields.ID]: false,
            },
          },
          sorting: {
            sortModel: [{ field: GridColumnsFields.CREATED_AT, sort: 'desc' }],
          },
        }}
        rows={rows}
        columns={columns}
        density='compact'
        autoPageSize
        disableSelectionOnClick
        onCellClick={(data: GridCellParams<UserFields>) => {
          if (data.field === '__check__') return // select
          showDialog(data.row.id)
        }}
        checkboxSelection
        loading={isLoading}
        selectionModel={selection}
        onSelectionModelChange={setSelection}
      />
      <Stack
        direction='row'
        justifyContent='flex-end'
        gap='1rem'
        style={{ marginTop: '1rem' }}>
        <Stack direction='row' gap={2}>
          <ConditionalWrapper
            condition={selection.length !== 1}
            wrapper={(children) => (
              <Tooltip title='Can edit only 1 item at a time'>
                <div>{children}</div>
              </Tooltip>
            )}>
            <Button
              variant='contained'
              disabled={selection.length !== 1}
              onClick={() =>
                showDialog(rows.find(({ id }) => id === selection[0])?.id)
              }>
              Edit
            </Button>
          </ConditionalWrapper>
          <Button
            variant='contained'
            disabled={selection.length === 0}
            loading={action === Action.DELETE}
            onClick={() => {
              setAction(Action.DELETE)
              if (
                window.confirm(
                  `Are you sure you want to delete ${
                    selection.length > 1 ? 'these users' : 'this user'
                  }?`,
                )
              ) {
                // console.log(selection)
                Promise.all(
                  selection.map((s) => mutateDelete({ id: Number(s) })),
                ).finally(() => setAction(Action.NONE))
              }
            }}>
            Delete
          </Button>
        </Stack>
      </Stack>
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export default withPageWrapper(
  RelationsPageWrapper,
  { width: 'max-width' },
  { alignItems: 'stretch' },
)

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'none !important',
  },
  '& .MuiDataGrid-cell': {
    lineHeight: 'unset !important',
    maxHeight: 'none !important',
    whiteSpace: 'break-spaces !important',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  '& .MuiDataGrid-row': {
    maxHeight: 'none !important',
  },
})
