//Can be development and local
export const IS_PRODUCTION = process.env.REACT_APP_CHANNEL === 'production'
export const IS_STAGING = process.env.REACT_APP_CHANNEL === 'staging'
export const IS_DEVELOPMENT = !IS_PRODUCTION && !IS_STAGING
export const IS_LOCAL_DEVELOPMENT = process.env.REACT_APP_CHANNEL === 'local'

export const API_HOST = IS_PRODUCTION
  ? 'https://api.runfreetraining.com'
  : IS_STAGING
  ? 'https://stage-api.runfreetraining.com'
  : IS_LOCAL_DEVELOPMENT
  ? 'http://localhost:3000'
  : 'https://dev-api.runfreetraining.com'

export const DRAWER_WIDTH = 240
