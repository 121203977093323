import { apiFetch, updateUserCache } from 'contexts/api/functions'
// import Cookies from 'js-cookie'
import {
  FinishResetPassword,
  LoginResponseBody,
  RefreshResponseBody,
  RegisterResponseBody,
  Role,
  StartResetPassword,
  VerifyUserResponseBody,
} from 'contexts/api/types'
import { Path } from 'router/constants'
import { ApiPath, QueryKeyKey } from 'utils/config'
import { IS_DEVELOPMENT } from 'utils/constants'
import { queryClient } from 'utils/setup'
import {
  clearAllLocalStorage,
  clearAllSessionStorage,
  setStorageItem,
  StorageItems,
} from 'utils/storage'

export const login = async ({
  email,
  password,
}: {
  email: string
  password: string
}): Promise<LoginResponseBody> => {
  // console.log('login attempt')
  const body = { username: email, password }
  const response = await apiFetch<LoginResponseBody>(ApiPath.LOGIN, {
    method: 'POST',
    body,
  })

  //TODO PUBLISH: Remove in lieu of proper domain save
  // Cookies.set('refreshToken', response.refreshToken, {
  //   domain: window.location.hostname,
  // })

  setStorageItem(StorageItems.ACCESS_TOKEN, response.accessToken)
  updateUserCache(response.user)
  return response
}

export const logout = async (): Promise<void> => {
  const response = await apiFetch<void>(ApiPath.LOGOUT, {
    method: 'POST',
  }).catch(() => console.error('Unable to log out, continuing'))
  // accessToken
  queryClient.setQueriesData(
    { predicate: (query) => query.queryKey.includes(QueryKeyKey.USER_BASE) },
    undefined,
  )
  clearAllSessionStorage()
  clearAllLocalStorage()
  return response
}

export const register = async ({
  email,
  password,
  phone,
  firstName,
  lastName,
  age,
  signed
}: {
  email: string
  password: string
  phone: string
  firstName: string
  lastName: string
  age: string
  signed: boolean
}): Promise<RegisterResponseBody> => {
  const agenum = parseInt(age);

  const body = {
    username: email,
    password,
    phoneNumber: phone,
    firstName,
    lastName,
    age: agenum,
    signed
  }
  const response = await apiFetch<RegisterResponseBody>(ApiPath.SIGNUP, {
    body,
    method: 'POST',
  })

  //TODO PUBLISH: Remove in lieu of proper domain save
  // Cookies.set('refreshToken', response.refreshToken, {
  //   domain: window.location.host,
  // })
  setStorageItem(
    StorageItems.AUTH_REDIRECT,
    `/${Path.ATHLETE}/${Path.INTAKE_FORM}?new=true`,
  )

  updateUserCache(response.user)
  setStorageItem(StorageItems.ACCESS_TOKEN, response.accessToken)
  return response
}

export const verifyUser = async ({
  email,
  phone,
}: {
  email: string
  phone: string
}): Promise<VerifyUserResponseBody> => {
  const body = {
    username: email,
    phoneNumber: phone,
  }

  const response = await apiFetch<VerifyUserResponseBody>(ApiPath.VERIFY, {
    body,
    method: 'POST',
  })

  console.log("response", response)

  return response
}

export const startResetPassword = async ({
  email,
}: {
  email: string
}): Promise<StartResetPassword> => {
  const body = {
    username: email,
  }
  const response = await apiFetch<StartResetPassword>(
    ApiPath.START_RESET_PASSWORD,
    {
      body,
      method: 'POST',
    },
  )

  return response
}

export const finishResetPassword = async ({
  email,
  password,
  token,
}: {
  email: string
  password: string
  token: string
}): Promise<FinishResetPassword> => {
  const body = {
    username: email,
    password,
    token,
  }
  const response = await apiFetch<FinishResetPassword>(
    ApiPath.FINISH_RESET_PASSWORD,
    {
      body,
      method: 'POST',
    },
  )

  return response
}

export const refreshAccessToken = async (): Promise<RefreshResponseBody> => {
  console.log('refresh attempt')

  try {
    const response = await apiFetch<RefreshResponseBody>(ApiPath.REFRESH, {
      method: 'POST',
    })

    updateUserCache(response.user)
    setStorageItem(StorageItems.ACCESS_TOKEN, response.accessToken)
    //TODO PUBLISH: Remove in lieu of proper domain save
    // Cookies.set('refreshToken', response.refreshToken, {
    //   domain: window.location.host,
    // })
    return response
  } catch (err) {
    console.error('Refresh token failure')
    // Cookies.remove('refreshToken')
    setStorageItem(StorageItems.ACCESS_TOKEN, null)
    throw new Error('Unable to Refresh access token')
  }
}

export const devLogin = async () =>
  IS_DEVELOPMENT &&
  queryClient.setQueryData([QueryKeyKey.USER_BASE, undefined], {
    email: 'test@mountaindev.com',
    id: 9999999997,
    role: Role.USER,
  })

export const devCoachLogin = async () =>
  IS_DEVELOPMENT &&
  queryClient.setQueryData([QueryKeyKey.USER_BASE, undefined], {
    email: 'test+coach@mountaindev.com',
    id: 9999999998,
    role: Role.COACH,
  })

export const devAdminLogin = async () =>
  IS_DEVELOPMENT &&
  queryClient.setQueryData([QueryKeyKey.USER_BASE, undefined], {
    email: 'test+admin@mountaindev.com',
    id: 9999999999,
    role: Role.ADMIN,
  })
