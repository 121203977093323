import { useSelfUser } from 'contexts/api/hooks'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Path } from 'router/constants'
import { IS_PRODUCTION } from 'utils/constants'
import { getStorageItem, StorageItems } from 'utils/storage'
import { LocationState } from 'utils/types'

export const AuthRoute = () => {
  const { isLoggedIn, isDoneWithIntakeForm } = useSelfUser()
  const { state } = useLocation()
  const authRedirect = getStorageItem(StorageItems.AUTH_REDIRECT)
  const savedRedirectUrl =
    (state as LocationState)?.from?.pathname ?? authRedirect

  !IS_PRODUCTION &&
    console.log(
      'Authroute isLoggedIn',
      isLoggedIn,
      'state',
      state,
      'authRedirect',
      authRedirect,
      'savedRedirectUrl',
      savedRedirectUrl,
    )

  return !isLoggedIn ? (
    <Outlet />
  ) : savedRedirectUrl && !savedRedirectUrl.includes(Path.AUTH) ? (
    <Navigate to={savedRedirectUrl} />
  ) : (
    <Navigate
      to={
        isDoneWithIntakeForm
          ? `/${Path.ATHLETE}`
          : `/${Path.ATHLETE}/${Path.INTAKE_FORM}`
      }
    />
  )
}

// {state:{from:string}}
