import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/material/styles'
import { Button, Link, Title } from 'components'
import { SubTitle } from 'components/SubTitle'
import { useSelfUser } from 'contexts/api/hooks'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { Path } from 'router/constants'
import { getStorageItem, setStorageItem, StorageItems } from 'utils/storage'

const DispatchContext = createContext<{
  open: boolean
  setOpen: (val: boolean) => void
}>({
  open: false,
  setOpen: () => {
    //
  },
})

export const useSubscriptionModalContext = () => useContext(DispatchContext)

export const SubscriptionModalProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const { user: selfUser } = useSelfUser()
  const [open, setOpen] = useState(false)
  const lastSubscriptionDate = getStorageItem(
    StorageItems.LAST_SUBSCRIPTION_MODAL_DATE,
  )
  const dateNow = new Date().getDate()
  const lastSubscriptionDateValue = lastSubscriptionDate
    ? new Date(lastSubscriptionDate).getDate()
    : undefined
  const [params] = useSearchParams()
  // Only when we log into the intake form after registering
  const isNew = params.get('new')

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (isNew) {
      // This must be a new account, so go ahead and mark this as being seen today
      setStorageItem(
        StorageItems.LAST_SUBSCRIPTION_MODAL_DATE,
        new Date().toISOString(),
      )
      return
    }
    // On subscription page, don't show modal
    if (window.location.pathname === `/${Path.SUBSCRIPTION}`) return
    if (window.location.pathname === `/${Path.SPECIAL_SUBSCRIPTION}`) return

    // if we have a subscription Id then don't show the modal
    if (!selfUser || !!selfUser.subscriptionId) return

    // If we're logged in as a coach or admin, don't show the modal
    if (selfUser?.role === 'coach' || selfUser?.role === 'admin') return

    if (
      !lastSubscriptionDate ||
      Number(dateNow) > Number(lastSubscriptionDateValue)
    ) {
      setOpen(true)
      setStorageItem(
        StorageItems.LAST_SUBSCRIPTION_MODAL_DATE,
        new Date().toISOString(),
      )
    }
  }, [
    lastSubscriptionDate,
    dateNow,
    lastSubscriptionDateValue,
    selfUser,
    isNew,
  ])

  return (
    <DispatchContext.Provider
      value={{
        open,
        setOpen,
      }}>
      {children}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <ModalContentWrapper>
          <Title style={{ fontSize: 26 }}>Welcome to the Athlete Portal!</Title>
          <SubTitle
            style={{ marginBottom: 10, fontWeight: 500, fontSize: '1rem' }}>
            {`You're currently using our free platform, but there's so much more in store for you.`}
          </SubTitle>
          <SubTitle
            style={{ marginBottom: 24, fontWeight: 500, fontSize: '1rem' }}>
            {`Unlock premium benefits by subscribing to our coaching service, which includes access to 
            weekly training plans, nutritional support, holistic e-courses, and much more.`}
          </SubTitle>
          <Link
            hrefLang='/athlete/subscription'
            to='/athlete/subscription'
            onClick={() => setOpen(false)}>
            <Button variant='contained'>Go to Subscription Page</Button>
          </Link>
        </ModalContentWrapper>
      </Modal>
    </DispatchContext.Provider>
  )
}

const ModalContentWrapper = styled(Box)`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  max-width: 90vw;
  background-color: ${(p) => p.theme.palette.background.paper};
  border-radius: 10px;
  border: 2px solid ${(p) => p.theme.palette.divider};
  padding: 32px;
`

export default SubscriptionModalProvider
