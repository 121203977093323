import { styled } from '@mui/material/styles'
import { Lead } from 'components'

export function Complete() {
  return (
    <Wrapper>
      <Title>Thank you for your submission!</Title>
      <Lead>
        I{"'"}ll be reaching out to you over the phone within the next day!
      </Lead>
    </Wrapper>
  )
}

const Wrapper = styled('main')`
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 320px;
`

const Title = styled('div')`
  font-weight: bold;
  color: ${(p) => p.theme.palette.text.primary};
  font-size: 3.375rem;
  span {
    font-size: 3.125rem;
  }
`
