import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { Button, Title } from 'components'
import { Feed } from 'components/Feed'
import { withPageWrapper } from 'components/withScreenWrapper'
import { devLoadIntakeForm } from 'contexts/api/functions'
import { useViewUser } from 'contexts/api/hooks'
import { refreshAccessToken } from 'contexts/auth/functions'
import { useNavigate } from 'react-router-dom'
import { Path, Relationships } from 'router/constants'
import { IS_DEVELOPMENT } from 'utils/constants'
import { Body, Header, InfoBody, InfoHeader } from './components'
import { IntakeFormSummary } from './components/IntakeFormSummary'
import { TrainingPeaksButton } from './components/TrainingPeaksButton'
import { useEffect, useState } from 'react'
import { OverrideOptions } from 'contexts/api/types'

function AccountPage() {
  const navigate = useNavigate()
  const [signedWaiver, setSignedWaiver] = useState(false)
  const {
    name,
    email,
    phoneNumber,
    subscription,
    isSelf,
    isLoading,
    user,
    manualOverride,
  } = useViewUser()

  const cardLast4 = subscription?.transactions?.sort((a, b) =>
    a.createdAt < b.createdAt ? -1 : 1,
  )[0]?.creditCard?.last4

  useEffect(() => {
    setSignedWaiver(user?.signedWaiver || false)
  }, [user])

  const openWaiver = () => {
    navigate(`/${Path.WAIVER}`)
  }

  const subscriptionOverrideText =
    manualOverride === OverrideOptions.PROVIDE_SUBSCRIPTION
      ? 'You have a provided subscription. Please reach out to jay@runfreetraining.com for more info.'
      : manualOverride === OverrideOptions.REMOVE_SUBSCRIPTION
      ? 'Your subscription has been removed. Please reach out to jay@runfreetraining.com for more info.'
      : ''

  const adminSubscriptionOverrideText =
    manualOverride === OverrideOptions.PROVIDE_SUBSCRIPTION
      ? 'This user has a provided subscription.'
      : manualOverride === OverrideOptions.REMOVE_SUBSCRIPTION
      ? 'This user has had their subscription removed.'
      : ''

  return (
    <>
      <Title style={{ marginBottom: '0.5rem' }}>
        {name}
        {"'"}s Account
      </Title>
      <Divider
        style={{
          borderColor: '#00000050',
          marginBottom: 30,
        }}
      />
      <Grid container spacing={3} paddingBottom={2}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={3}>
            <IntakeFormSummary category={Path.ACCOUNT} additionalItems={3}>
              <Grid item xs={12} md={6}>
                <InfoHeader>Name</InfoHeader>
                <InfoBody>{name}</InfoBody>
              </Grid>
              <Grid item xs={12} md={6}>
                <InfoHeader>Phone</InfoHeader>
                <InfoBody>{phoneNumber}</InfoBody>
              </Grid>
              <Grid item xs={12} md={6}>
                <InfoHeader>Email</InfoHeader>
                <InfoBody>{email}</InfoBody>
              </Grid>
            </IntakeFormSummary>
            {/* <div>
              <Header>Pacing Profile</Header>
              <Paper style={{ padding: 10 }}>
                <Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Sed felis eget velit aliquet sagittis. Dui id ornare arcu odio
                  ut.
                </Body>
              </Paper>
            </div> */}
            <TrainingPeaksButton />
            {IS_DEVELOPMENT && (
              <>
                <Button variant='contained' onClick={() => devLoadIntakeForm()}>
                  (Development) Load Intake form
                </Button>
                <Button
                  variant='contained'
                  onClick={() => refreshAccessToken()}>
                  (Development) Refresh Access Token
                </Button>
              </>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            <div>
              <Header>Subscription</Header>
              <Paper>
                <Stack padding={2}>
                  {isLoading ? (
                    <div style={{ justifyContent: 'center', display: 'flex' }}>
                      <CircularProgress />
                    </div>
                  ) : !subscription ? (
                    <>
                      <Body>
                        {isSelf ? (
                          manualOverride ===
                            OverrideOptions.NO_MANUAL_OVERRIDE && (
                            <p>You are not on a plan</p>
                          )
                        ) : (
                          <p>This user is not on a plan</p>
                        )}
                        <p className='text-nowrap'>
                          {isSelf
                            ? subscriptionOverrideText
                            : adminSubscriptionOverrideText}
                        </p>
                      </Body>
                      <Button
                        fullWidth
                        style={{ padding: 10, marginTop: 10 }}
                        variant='contained'
                        disabled={
                          manualOverride !== OverrideOptions.NO_MANUAL_OVERRIDE
                        }
                        onClick={() =>
                          navigate(`/${Path.ATHLETE}/${Path.SUBSCRIPTION}`)
                        }>
                        Sign up
                      </Button>
                    </>
                  ) : (
                    <>
                      <Body>
                        {isSelf ? (
                          manualOverride ===
                            OverrideOptions.NO_MANUAL_OVERRIDE && (
                            <p>
                              You are currently subscribed to Run Free Training
                            </p>
                          )
                        ) : (
                          <p>
                            This user is currently subscribed to Run Free
                            Training
                          </p>
                        )}
                        <p className='text-nowrap'>
                          {isSelf
                            ? subscriptionOverrideText
                            : adminSubscriptionOverrideText}
                        </p>
                      </Body>
                      <Grid item container spacing={2} marginTop={1}>
                        {!isSelf ? (
                          <>
                            <Grid item xs={12} md={6}>
                              <InfoHeader>Amount</InfoHeader>
                              <InfoBody>${subscription.price}</InfoBody>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <InfoHeader>Plan id</InfoHeader>
                              <InfoBody>{subscription.planId}</InfoBody>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <InfoHeader>Subscription id</InfoHeader>
                              <InfoBody>{subscription.id}</InfoBody>
                            </Grid>
                          </>
                        ) : null}
                        <Grid item xs={12} md={6}>
                          <InfoHeader>Next payment</InfoHeader>
                          <InfoBody>{subscription.nextBillingDate}</InfoBody>
                        </Grid>
                        {cardLast4 ? (
                          <Grid item xs={12} md={6}>
                            <InfoHeader>
                              Card{' '}
                              <a href='#'>
                                <span style={{ fontWeight: 400 }}>edit</span>
                              </a>
                            </InfoHeader>
                            <InfoBody>x{cardLast4}</InfoBody>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Button
                        fullWidth
                        style={{ padding: 10, marginTop: 10 }}
                        variant='contained'
                        disabled={
                          !isSelf ||
                          manualOverride !== OverrideOptions.NO_MANUAL_OVERRIDE
                        }
                        onClick={() =>
                          navigate(`/${Path.ATHLETE}/${Path.SUBSCRIPTION}`)
                        }>
                        Manage
                      </Button>
                    </>
                  )}
                </Stack>
              </Paper>
            </div>
            <div>
              <Header>Waiver</Header>
              <Button variant='contained' fullWidth onClick={openWaiver}>
                {signedWaiver ? 'View' : 'Sign'} Waiver
              </Button>
            </div>
            <Feed category={Relationships.GENERAL} />
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

export default withPageWrapper(
  AccountPage,
  { width: 'max-width' },
  { alignItems: 'stretch' },
)
