import List from '@mui/material/List'
import MuiListItemButton from '@mui/material/ListItemButton'
import MuiListItemIcon from '@mui/material/ListItemIcon'
import MuiListItemText, { ListItemTextProps } from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import { styled, useTheme } from '@mui/material/styles'
import { Column, Link } from 'components'
import {
  useSelfRelationships,
  useSelfUser,
  useViewUser,
} from 'contexts/api/hooks'
import { Role } from 'contexts/api/types'
import { usePathManager } from 'contexts/pathManager/Controller'
import { ThemeConstants } from 'contexts/theme/constants'
import rft_logo from 'images/rft_logo.png'
import {
  bottomSidebarItems,
  Relationships,
  topSidebarItems,
} from 'router/constants'
import { Icon } from './Icon'
import { RelationshipAutocomplete } from './RelationshipAutocomplete'

export function Sidebar() {
  const theme = useTheme()
  const { role } = useSelfUser()
  const { user: viewUser } = useViewUser()
  const { relationships } = useSelfRelationships()
  const { page, subpage } = usePathManager()
  // const [height, setHeight] = useState<number>()

  return (
    <DrawerWrapper>
      <DrawerPaper>
        <Column
          alignItems='flex-start'
          justifyContent='space-between'
          style={{ flex: 1, width: '100%', height: '100%' }}>
          <img
            src={rft_logo}
            style={{
              height: 60,
              alignSelf: 'center',
              marginBottom:20
            }}
            alt='logo'
          />
          {role !== Role.USER && <RelationshipAutocomplete />}
          <List style={{ width: '100%' }}>
            {topSidebarItems.map((item, index) => {
              const selected = `/${page}/${subpage}` === item.link
              const canView =
                role === Role.ADMIN ||
                role === Role.USER ||
                (role === Role.COACH &&
                  !!relationships.find(
                    (r) =>
                      r.to.id === viewUser?.id &&
                      ((r.category as string) === item.icon_path ||
                        r.category === Relationships.TRAINING),
                  ))
              // console.log('canView', canView, role, relationships)
              // User is user
              // User is admin
              // User is coach and has relationship
              return !canView ? null : (
                <Link to={item.link} key={index}>
                  <ListItemButton
                    key={item.text}
                    selected={selected}
                  // ref={(node) =>
                  //   selected && setHeight(node?.getBoundingClientRect().top)
                  // }
                  >
                    <ListItemIcon
                      color={
                        selected
                          ? theme.palette.primary.main
                          : theme.palette.text.primary
                      }>
                      <Icon path={item.icon_path} />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      primaryTypographyProps={{ fontSize: 18 }}
                      expanded={true}
                      style={{
                        color: selected
                          ? theme.palette.primary.main
                          : theme.palette.text.primary,
                      }}
                    />
                  </ListItemButton>
                </Link>
              )
            })}
          </List>
          <List style={{ width: '100%' }}>
            {bottomSidebarItems
              .filter((item) => item.allowed_roles.includes(role))
              .map((item, index) => {
                const selected = `/${page}/${subpage}` === item.link
                return (
                  <Link to={item.link} key={index}>
                    <ListItemButton selected={selected} key={item.text}>
                      <ListItemIcon
                        color={
                          selected
                            ? theme.palette.primary.main
                            : theme.palette.text.primary
                        }>
                        <Icon path={item.icon_path} />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        primaryTypographyProps={{ fontSize: 18 }}
                        expanded={true}
                        style={{
                          color: selected
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                        }}
                      />
                    </ListItemButton>
                  </Link>
                )
              })}
          </List>
          {/* {height !== undefined && (
            <div
              style={{
                position: 'absolute',
                width: 10,
                borderRadius: 5,
                height: 52,
                backgroundColor: theme.palette.primary.main,
                top: height - 3,
                left: 30,
                transition: 'top 0.5s ease-in-out 0s',
              }}
            />
          )} */}
        </Column>
      </DrawerPaper>
    </DrawerWrapper>
  )
}

const DrawerWrapper = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  display: flex;
  pointer-events: none;
  width: ${ThemeConstants.SIDEBAR_WIDTH}px;
`

const DrawerPaper = styled(Paper)`
  height: fit-content;
  pointer-events: all;
  flex-direction: column;
  // margin: 20px;
  flex: 1;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 15px;
`

const ListItemIcon = styled(MuiListItemIcon)`
  &${`.MuiListItemIcon-root`} {
    color: ${(p) => p.color ?? p.theme.palette.text.primary};
    min-width: unset;
  }
`

const ListItemButton = styled(MuiListItemButton)`
  justify-content: flex-start;
  padding-left: 30px;
  gap: 15px;
  color: black;
`

const ListItemText = styled(MuiListItemText, {
  shouldForwardProp: (p) => p !== 'expanded',
}) <ListItemTextProps & { expanded: boolean }>`
  margin: 0;
  display: ${(p) => (p.expanded ? 'block' : 'none')};
  white-space: nowrap;
`
