import Fade from '@mui/material/Fade'
import MuiListItemButton from '@mui/material/ListItemButton'
import MuiListItemIcon from '@mui/material/ListItemIcon'
import MuiListItemText, { ListItemTextProps } from '@mui/material/ListItemText'
import MuiMenu from '@mui/material/Menu'
import { styled, useTheme } from '@mui/material/styles'
import { Link } from 'components'
import { useSelfUser } from 'contexts/api/hooks'
import { usePathManager } from 'contexts/pathManager/Controller'
import { MouseEvent, useState } from 'react'
import { bottomSidebarItems, topSidebarItems } from 'router/constants'
import { Icon, UtilityIcon } from './Icon'

export function NavElements() {
  const { role } = useSelfUser()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { page, subpage } = usePathManager()
  const theme = useTheme()
  const menuOpen = Boolean(anchorEl)

  const handleClickMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl((el) => (el ? null : event.currentTarget))
  }

  const handleMenuNavigate = () => {
    setAnchorEl(null)
  }

  const menuPaths = [...topSidebarItems, ...bottomSidebarItems]

  return (
    <>
      <Item onClick={handleClickMenu}>
        <Icon path={UtilityIcon.MENU} />
      </Item>
      <Menu
        style={{ width: '100%' }}
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClickMenu}
        TransitionComponent={Fade}>
        {menuPaths
          .filter((item) => item.allowed_roles.includes(role))
          .map((item) => {
            const selected = `/${page}/${subpage}` === item.link

            return (
              <Link key={item.link} to={item.link} onClick={handleMenuNavigate}>
                <ListItemButton selected={selected} key={item.text}>
                  <ListItemIcon color={theme.palette.text.primary}>
                    <Icon path={item.icon_path} />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{ fontSize: 18 }}
                    expanded={true}
                    style={{ color: theme.palette.text.primary }}
                  />
                </ListItemButton>
              </Link>
            )
          })}
      </Menu>
    </>
  )
}

const Menu = styled(MuiMenu)`
  .MuiMenu-paper {
  }
`

const Item = styled('a')`
  color: ${(p) => p.theme.palette.text.primary};
  cursor: pointer;
  text-decoration: none;
  text-decoration-color: transparent;
  display: flex;
  padding: 0.25rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  align-items: center;

  a: {
    text-decoration: none;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.4;
  }

  .icon {
    margin-right: 0.25rem;
  }
`

const ListItemIcon = styled(MuiListItemIcon)`
  &${`.MuiListItemIcon-root`} {
    color: ${(p) => p.color ?? p.theme.palette.text.primary};
    min-width: unset;
  }
`

const ListItemButton = styled(MuiListItemButton)`
  justify-content: flex-start;
  gap: 15px;
`

const ListItemText = styled(MuiListItemText, {
  shouldForwardProp: (p) => p !== 'expanded',
})<ListItemTextProps & { expanded: boolean }>`
  margin: 0;
  display: ${(p) => (p.expanded ? 'block' : 'none')};
  white-space: nowrap;
`
