import { Outlet } from 'react-router-dom'
import { useWindowSizes } from 'utils/hooks'
import { NavBar } from './NavBar'
import { Sidebar } from './Sidebar'

export function Nav() {
  const { showSidebar, showHeader } = useWindowSizes()

  return (
    <>
      {showSidebar && <Sidebar />}
      <>
        {showHeader && <NavBar />}
        <Outlet />
      </>
    </>
  )
}

// const SidebarWrapper = styled('div')`
//   padding-left: ${ThemeConstants.DRAWER_WIDTH}px;
// `
// // // padding-right: 20px; // mirror on the right the space between sidebar and real content

// const NavBarWrapper = styled('div')`
//   padding-top: ${ThemeConstants.NAV_BAR_HEIGHT}px;
// `
