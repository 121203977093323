import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { Title, TitleSection } from 'components'
import { Feed } from 'components/Feed'
import { Icon } from 'components/Icon'
import { withPageWrapper } from 'components/withScreenWrapper'
import { useViewUser } from 'contexts/api/hooks'
import { Relationships } from 'router/constants'
import { Path } from 'router/constants'
import { Goal, Header } from './components'
import { IntakeFormSummary } from './components/IntakeFormSummary'
import { VideoHistory, VideoPinned } from 'components/Video'
import { TabPinVideo } from 'contexts/api/types'

const OnePercentPage = () => {
  const { user, formResponses, isSelf } = useViewUser()
  
  const titlePrefix = isSelf ? (
    ''
  ) : (
    <>
      <TitleSection> {`${user?.name}`}</TitleSection> -{' '}
    </>
  )
  return (
    <>
      <Title style={{ marginBottom: '0.5rem' }}>{titlePrefix}One Percent</Title>
      <Divider
        style={{
          borderColor: '#00000050',
          marginBottom: 30,
        }}
      />
      <Grid container spacing={3} paddingBottom={2}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={3} paddingBottom={2}>
            <IntakeFormSummary category={Path.ONE_PERCENT} />
            {/* <div>
              <Header>Pacing Profile</Header>
              <Paper style={{ padding: 10 }}>
                <Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Sed felis eget velit aliquet sagittis. Dui id ornare arcu odio
                  ut.
                </Body>
              </Paper>
            </div> */}
          </Stack>
          <Grid item xs={12}>
            <VideoPinned tabPinVideo={TabPinVideo.PinnedOnePercent}/>
          </Grid>
          <Grid item xs={12}>
            <VideoHistory />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={3}>
            <Stack alignItems='stretch' spacing={2}>
              <Header>Goals / Races</Header>
              <Goal
                title='Lift Maxes'
                subtitle={formResponses?.op_weights_max}
                icon={<Icon path={Path.ONE_PERCENT} />}
              />
              <Goal
                title='Weight Routine'
                subtitle={formResponses?.op_weights_routine}
                icon={<Icon path={Path.ONE_PERCENT} />}
              />
              <Goal
                title='Lift Craving'
                subtitle={formResponses?.op_weights_crave}
                icon={<Icon path={Path.ONE_PERCENT} />}
              />
              <Goal
                title='Exercises that prevent injury'
                subtitle={formResponses?.op_injury_exer}
                icon={<Icon path={Path.ONE_PERCENT_SPLIT2} />}
              />
              <Goal
                title='Weakest Part of your Body'
                subtitle={formResponses?.op_weights_weak}
                icon={<Icon path={Path.ONE_PERCENT} />}
              />
            </Stack>
            <Feed category={Relationships.ONE_PERCENT} />
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

export default withPageWrapper(
  OnePercentPage,
  { width: 'max-width' },
  { alignItems: 'stretch' },
)
